const serializeUrlParams = (params, serializationDict) => {
    let serializedDict = {};
    for (let [key, val] of Object.entries(params)) {
        serializedDict[serializationDict[key] || key] = val;
    }
    return serializedDict
};

const sortUrlParams = (param1, param2) => {
  return param1[0].localeCompare(param2[0], 'pl')
};

export const getUrlParamsString = ({
    defaultData={}, data={}, serializationUrlDictToPolish={}, pageNumber
}) => {
    // get search part of url based on dict with data
    let urlParams = '';
    const serializedUrlDefaultData = serializeUrlParams(
        defaultData, serializationUrlDictToPolish);
    const serializedUrlData = serializeUrlParams(
        data, serializationUrlDictToPolish);

    for (let [k, v] of Object.entries(
        serializedUrlData).sort(sortUrlParams)
    ) {
        if (v !== serializedUrlDefaultData[k]) {
            urlParams += `&${k}=${encodeURIComponent(v.toString())}`;
        }
    }
    if (pageNumber) {
        urlParams += `&strona=${pageNumber}`;
    }

    if (!urlParams.length) { return '' }

    return `?${urlParams.substring(1)}`
};

export const getFiltersByUrlParams = (
    urlParams, defaultFilters={}, serializationUrlDictToPolish={},
) => {
    let filters = {};
    for (let [name, value] of Object.entries(defaultFilters)) {
        let paramValue = urlParams.get(serializationUrlDictToPolish[name]);
        if (paramValue) {
            filters[name] = decodeURIComponent(paramValue);
            continue
        }
        filters[name] = value;
    }
    return filters
};

export const changePage = (history, params, currentPageNumber, next) => {
    params.set('strona', next ? currentPageNumber + 1 : currentPageNumber - 1);
    history.push(window.location.pathname + `?${params.toString()}`);
};
