import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { EMPTY_VALUE, REPO_TYPES } from '../../consts';
import { URLS } from '../../urls/frontend';
import { IconButtonWithTooltip } from './IconButtonWithTooltip';
import './molecules-global.sass';


export const CaseFilesTableRow = ({
    casePageCaseReference,
    item,
    onChangeCaseReference,
}) => {
    const { case: case_ = null, repoType = null } = item;
    // just in case of wrong structure
    if (!case_) { return null }

    const { caseReference } = case_;
    return (
        <TableRow hover>
            <TableCell>{case_.krsNumber || EMPTY_VALUE}</TableCell>
            <TableCell>{caseReference}</TableCell>
            <TableCell align="center">
                {caseReference && (<>
                    {casePageCaseReference && casePageCaseReference === caseReference
                        ? (
                            <IconButtonWithTooltip
                                onClick={() => onChangeCaseReference(null)}
                                srOnlyText="Przejdź do sprawy"
                            >
                                <VisibilityOutlinedIcon />
                            </IconButtonWithTooltip>
                        ) : (
                            <IconButtonWithTooltip
                                component={Link}
                                to={URLS[REPO_TYPES[repoType.toLowerCase()]
                                    .urlsNames.case_].getUrl(caseReference)
                                }
                                srOnlyText="Przejdź do sprawy"
                            >
                                <VisibilityOutlinedIcon />
                            </IconButtonWithTooltip>
                        )
                    }
                </>)}
            </TableCell>
            <TableCell align="center">
                {case_.krsNumber && (
                    <IconButtonWithTooltip
                        component={Link}
                        onClick={() => onChangeCaseReference(null)}
                        to={URLS[REPO_TYPES[repoType.toLowerCase()]
                            .urlsNames.KRSSubject].getUrl(case_.krsNumber)
                        }
                        srOnlyText="Przejdź do podmiotu"
                    >
                        <VisibilityOutlinedIcon />
                    </IconButtonWithTooltip>
                )}
            </TableCell>
        </TableRow>
    )
};
