import { Box, CircularProgress, Typography } from '@material-ui/core';


export const Loader = (props) => {
    return (
        <Box display="flex" m={0} my={2}>
            <CircularProgress color="primary" style={{width: 20, height: 20, marginRight: 10}}/>
            <Typography color="primary" role="alert">Trwa inicjalizacja danych...</Typography>
        </Box>
    )
}
