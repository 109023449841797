import { useEffect, useState } from 'react';

import { REQUEST_ERROR } from '../consts';
import { fetchApi } from '../fetch';
import { sortDictionaryOptions } from '../helpers/forms';


export const useGetSingleObjectData = (
    object_,
    url,
    body,
    message404,
    encodeParams = true,
) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [fetchError, setFetchError] = useState(null);
    const [technicalError, setTechnicalError] = useState(null);

    useEffect(() => {
        if (!object_) {
            setIsLoading(false);
            return
        }
        let xhrFetch = null;
        setFetchError(null);
        setTechnicalError(null);
        setData({});

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            setData(data);
            setIsLoading(false);
        }

        function callbackFetchDataError(message) {
            xhrFetch = null;
            setFetchError(message);
            setIsLoading(false);
        }

        function callbackFetchDataIncorrectStatus(statusCode, technicalErrorObject) {
            setTechnicalError(technicalErrorObject);
            callbackFetchDataError(
                statusCode === 404 ? message404 : REQUEST_ERROR);
        }

        function callbackFetchDataShowErrors(data) {
            console.error('App Error: Invalid request parameters ', data);
            callbackFetchDataError(REQUEST_ERROR);
        }

        xhrFetch = fetchApi({
            url,
            body,
            encodeParams,
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: callbackFetchDataShowErrors,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [object_]); // eslint-disable-line react-hooks/exhaustive-deps

    return {isLoading, data, fetchError, technicalError}
};


export const useGetDictionariesValues = (
    dictNameList, setDataFunction, setErrorOnFunction,
    callbackCheckInitialChoices
) => {

    useEffect(() => {
        let xhrFetch = null;

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            // sort options
            for (let [dictName, dictData] of Object.entries(data)) {
                data[dictName] = dictData.sort(sortDictionaryOptions);
            }
            setDataFunction(data);
            if (callbackCheckInitialChoices) {
                callbackCheckInitialChoices(data)
            }
        }

        function callbackFetchDataError(message) {
            xhrFetch = null;
            setDataFunction({});
            setErrorOnFunction(true);
            if (callbackCheckInitialChoices) {
                callbackCheckInitialChoices({})
            }
        }

        function callbackFetchDataIncorrectStatus(statusCode) {
            callbackFetchDataError('');
        }

        xhrFetch = fetchApi({
            url: '/dictionaries',
            body: {name: dictNameList},
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: () => callbackFetchDataIncorrectStatus(400),
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
