import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link } from 'react-router-dom';
import {
    TableCell,
    TableRow,
} from '@material-ui/core';
import { useMemo } from 'react';
import { EMPTY_VALUE, REPO_TYPES, WITH_MOCK } from '../../consts';
import { URLS } from '../../urls/frontend';
import { IconButtonWithTooltip } from '../molecules';
import { formatDate } from '../../hooks';
import CaseIcon from '../../assets/cases.svg';
import './molecules-global.sass';


export const CaseTableRow = ({
    item,
    repoData,
    onDocumentPreview,
    withMovedFiles = false,
    useFormatDate = false,
    onShowMovedFiles
}) => {
    let urlName;
    if (repoData.name === REPO_TYPES.all.name) {
        if (item.repoType) {
            urlName = REPO_TYPES[item.repoType.toLowerCase()].urlsNames.case_;
        }
    } else {
        urlName = repoData.urlsNames.case_;
    }

    const repoDataByItemType = useMemo(
        () => item.repoType
            ? REPO_TYPES[item.repoType.toLowerCase()]
            : repoData,
        [item.repoType, repoData]
    );

    return (
        <TableRow hover>
            {repoData.name === REPO_TYPES.all.name && (
                <TableCell>
                    {REPO_TYPES[item.repoType.toLowerCase()].label}
                </TableCell>
            )}
            <TableCell>{item.caseReference}</TableCell>
            <TableCell>{item.kind || EMPTY_VALUE}</TableCell>
            <TableCell>{item.status || EMPTY_VALUE}</TableCell>
            <TableCell>{useFormatDate ? formatDate(item.startDate) : (item.startDate || EMPTY_VALUE)}</TableCell>
            <TableCell>{useFormatDate ? formatDate(item.endDate) : (item.endDate || EMPTY_VALUE)}</TableCell>
            <TableCell>
                {onDocumentPreview && (
                    <IconButtonWithTooltip
                        onClick={() => onDocumentPreview(
                            item.caseReference, item.documentData || null
                        )}
                        srOnlyText="Podgląd"
                    >
                        <DescriptionIcon />
                    </IconButtonWithTooltip>
                )}
                {urlName &&
                    <IconButtonWithTooltip
                        edge="end"
                        component={Link}
                        to={URLS[repoDataByItemType.urlsNames.case_].getUrl(item.caseReference)}
                        srOnlyText="Przejdź do sprawy"
                    >
                        <VisibilityOutlinedIcon />
                    </IconButtonWithTooltip>
                }
            </TableCell>
            {(WITH_MOCK ? true : withMovedFiles) && (
                <TableCell>
                    {(WITH_MOCK ? true : item.hasMovedFiles) && (
                        <IconButtonWithTooltip
                            onClick={() => onShowMovedFiles(item.caseReference)}
                            srOnlyText="Zobacz dołączone akta/sprawy"
                        >
                            <img
                                alt=""
                                aria-hidden={true}
                                className="icon-button-file"
                                src={CaseIcon}
                            />
                        </IconButtonWithTooltip>
                    )}
                </TableCell>
            )}
        </TableRow>
    )
};
