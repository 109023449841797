import { useContext, useEffect, useState } from 'react';
import {
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { AppContext } from '../../context/appContext';
import { UserContext } from '../../context/userContext';
import { ButtonCustom } from '../atoms';
import { IconButtonWithTooltip, LabeledElement } from '../molecules';
import { ChangePasswordForm } from './ChangePasswordForm';
import { LoginForm } from './LoginForm';
import './organisms-global.sass';


export const UserWidget = (props) => {
    const theme = useTheme();
    const mediaUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const [loginWindowOn, setLoginWindowOn] = useState(false);
    const [changePasswordWindowOn, setChangePasswordWindowOn] = useState(false);
    const [userMenu, setUserMenu] = useState(null);
    const { authorizationFocused, setAuthorizationFocused } = useContext(AppContext);
    const { user } = useContext(UserContext);

    const { isAuthenticated, username } = user;

    useEffect(() => {
        if (isAuthenticated) {
          setLoginWindowOn(false);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        authorizationFocused && setLoginWindowOn(true);

        return () => {
            setAuthorizationFocused(false);
        }
    }, [authorizationFocused, setAuthorizationFocused, setLoginWindowOn]);

    function handleOpenLoginWindow() {
        setLoginWindowOn(true);
        setUserMenu(null);
    }

    function handleLogout() {
        window.sessionComponent.logout();
        setUserMenu(null);
    }

    function handleChangePasswordWindowOpen() {
        setChangePasswordWindowOn(true);
        setUserMenu(null);
    }

    function handleCloseLoginForm() {
        setLoginWindowOn(false);
    }

    function handleCloseChangePasswordForm() {
        setChangePasswordWindowOn(false);
    }

    function handleUserMenuClick(ev) {
        setUserMenu(ev.currentTarget);
    }

    function handleUserMenuClose() {
        setUserMenu(null);
    }

    return (
        <>
            {isAuthenticated && user.isExternalNamed && (
                <>
                    {mediaUpSm ? (
                        <>
                            <ButtonCustom
                                aria-controls="user_settings_menu"
                                aria-haspopup="true"
                                onClick={handleUserMenuClick}
                                startIcon={<AccountCircleIcon />}
                                variant="outlined"
                            >
                                <Typography variant="srOnly">Zalogowany jako: </Typography>
                                <Typography>{username}</Typography>
                                <Typography variant="srOnly">Kliknij, aby opuścić widok autoryzowany lub zmienić hasło.</Typography>
                            </ButtonCustom>
                        </>
                    ) : (
                        <IconButtonWithTooltip
                            aria-controls="user_settings_menu"
                            aria-haspopup="true"
                            onClick={handleUserMenuClick}
                            color="inherit"
                            srOnlyText={`Zalogowany jako: ${username}`}
                        >
                            <AccountCircleIcon />
                            <Typography variant="srOnly">Kliknij, aby opuścić widok autoryzowany lub zmienić hasło.</Typography>
                        </IconButtonWithTooltip>
                    )}
                </>
            )}
            {isAuthenticated ? (
                user.isExternalNamed ? (
                    <Menu
                        id="user_settings_menu"
                        anchorEl={userMenu}
                        keepMounted
                        open={Boolean(userMenu)}
                        onClose={handleUserMenuClose}
                    >
                        <MenuItem onClick={handleLogout} classes={{ root: 'user-menu-item' }}>Opuść widok autoryzowany</MenuItem>
                        <MenuItem key="changePassword" onClick={handleChangePasswordWindowOpen} classes={{ root: 'user-menu-item' }}>
                            Zmień hasło
                        </MenuItem>
                    </Menu>
                ) : (
                    user.isExternalOneTime && (
                        mediaUpSm ? (
                            <ButtonCustom
                                onClick={handleLogout}
                                startIcon={<PowerSettingsNewIcon />}
                            >Opuść widok autoryzowany</ButtonCustom>
                        ) : (
                            <IconButtonWithTooltip
                                edge="end"
                                color="inherit"
                                onClick={handleLogout}
                                srOnlyText="Opuść widok autoryzowany"
                            >
                                <PowerSettingsNewIcon />
                            </IconButtonWithTooltip>
                        )
                    )
                )
            ) : (
                <LabeledElement
                    moreInfo="Widok autoryzowany służy do przeglądania dokumentów, które nie są dostępne dla każdego. Naciśnij przycisk, jeżeli otrzymałeś z sądu login i hasło lub hasło jednorazowe uprawniające do dostępu do dokumentów o ograniczonym poziomie dostępności."
                >
                    {mediaUpSm ? (
                            <ButtonCustom
                                onClick={handleOpenLoginWindow}
                                startIcon={<PowerSettingsNewIcon />}
                            >Włącz widok autoryzowany</ButtonCustom>
                    ) : (
                        <IconButtonWithTooltip
                            color="inherit"
                            onClick={handleOpenLoginWindow}
                            srOnlyText="Włącz widok autoryzowany"
                            >
                            <PowerSettingsNewIcon />
                        </IconButtonWithTooltip>
                    )}
                </LabeledElement>
            )}
            {!isAuthenticated && loginWindowOn && (
                <LoginForm onDialogClose={handleCloseLoginForm} />
            )}
            {isAuthenticated && user.isExternalNamed && changePasswordWindowOn && (
                <ChangePasswordForm
                    key="changePasswordForm"
                    onCancel={handleCloseChangePasswordForm} />
            )}
        </>
    )
};
