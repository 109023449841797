import { useContext, useEffect, useRef } from 'react';

import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import { BreadcrumbsCustom } from '../../molecules';
import { PageTitle } from '../../atoms';
import { UserWidget } from '../UserWidget';
import './BreadcrumbsWithPageTitle.sass';

export const BreadcrumbsWithPageTitle = ({
  actionButton,
  breadcrumbs,
  currentLocation,
  otherTitles,
  title,
  subtitle,
  ...props
}) => {
    const { contentFocused, setContentFocused } = useContext(AppContext);
    const { user } = useContext(UserContext);
    const contentRef = useRef();

    useEffect(() => {
        if (contentFocused && contentRef.current) {
            const mainContent = contentRef.current.nextElementSibling;

            if (mainContent) {
                mainContent.setAttribute('tabIndex', -1);
                mainContent.focus();
            }
        }
        return () => {
            setContentFocused(false);
        }
    }, [contentFocused, setContentFocused])

    return (
        <>
            <div className="breadcrumbs-with-page-title">
                <div className="breadcrumbs-with-page-title__row">
                    <BreadcrumbsCustom
                        breadcrumbs={breadcrumbs}
                        currentLocation={currentLocation}
                    />
                    {!user.isInternal && <UserWidget />}
                </div>
                <div className="breadcrumbs-with-page-title__row">
                    <PageTitle
                        subtitle={subtitle}
                        otherTitles={otherTitles}
                        {...props}
                    >{title}</PageTitle>
                    {actionButton}
                </div>
            </div>
            {/* Empty span to catch focus of the page content after skip link click */}
            <span ref={contentRef} aria-hidden={true} />
        </>
    )
};
