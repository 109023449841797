import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../../../assets/logo.svg';
import new_logo from '../../../assets/new_logo.png';
import { IconButtonWithTooltip } from '../../molecules';
import './TopAppBar.sass';


export const TopAppBar = ({ ariaControls, onMenuClick, ...props }) => {
  let history = useHistory();
  const trigger = useScrollTrigger();

  return (
    <>
      <AppBar
        classes={{ root: 'top-app-bar' }}
        elevation={trigger ? 3 : 0}
        color="inherit"
        position="fixed"
        {...props}
      >
        <Toolbar>
          <div className="top-app-bar__menu-btn">
            <IconButtonWithTooltip
              color="inherit"
              onClick={onMenuClick}
              srOnlyText="Otwórz menu boczne"
              aria-expanded={false}
              aria-controls={ariaControls}
            >
              <MenuIcon />
            </IconButtonWithTooltip>
          </div>
			<div className="top-app-bar__logo">
				<Typography component="h1" >
					<a href={window.externalEnvironment || ''} className="top-app-bar__home-link">
						<img src={logo} alt="Portal Rejestrów Sądowych" />
					</a>
				</Typography>
				<Typography>
					<img className="logo-ms" src={new_logo} alt=""/>
				</Typography>
			</div>
          {history.length > 2 && (
            <IconButtonWithTooltip
              color="inherit"
              onClick={() => history.goBack()}
              srOnlyText="Wstecz"
            >
              <ArrowBackIcon />
            </IconButtonWithTooltip>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
};

export default TopAppBar;
