import { EMPTY_VALUE, REPO_TYPES } from '../consts';


export const formatFileSize = (value) => {
    value = parseInt(value);
    if (!value) { return EMPTY_VALUE }
    // we expect the value in bytes
    if (value < 1024) {
        return `${value} B`
    }
    let valFloor;
    if (value < 1048576) {  // 1 MB (1024 * 1024)
        valFloor = ~~(value / 1024); // compute floor
        if (((value % 1024) / 1024) >= 0.5) {
            if (valFloor + 1 === 1024) {
                // increase the number
                value = 1048576;
            } else {
                return `${valFloor + 1} KB`
            }
        } else {
            return `${valFloor} KB`
        }
    }

    valFloor = ~~(value / 1048576);
    if (((value % 1048576) / 1048576) >= 0.5) {
        return `${valFloor + 1} MB`
    }
    return `${valFloor} MB`
};

export const formatFileFormat = (value) => {
    return value ? value.toUpperCase() : EMPTY_VALUE
};

const caseIsClosed = (caseEndType) => caseEndType !== null;
const RAS_NAME = REPO_TYPES.ras.name;
const RP_NAME = REPO_TYPES.rp.name
const RWIP_NAME = REPO_TYPES.rwip.name


export const getDocumentFileAccessAction = ({
    accessLevel, casesForDocument, inCase, repoName, user, caseEndType
}) => {
    if (repoName === RP_NAME) {
        if (inCase) {
            return 'NO_ACCESS_INFO'
        }
        if (user.isInternal) {
            return 'GET_FILE'
        }
        return 'NO_ACCESS_INFO';
    }

    if (inCase) {
        if (!user.isAuthenticated && (
                repoName === RWIP_NAME ||
                (repoName === RAS_NAME && caseIsClosed(caseEndType)) ||
                // documents in case are available base on case access level
                ['RESTRICTED', 'FOR_COURT'].includes(accessLevel)
        )) {
            return 'LOGIN'
        }

        if (accessLevel === 'FOR_COURT') {
            if (user.isExternalOneTime) {
                 return 'NO_ACCESS_INFO'
            }
        }
        return 'GET_FILE'
    }

    if (repoName === RWIP_NAME && !user.isAuthenticated) {
        return 'LOGIN';
    }

    if (!casesForDocument.length) {
        return 'GET_FILE'
    }

    if (repoName === RAS_NAME && !user.isAuthenticated) {
        // check if at least one case is PUBLIC and not closed
        for (let case_ of casesForDocument) {
            if (
                ((case_.documentData || {}).accessLevel || {}).key === 'PUBLIC' &&
                !caseIsClosed(case_.endType)
            ) {
                return 'GET_FILE'
            }
        }
        // otherwise there is no free access for unauthenticated user
        return 'LOGIN'
    }

    const casesAccessLevels = casesForDocument
        .map(case_ => ((case_.documentData || {}).accessLevel || {}).key || null)
        .filter(case_ => case_ !== null)
    if (casesAccessLevels.includes('PUBLIC')) {
        // if document has public access level at least for one case, show it
        return 'GET_FILE';
    }
    if (!user.isAuthenticated) {
        return 'LOGIN';
    }
    if (!casesAccessLevels.includes('RESTRICTED') && user.isExternalOneTime) {
        // only FOR_COURT and user isExternalNamed has permission
        return 'NO_ACCESS_INFO';
    }
    return 'GET_FILE';
}

export const getCookieValue = (name) => {
    for (let cookie of document.cookie.split(';')) {
        const [n, v] = cookie.trim().split('=');
        if (n === name) {
            return v
        }
    }
    return null
};

export const getPastedKrsNumberValue = (ev) => {
    // stop data actually being pasted into input
    ev.preventDefault();
    // set pasted data via clipboard API
    const clipboardData = ev.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');
    if (!/^\d{1,10}$/.test(pastedData)) {
        pastedData = ''
    }
    return pastedData
};

export const getBlurredKrsNumberValue = (value) => {
    if (value.length) {
         return value.padStart(10, '0')
    }
    return ''
}

export const fillWithLeadingZero = (value, length = 2) => {
    value = value.toString();
    if (value.length >= length) {
        return value
    }
    return `${'0'.repeat(length - value.length)}${value}`
};

export const dateToString = (date) => {
    if (!date) { return '' }
    return `${date.getFullYear()}-${fillWithLeadingZero(date.getMonth() + 1)}-${fillWithLeadingZero(date.getDate())}`
};

export const datetimeToString = (date) => {
    if (!date) { return '' }
    return `${date.getFullYear()}-${fillWithLeadingZero(date.getMonth() + 1)}-${fillWithLeadingZero(date.getDate())}T${fillWithLeadingZero(date.getHours())}:${fillWithLeadingZero(date.getMinutes())}`
};

export const isDocumentOutOfCase = (statusDict) => {
    if (!statusDict || typeof statusDict !== 'object') { return false }
    return ['WYLACZONY_ZE_SPRAWY', 'PRZENIESIONY'].includes(statusDict.key)
}

export const convertDateWithoutTime = (date) => {
    return date.slice(0, 10)
}

export const sortDepartmentDictionary = (dict) =>
    dict.sort((prev, next) => prev.shortName > next.shortName ? 1 : -1)

export const getErrorPerDefinition = (parsedError, errorCode, fieldName, key) => {
    return (
        parsedError && parsedError[key] &&
            parsedError[key].some((obj) =>
                obj.errorCode && obj.errorCode === errorCode && obj.fieldName && obj.fieldName === fieldName)
    );
};
