import { Component } from 'react';

import { UserContext } from '../../context/userContext';
import { ADFS_LOGIN_URL } from '../../urls/backend';
import { DialogBase } from '../molecules';
import { LogoutDialog } from './LogoutDialog';
import { RefreshSessionDialog } from './RefreshSessionDialog';


const REFRESH_TIME_WINDOW_SECONDS_VALUE = 20;


export class Session extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timerSeconds: null,
            logoutOn: false,
            logoutDone: false,
            sessionRefreshingFailed: false,
            unauthorizedAccess: false,
        };

        this.sessionIntervalId = null;
        this.xhrFetch = null;
    }

    componentDidMount() {
       if (this.context.auth.expire) {
           this.initializeTimer();
       }
       if (this.xhrFetch !== null) {
           this.xhrFetch.abort();
       }
    }

    componentWillUnmount() {
        // abort interval if exist
        if (this.sessionIntervalId !== null) {
            clearInterval(this.sessionIntervalId)
            this.sessionIntervalId = null;
        }
    }

    initializeTimer = () => {
        // set timerValue
        this.setState(
            {timerSeconds: this.computeSessionTime()},
            () => this.startTimer()
        );
    }

    computeSessionTime() {
        if (!this.context.auth.expire) {
            return 0
        }
        return this.context.auth.expire - Math.floor((new Date()).getTime() / 1000)
    }

    startTimer() {
        // clear interval, maybe it exists
        clearInterval(this.sessionIntervalId);
        this.sessionIntervalId = window.setInterval(
            () => this.setState(prevState => {
                const prevTimerSeconds = prevState.timerSeconds;
                if (prevTimerSeconds === 0) {
                    clearInterval(this.sessionIntervalId);
                    this.sessionIntervalId = null;
                    return {}
                }
                if (prevTimerSeconds - 1 === 0) {
                    return {timerSeconds: 0, logoutOn: true}
                }
                return {timerSeconds: prevTimerSeconds - 1}
            }),
            1000
        );
    }

    computeMinSec() {
        const { timerSeconds } = this.state;
        const min = ~~(timerSeconds / 60);
        return [min, timerSeconds - min * 60]
    }

    logout(unauthorizedAccess=false) {
        this.setState({logoutOn: true, unauthorizedAccess});
    }

    setExpire(value) {
        this.context.setExpire(value, this.initializeTimer);
    }

    handleLogoutSuccess = () => {
        this.setState({
            sessionRefreshingFailed: false,
            logoutOn: false,
            logoutDone: true,
        });
    }

    handleLogoutDoneClose = () => {
        // refresh page
        window.location.reload();
    }

    isUserInternal = () => {
        return this.context.user.isInternal
    }

    loginInternalUser = () => {
        // user has to be logged in via ADFS, redirect to login page
        window.location.href = window.location.href.replace(
            window.location.path, ADFS_LOGIN_URL);
    }

    render() {
        if (this.isUserInternal()) { return null }

        const { logoutDone, logoutOn } = this.state;
        const { isAuthenticated } = this.context.user;

        let min, sec, minStr, secStr;
        if (isAuthenticated ) {
            [min, sec] = this.computeMinSec();
            minStr = min.toString().padStart(2, '0');
            secStr = sec.toString().padStart(2, '0');
        }
        return (
            <>
                {isAuthenticated && !logoutOn && !logoutDone &&
                    this.state.timerSeconds <= REFRESH_TIME_WINDOW_SECONDS_VALUE &&
                    !(min === 0 && sec <= 0) && (
                    <RefreshSessionDialog
                        min={minStr}
                        sec={secStr}
                        onSessionRefreshingFailed={() => this.setState({
                            sessionRefreshingFailed: true,
                            logoutOn: true,
                        })}
                    />
                )}
                {logoutOn &&
                    <LogoutDialog
                        sessionRefreshingFailed={this.state.sessionRefreshingFailed}
                        unauthorizedAccess={this.state.unauthorizedAccess}
                        onLogoutSuccess={this.handleLogoutSuccess} />
                }
                {logoutDone &&
                    <DialogBase
                        dialogCancelBtnText="OK"
                        dialogTitle="Użytkownik został wylogowany."
                        open
                        onDialogClose={this.handleLogoutDoneClose}
                    >
                        Aby uzyskać dostęp do widoków dla autoryzowanych użytkowników, ponownie przejdź do autoryzacji.
                    </DialogBase>
                }
            </>
        )
    }
}


Session.contextType = UserContext;
