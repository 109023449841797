import { Link } from 'react-router-dom';
import {
    TableCell,
    TableRow,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { EMPTY_VALUE } from '../../../consts';
import { isDocumentOutOfCase } from '../../../helpers/helpers';
import { URLS } from '../../../urls/frontend';
import { DialogWithButton, IconButtonWithTooltip } from '../../molecules';
import { DocumentMetaData } from './DocumentMetaData';
import '../molecules-global.sass';


export const DocumentTableRow = ({caseReference, item, repoData}) => {
    return (
        <TableRow
            className={isDocumentOutOfCase(item.statusInCase) ? 'table-row--disabled' : 'table-row'}
            hover={!isDocumentOutOfCase(item.statusInCase)}
        >
            <TableCell>{item.numberInCase}</TableCell>
            <TableCell>{item.type || EMPTY_VALUE}</TableCell>
            <TableCell>
                {item.arrivedDate || item.releaseDate || EMPTY_VALUE}
            </TableCell>
            <TableCell>{item.disclosedDate || EMPTY_VALUE}</TableCell>
            <TableCell>{item.fileIdentifier}</TableCell>
            <TableCell>
                <div className="table-actions">
                    <DialogWithButton
                        dialogOpenBtnIconText="Podgląd metadanych"
                        dialogTitle="Metadane dokumentu"
                        iconEdge="end"
                    >
                        <DocumentMetaData
                            caseReference={caseReference}
                            data={item}
                        />
                    </DialogWithButton>
                    <IconButtonWithTooltip
                        className="table-row__icon-btn"
                        component={Link}
                        edge="end"
                        srOnlyText="Przejdź do dokumentu"
                        to={(URLS[repoData.urlsNames.documentInCaseWithMetric] || URLS[repoData.urlsNames.documentInCase]).getUrl(
                            item.identifier, caseReference, item.metricIdentifier
                        )}
                    >
                        <VisibilityOutlinedIcon />
                    </IconButtonWithTooltip>
                    {isDocumentOutOfCase(item.statusInCase) && (
                        <DialogWithButton
                            dialogOpenBtnIconText="Więcej informacji"
                            dialogBtnIcon={<InfoOutlinedIcon fontSize="small"/>}
                            iconEdge="end"
                        >
                            Wyłączony ze sprawy lub przeniesiony
                        </DialogWithButton>
                    )}
                </div>
            </TableCell>
        </TableRow>
    )
};
