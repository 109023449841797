import { createTheme } from '@material-ui/core/styles';

const fontSize = 16; // px
const htmlFontSize = 10;
const coef = fontSize / 16;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0053A4',
    },
    error: {
      main: '#de0001',
    }
  },
  typography: {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        fontSize: '1.4rem',
        textTransform: 'none',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
      },
      outlinedSecondary: {
        borderColor: '#FF0030',
        color: '#000000',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
      },
      outlined: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        textAlign: 'center',
      },
    },
    MuiIconButton: {
      root: {
        height: '4.8rem',
      },
    },
    MuiFormHelperText: {
      root: {
        opacity: 0,
        position: 'absolute',
        pointerEvents: 'none',
      }
    },
  },
});
