import { useContext } from 'react';
import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';

import footerImg from '../../../assets/footer-img.png';
import FE_PC_pl from '../../../assets/FE_PC_pl.png';
import PL_pl from '../../../assets/PL_pl.png';
import UE_pl_down from '../../../assets/UE_pl_down.png';
import new_logo from "../../../assets/new_logo.png";
import { ApiAppVersionContext } from '../../../context/apiAppVersionContext';
import './Footer.sass';


// Attention! Don't use the same name as string, because sed will kill the app
const APP_VERSION = process.env.REACT_APP_RAR_PORTAL_VERSION ||
  'RAR_PORTAL_APP_VERSION'; // for instances on server


export const Footer = ({ children }) => {
  const { version: apiAppVersion } = useContext(ApiAppVersionContext);
  return (
    <>
      <AppBar
        classes={{ root: 'footer' }}
        color="inherit"
        position="static"
        component="footer"
        elevation={0}
      >
        <Toolbar>
          <nav className="footer__nav" aria-labelledby="footerNav">
            <h2 className="sr-only" id="footerNav">Nawigacja w stopce</h2>
            {children}
          </nav>
			<div className="logos">
				<div>
					<img src={FE_PC_pl} alt="Fundusze Europejskie" />
					<img src={PL_pl} alt="Flaga RP"/>
					<img src={UE_pl_down} alt="Unia Europejska"/>
					<img src={new_logo} alt=""/>
				</div>
			</div>
          <Box pr={2}><Typography component="span" variant="caption" color="textSecondary">Wersja: {APP_VERSION} {apiAppVersion}</Typography></Box>
          <Link
            href="https://www.gov.pl/"
            color="inherit"
            variant="h6"
            className="footer__gov-link"
            target="_blank"
          >
            <span className="sr-only">Link zewnętrzny, prowadzi na stronę www</span>  
            <span>gov.pl</span>
            <img src={footerImg} alt="" aria-label="hidden" className="footer__img" />
          </Link>
        </Toolbar>
      </AppBar>
    </>
  )
};
