import { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';

import {
    CASE_REFERENCE_INPUT_MAX_LENGTH,
    CASE_REFERENCE_PLACEHOLDER,
    DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH,
    DOCUMENT_NUMBER_IN_CASE_INPUT_MAX_LENGTH,
    KRS_SUBJECT_INPUT_MAX_LENGTH,
    KRS_SUBJECT_PLACEHOLDER
} from '../../consts';
import {formatChoice, formatChoiceKey} from '../../helpers/forms';
import {
    getBlurredKrsNumberValue,
    getPastedKrsNumberValue
} from '../../helpers/helpers';
import { InputCustom, SelectCustom } from '../atoms';
import { DatesFields } from './DatesFields';
import { DigitalSelectField } from './searchPage';


const FILTERS_SELECT_FIELDS_DATA = [
    {
        dictName: 'documentKind',
        fetchingChoicesError: 'Nie udało się pobrać rodzajów dokumentów.',
        filterName: 'type',
        id: 'id_type',
        label: 'Rodzaj dokumentu',
    },
    {
        dictName: 'documentAccessLevel',
        fetchingChoicesError: 'Nie udało się pobrać poziomów dostępności.',
        filterName: 'accessLevel',
        id: 'id_accessLevel',
        label: 'Poziom dostępu',
    },
    {
        dictName: 'documentLanguage',
        fetchingChoicesError: 'Nie udało się pobrać języków.',
        filterName: 'language',
        id: 'id_language',
        label: 'Język dokumentu',
    },
];


export const DocumentsFiltersFields = ({
    dateNamesList,
    dictionariesData,
    dictionariesErrorOn,
    disabled,
    errors,
    filters,
    selectDictNamesList,
    textNamesList,
    onChangeFilter
}) => {

    const dateFiltersIds = useMemo(
        () => [...new Set(dateNamesList.map(
            date_ => date_.replace('From', '').replace('To', '')))],
        [dateNamesList]
    );

    function handleKrsNumberBlur(ev) {
        const value = getBlurredKrsNumberValue(ev.target.value);
        if (value.length) {
            onChangeFilter('krsNumber', value);
        }
    }

    function handleKrsNumberPaste(ev) {
        const value = getPastedKrsNumberValue(ev);
        if (value.length) {
            onChangeFilter('krsNumber', value);
        }
    }

    return (
        <>
            {[
                {
                    filterName: 'krsNumber',
                    id: 'id_krsNumber',
                    label: 'Numer KRS',
                    maxLength: KRS_SUBJECT_INPUT_MAX_LENGTH,
                    placeholder: KRS_SUBJECT_PLACEHOLDER,
                    required: true,
                    onBlur: handleKrsNumberBlur,
                    onPaste: handleKrsNumberPaste,
                },
                {
                    filterName: 'caseReference',
                    id: 'id_caseReference',
                    label: 'Sygnatura sprawy',
                    maxLength: CASE_REFERENCE_INPUT_MAX_LENGTH,
                    placeholder: CASE_REFERENCE_PLACEHOLDER,
                    required: true,
                },
                {
                    filterName: 'identifier',
                    id: 'id_identifier',
                    label: 'Identyfikator dokumentu',
                    maxLength: DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH,
                    placeholder: null,
                    required: false,
                },
                {
                    filterName: 'numberInCase',
                    id: 'id_numberInCase',
                    label: 'Numer dokumentu w sprawie',
                    maxLength: DOCUMENT_NUMBER_IN_CASE_INPUT_MAX_LENGTH,
                    placeholder: null,
                    required: false,
                }
            ].map(filterData => {
                if (!textNamesList.includes(filterData.filterName)) {
                    return null
                }
                const { filterName } = filterData;
                return (
                    <InputCustom
                        errorOrHelperText={errors[filterName] || (filterName === 'krsNumber' && 'Możesz wpisać tylko cyfry.')}
                        disabled={disabled}
                        id={filterData.id}
                        isInvalid={!!errors[filterName]}
                        key={filterName}
                        label={filterData.label}
                        maxLength={filterData.maxLength}
                        placeholder={filterData.placeholder}
                        required={filterData.required}
                        value={filters[filterName]}
                        showHelperText
                        onBlur={ev =>
                            filterData.onBlur ? filterData.onBlur(ev) : {}
                        }
                        onChange={ev => onChangeFilter(
                            filterName,
                            filterName === 'krsNumber'
                                ? ev.target.value.replace(/[^\d]/, '')
                                : ev.target.value
                        )}
                        onPaste={ev =>
                            filterData.onPaste ? filterData.onPaste(ev) : {}
                        }
                    />
                )
            })}

            {FILTERS_SELECT_FIELDS_DATA.map(filterData => {
                if (!selectDictNamesList.includes(filterData.dictName)) {
                    return null
                }
                const { filterName } = filterData;
                const choices = dictionariesData[filterData.dictName] || [];
                return (
                    <div key={filterData.id}>
                        <SelectCustom
                            disabled={dictionariesErrorOn || disabled}
                            errorOrHelperText={(dictionariesErrorOn && filterData.fetchingChoicesError) || (!!errors[filterName] && errors[filterName])}
                            id={filterData.id}
                            isInvalid={dictionariesErrorOn || !!errors[filterName]}
                            label={filterData.label}
                            value={choices.length > 0 ? filters[filterName] : '0'}
                            onChange={ev => onChangeFilter(
                                filterName, ev.target.value
                            )}
                        >
                            {choices.map(choice => (
                                <MenuItem
                                    key={formatChoiceKey(choice)}
                                    value={formatChoice(choice)}
                                >
                                    {choice.value}
                                </MenuItem>
                            ))}
                        </SelectCustom>
                    </div>
                )
            })}

            <DigitalSelectField
                disabled={disabled}
                error={errors.digital}
                value={filters.digital}
                onChangeFilter={onChangeFilter}
            />

            {[
                {
                    filterName: 'caseReferenceFrom',
                    id: 'id_caseReferenceFrom',
                    label: 'Przeniesiony ze sprawy',
                    maxLength: CASE_REFERENCE_INPUT_MAX_LENGTH,
                    placeholder: CASE_REFERENCE_PLACEHOLDER,
                },
                {
                    filterName: 'caseReferenceTo',
                    id: 'id_caseReferenceTo',
                    label: 'Przeniesiony do sprawy',
                    maxLength: CASE_REFERENCE_INPUT_MAX_LENGTH,
                    placeholder: CASE_REFERENCE_PLACEHOLDER,
                },
            ].map(filterData => {
                if (!textNamesList.includes(filterData.filterName)) {
                    return null
                }
                const { filterName } = filterData;
                return (
                    <InputCustom
                        errorOrHelperText={errors[filterName]}
                        disabled={disabled}
                        id={filterData.id}
                        isInvalid={!!errors[filterName]}
                        key={filterName}
                        label={filterData.label}
                        maxLength={filterData.maxLength}
                        placeholder={filterData.placeholder}
                        value={filters[filterName]}
                        onChange={ev => onChangeFilter(
                            filterName, ev.target.value
                        )}
                    />
                )
            })}

            <DatesFields
                disabled={disabled}
                errors={errors}
                fields={filters}
                filtersIds={dateFiltersIds}
                onChangeFilter={onChangeFilter}
            />
        </>
    )
};
