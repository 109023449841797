import {Typography} from '@material-ui/core';

import {ButtonCustom} from '../../atoms';
import './Pagination.sass';


export const Pagination = ({
    currentPageNumber, disabled, perPage, total, onChangePage
}) => {
    if (currentPageNumber === 1 && total <= perPage) { return null }

    return (
        <div className="pagination">
            <ButtonCustom
                disabled={disabled || currentPageNumber === 1}
                type="button"
                onClick={() => onChangePage(false)}
                variant="outlined"
            >
                Poprzednia strona
            </ButtonCustom>
            <Typography variant="body1">{currentPageNumber}</Typography>
            <ButtonCustom
                disabled={
                    disabled ||
                    Math.ceil(total / perPage) <= currentPageNumber
                }
                type="button"
                onClick={() => onChangePage(true)}
                variant="outlined"
            >
                Następna strona
            </ButtonCustom>
        </div>
    )
};
