import { useContext, useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';

import { UserContext } from '../../context/userContext';
import { LOGIN_URL } from '../../urls/backend';
import { fetchApi } from '../../fetch';
import { formatFormErrors } from '../../helpers/forms';
import { validate } from '../../validation/LoginForm';
import { DialogNaked } from '../molecules';
import { ButtonCustom, InfoAlert, InputCustom } from '../atoms';
import './organisms-global.sass';
import refreshSession from '../../helpers/refreshSession';

const DEFAULT_LOGIN_PASSWORD_DATA = {
    login: '',
    secret: '',
};

const DEFAULT_ONETIME_PASSWORD_DATA = {
    secret: '',
};

const TABS_LIST = [
    {
        label: 'Dane do autoryzacji',
        sectionName: 'loginSecret',
    },
    {
        label: 'Hasło jednorazowe',
        sectionName: 'oneTimeSecret',
    },
];
const ONETIME_PASSWORD_ERROR_TRANSLATOR = {
    'INVALID': 'Hasło jest nieprawidłowe.',
    'USED': 'Jednorazowe hasło zostało już użyte. Jeżeli nadal potrzebujesz dostępu do tych dokumentów, zwróć się do sądu rejestrowego o wydanie kolejnego hasła jednorazowego.',
};


export const LoginForm = ({ extendedInfo, onDialogClose }) => {
    const [activeTab, setActiveTab] = useState('loginSecret');
    const [data, setData] = useState({...DEFAULT_LOGIN_PASSWORD_DATA});
    const [isLoginOn, setIsLoginOn] = useState(false);
    const [errors, setErrors] = useState({});

    function handleChangeValue(fieldName, value) {
        setData(prevValue => ({
            ...prevValue,
            [fieldName]: value,
        }));
    }

    const isLoginSecretMode = activeTab === 'loginSecret';

    function handleChangeActiveTab(sectionName) {
        setData(sectionName === 'loginSecret'
            ? {...DEFAULT_LOGIN_PASSWORD_DATA}
            : {...DEFAULT_ONETIME_PASSWORD_DATA}
        );
        setErrors({});
        setActiveTab(sectionName);
    }

    const { set: setUserData } = useContext(UserContext);

    useEffect(() => {
        if (!isLoginOn) { return }
        setErrors({});
        const [isValid, errors] = validate(
            data, isLoginSecretMode ? ['login', 'secret'] : ['secret']);
        if (!isValid) {
            setErrors(errors);
            setIsLoginOn(false);
            return
        }
        let xhrFetch = null;

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            setUserData(data);
            onDialogClose();
            const refreshSessionDefinition = refreshSession();
            if (refreshSessionDefinition.getState()) {
                refreshSessionDefinition.removeState();
                window.location.reload();
            }
        }

        function callbackFetchDataError(message) {
            xhrFetch = null;
            setErrors({formErrors: [message]});
            setIsLoginOn(false);
        }

        function showOneTimePasswordError(responseText) {
            let success = true;
            let jsonData = {};
            try {
                jsonData = JSON.parse(responseText);
            } catch(error) {
                success = false;
            }
            if (success) {
                const errors = formatFormErrors(jsonData, ['secret']);
                callbackFetchDataError(
                    ONETIME_PASSWORD_ERROR_TRANSLATOR[errors.secret] ||
                    ONETIME_PASSWORD_ERROR_TRANSLATOR.INVALID
                );
            } else {
                callbackFetchDataError(
                    ONETIME_PASSWORD_ERROR_TRANSLATOR.INVALID);
            }
        }

        function callbackFetchDataIncorrectStatus(statusCode, responseText) {
            if (statusCode === 401 && !isLoginSecretMode) {
                showOneTimePasswordError(responseText);
            } else {
                callbackFetchDataError(statusCode === 401
                    ? 'Login i hasło nie są zgodne.'
                    : 'Wystąpił tymczasowy problem z autoryzacją. Prosimy spróbować później.'
                );
            }
        }

        function callbackFetchDataShowErrors(responseData) {
            xhrFetch = null;
            setErrors(formatFormErrors(responseData, Object.keys(data)));
            setIsLoginOn(false);
        }

        xhrFetch = fetchApi({
            url: LOGIN_URL,
            method: 'POST',
            body: data,
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: callbackFetchDataShowErrors,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [isLoginOn]); // eslint-disable-line react-hooks/exhaustive-deps

    const formInvalid = !!errors.login || !!errors.secret;

    return (
        <DialogNaked open onDialogClose={onDialogClose}>
            <DialogTitle>Autoryzacja</DialogTitle>
            <InfoAlert formInfoAlert>
                <>
                    {extendedInfo && <span>{extendedInfo}</span>}
                    <span>Login i hasło, otrzymane z sądu, umożliwia dostęp do dokumentów o ograniczonym poziomie dostępności lub dostępnych tylko dla sądu, w sprawach do których użytkownik został upoważniony przez sąd.</span>
                    <span>Hasło jednorazowe otrzymane z sądu uprawnia do dostępu do dokumentów o ograniczonym poziomie dostępności w sprawach, do których użytkownik został upoważniony przez sąd.</span>
                </>
            </InfoAlert>
            <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                aria-label={`Panel zakładek, liczba zakładek: ${TABS_LIST.length}`}
                className="tabs"
                classes={{ flexContainer: 'tabs__container', indicator: 'tabs__active-indicator' }}
            >
                {TABS_LIST.map(tabData => (
                    <Tab
                        key={tabData.sectionName}
                        label={tabData.label}
                        value={tabData.sectionName}
                        onClick={() => handleChangeActiveTab(tabData.sectionName)}
                    />
                ))}
            </Tabs>
            <DialogContent>
                <form id="login_form">
                    {isLoginSecretMode && (
                        <>
                            <InputCustom
                                errorOrHelperText={errors.login}
                                disabled={isLoginOn}
                                id="id_login"
                                isInvalid={!!errors.login}
                                label="Login"
                                required
                                value={data.login}
                                onChange={ev => handleChangeValue(
                                    'login', ev.target.value
                                )}
                            />
                        </>
                    )}
                    <InputCustom
                        errorOrHelperText={errors.secret}
                        disabled={isLoginOn}
                        id="id_secret"
                        isInvalid={!!errors.secret}
                        label={isLoginSecretMode ? 'Hasło' : 'Hasło jednorazowe'}
                        required
                        type="password"
                        value={data.secret}
                        onChange={ev => handleChangeValue(
                            'secret', ev.target.value
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions className="form-buttons">
                <ButtonCustom
                    disabled={isLoginOn}
                    variant="outlined"
                    onClick={onDialogClose}
                >
                    Anuluj
                </ButtonCustom>
                <ButtonCustom
                    disabled={isLoginOn}
                    form="login_form"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsLoginOn(true);
                    }}
                    type="submit"
                >
                    Autoryzuj
                </ButtonCustom>
                {(errors.formErrors || []).map(error =>
                    <p className="error" role="alert" key={error}>{error}</p>
                )}
                {formInvalid && <Typography role="alert" variant="srOnly">Formularz zawiera błędy!</Typography>}
            </DialogActions>
        </DialogNaked>
    )
};
