import { Link } from 'react-router-dom';
import {
    TableCell,
    TableRow,
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { URLS } from '../../urls/frontend';
import { IconButtonWithTooltip } from '../molecules';
import { useMemo } from 'react';
import { EMPTY_VALUE, REPO_TYPES } from '../../consts';

export const RelatedCaseTableRow = ({item, repoData}) => {
    const repoDataByItemType = useMemo(
        () => item.repoType
            ? REPO_TYPES[item.repoType.toLowerCase()]
            : repoData,
        [item.repoType, repoData]
    );

    return (
        <TableRow hover>
            <TableCell>{item.caseReference}</TableCell>
            <TableCell>{item.relationStartDate || EMPTY_VALUE}</TableCell>
            <TableCell>{item.relationReason || EMPTY_VALUE}</TableCell>
            <TableCell>
                <IconButtonWithTooltip
                    edge="end"
                    component={Link}
                    to={URLS[repoDataByItemType.urlsNames.case_].getUrl(item.caseReference)}
                    srOnlyText="Przejdź do sprawy"
                >
                    <VisibilityOutlinedIcon />
                </IconButtonWithTooltip>
            </TableCell>
        </TableRow>
    )
};
