import { Box, InputLabel, TextField } from '@material-ui/core';

import './InputCustom.sass';


export const InputCustom = ({
  ariaLabel,
  defaultValue,
  errorOrHelperText,
  fullWidth,
  id,
  isInvalid,
  inputContainerClassName,
  label,
  maxLength,
  mb,
  ml,
  mr,
  width,
  showHelperText,
  type,
  ...props
}) => (
  <Box
    className={`input-custom ${showHelperText ? 'input-custom--with-helper' : ''} ${inputContainerClassName || null}`}
    m={0}
    ml={ml}
    mr={mr}
    mb={mb || 2}
    width={width || 1}
  >
    <InputLabel htmlFor={id} shrink>{label}</InputLabel>
    <TextField
      error={isInvalid}
      helperText={errorOrHelperText}
      id={id}
      type={type || 'text'}
      fullWidth={fullWidth || true}
      inputProps={{ 'aria-label': ariaLabel, 'maxLength': maxLength }}
      {...props}
    />
  </Box> 
);
