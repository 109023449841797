import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from './context/appContext';
import { ApiAppVersionProvider } from './context/apiAppVersionContext';
import { UserProvider } from './context/userContext';
import App from './components/App';

ReactDOM.render(
  <UserProvider>
      <ApiAppVersionProvider
        ref={c => window.apiAppVersionProviderComponent = c}
      >
        <Router>
          <AppProvider>
            <App />
          </AppProvider>
        </Router>
      </ApiAppVersionProvider>
  </UserProvider>,
  document.getElementById('root')
);
