import { Grid } from '@material-ui/core';

import { BoxCustom } from '../atoms';

export const MetadataBox = ({
  children,
  md,
  xs,
}) => (
  <BoxCustom
    classes={{ root: 'krs-subject-grid' }}
    component={Grid}
    item
    md={md}
    xs={xs || 12}
  >
    <Grid container spacing={2}>
      {children}
    </Grid>
  </BoxCustom>
);