import { useState } from 'react';

import { BoxCustom, ButtonCustom } from '../../atoms';
import { TableCustom } from '../../molecules';
import { DownloadDocumentSection } from './DownloadDocumentSection';
import { SignatureTableRow } from './SignatureTableRow';


export const ShowDocumentSection = ({
    signatures,
    ...props
}) => {
    const [showDocumentOn, setShowDocumentOn] = useState(false);

    if (!showDocumentOn) return <ButtonCustom fullWidth onClick={() => setShowDocumentOn(true)} margin={1}>Pokaż</ButtonCustom>

    return (
        <>
            <BoxCustom title="Podpisy">
                {signatures.length > 0
                    ? (
                        <TableCustom
                            tableHeaderList={[
                                'Osoba podpisująca', 'Czas podpisania',
                                'Rodzaj podpisu'
                            ]}
                        >
                            {signatures.map(item => (
                                <SignatureTableRow
                                    item={item}
                                    key={`${item.signer}_${item.signedDate}`}/>
                            ))}
                        </TableCustom>
                    )
                    : <p className="error" role="alert">Brak podpisów dla tego dokumentu.</p>
                }
            </BoxCustom>
            <DownloadDocumentSection {...props} />
        </>
    )
};
