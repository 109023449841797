import {
    ARRIVED_DATE_TO_HAS_TO_BE_GREATER_THAN_ARRIVED_DATE_FROM,
    COMPOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_COMPOSED_DATE_FROM,
    DISCLOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_DISCLOSED_DATE_FROM,
    TRANSFERRED_DATE_TO_HAS_TO_BE_GREATER_THAN_TRANSFERRED_DATE_FROM
} from '../consts';
import {
    isValidDate,
    isValidDateTime,
    isValidRangeFieldsPair
} from './helpers';


export const validate = (
    formData, krsSubjectRequired=false, caseReferenceRequired=false
) => {
    let isValid = true;
    let errors = {};

    if (krsSubjectRequired && !formData.krsNumber) {
        errors.krsNumber = 'To pole jest wymagane.';
        isValid = false;
    }
    if (
        Object.keys(formData).includes('krsNumber') &&
        formData.krsNumber.length > 10
    ) {
        errors.krsNumber = 'Numer KRS może mieć maksymalnie 10 cyfr.';
        isValid = false;
    }

    if (caseReferenceRequired && !formData.caseReference) {
        errors.caseReference = 'To pole jest wymagane.';
        isValid = false;
    }

    for (let fieldName of ['arrivedDateFrom', 'arrivedDateTo']) {
        if (
            formData[fieldName] &&
            !isValidDateTime(fieldName, formData[fieldName], errors)
        ) {
            isValid = false;
        }
    }

    for (let fieldName of [
        'composedDateFrom', 'composedDateTo',
        'disclosedDateFrom', 'disclosedDateTo',
        'transferredDateFrom', 'transferredDateTo'
    ]) {
        if (
            formData[fieldName] &&
            !isValidDate(fieldName, formData[fieldName], errors)
        ) {
            isValid = false;
        }
    }

    // form errors
    const invalidFields = Object.keys(errors);
    for (let datePairConfig of [
        [
            'arrivedDateFrom', 'arrivedDateTo',
            formData.arrivedDateFrom, formData.arrivedDateTo,
            errors, invalidFields, true,
            ARRIVED_DATE_TO_HAS_TO_BE_GREATER_THAN_ARRIVED_DATE_FROM
        ],
        [
            'composedDateFrom', 'composedDateTo',
            formData.composedDateFrom, formData.composedDateTo,
            errors, invalidFields, true,
            COMPOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_COMPOSED_DATE_FROM
        ],
        [
            'disclosedDateFrom', 'disclosedDateTo',
            formData.disclosedDateFrom, formData.disclosedDateTo,
            errors, invalidFields, true,
            DISCLOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_DISCLOSED_DATE_FROM
        ],
        [
            'transferredDateFrom', 'transferredDateTo',
            formData.transferredDateFrom, formData.transferredDateTo,
            errors, invalidFields, true,
            TRANSFERRED_DATE_TO_HAS_TO_BE_GREATER_THAN_TRANSFERRED_DATE_FROM
        ]
    ]) {
        if (!isValidRangeFieldsPair(...datePairConfig)) {
            isValid = false;
        }
    }

    return [isValid, errors]
};
