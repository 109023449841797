import { Link } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import './LinkTail.sass';

export const LinkTail = ({ to, linkName, linkSubtitle }) => (
  <Grid
    component="li"
    item
    md={6}
    xs={12}
  >
    <Paper variant="outlined" className="link-tail">
      <Link to={to}>
        <Typography color="primary" variant="h6" component="h3" className="link-tail__title">
          {linkName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {linkSubtitle}
        </Typography>
      </Link>
    </Paper>
  </Grid>
);

export default LinkTail;
