import { useMemo } from 'react';

import {
    DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS,
    DOCUMENTS_SERIALIZATION_URL_LIST
} from '../../../consts';
import { validate } from '../../../validation/DocumentsFiltersForm';
import { TableWithFilters} from '../../organisms';
import { DocumentsFiltersForm } from './DocumentsFiltersForm';
import { DocumentTableRow } from './DocumentTableRow';


const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of DOCUMENTS_SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}


const DEFAULT_FILTERS = {...DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS};
// caseReference field is unnecessary in this view, throw it away from filters
delete DEFAULT_FILTERS['caseReference'];

export const Documents = ({caseReference, repoData, ...props}) => {
    const additionalFetchParams = useMemo(
        () => ({
            caseReference: encodeURIComponent(caseReference),
            context: 'CASE_REFERENCE_WITH_METADATA',
            repoType: repoData.fetchParamName
        }),
        [caseReference, repoData.fetchParamName]
    );
    return (
        <TableWithFilters
            additionalFetchParams={additionalFetchParams}
            defaultFilters={DEFAULT_FILTERS}
            fetchUrl="/documents"
            FiltersComponent={DocumentsFiltersForm}
            filtersSelectFieldNames={['type', 'statusInCase', 'language']}
            repoData={repoData}
            serializationUrlDictToPolish={SERIALIZATION_URL_DICT_TO_POLISH}
            tableEmptyResults="Brak dokumentów spełniających podane kryteria."
            tableHeaderList={[
                'Numer dokumentu w sprawie', 'Rodzaj dokumentu',
                'Chwila wpływu do sądu / data wydania',
                'Data ujawnienia w sprawie', 'Identyfikator', 'Więcej o dokumencie',
            ]}
            tableItemKeyField="identifier"
            tableItemsName="documents"
            tableName="Dokumenty"
            TableRowComponent={DocumentTableRow}
            tableRowComponentProps={{caseReference}}
            validationFunction={validate}
        />
    )
};
