import { TableWithoutFilters} from '../../organisms';
import { CaseTableRow } from '../CaseTableRow';


export const Cases = ({repoData}) => {
    return (
        <TableWithoutFilters
            additionalFetchParams={{
                context: 'CURRENT_USER',
                repoType: repoData.fetchParamName,
            }}
            fetchUrl="/cases"
            repoData={repoData}
            tableEmptyResults="Brak spraw do wyświetlenia."
            tableHeaderList={[
                'Sygnatura', 'Rodzaj', 'Stan sprawy', 'Data wszczęcia',
                'Data zakreślenia', 'Więcej o sprawie'
            ]}
            tableItemKeyField="caseReference"
            tableItemsName="cases"
            tableName="Wykaz spraw z pełnym dostępem do dokumentów"
            TableRowComponent={CaseTableRow}
        />
    )
};
