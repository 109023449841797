import { URLS } from './urls/frontend';

export const AT_LEAST_ONE_PARAM_IS_MANDATORY =
    'Co najmniej jedna z wartości jest wymagana.';
export const INVALID = 'Wartość tego pola jest nieprawidłowa.';
export const REQUIRED = 'To pole jest wymagane.';
export const FORM_INVALID = 'Formularz zawiera błędy.';

// cases
export const START_DATE_TO_HAS_TO_BE_GREATER_THAN_START_DATE_FROM =
    '"Wszczęcie od" musi być wcześniejsze niż "Wszczęście do".'
export const END_DATE_TO_HAS_TO_BE_GREATER_THAN_END_DATE_FROM =
    '"Zakreślenie od" musi być wcześniejsze niż "Zakreślenie do".'

// documents
export const ARRIVED_DATE_TO_HAS_TO_BE_GREATER_THAN_ARRIVED_DATE_FROM =
    '"Chwila wpływu do Sądu od" musi być wcześniejszy niż "Chwila wpływu do Sądu do".'
export const COMPOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_COMPOSED_DATE_FROM =
    '"Data sporządzenia od" musi być wcześniejsza niż "Data sporządzenia do".'
export const DISCLOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_DISCLOSED_DATE_FROM =
    '"Data ujawnienia w sprawie od" musi być wcześniejsza niż "Data ujawnienia w sprawie do".'
export const TRANSFERRED_DATE_TO_HAS_TO_BE_GREATER_THAN_TRANSFERRED_DATE_FROM =
    '"Data przeniesienia / wyłączenia od" musi być wcześniejsza niż "Data przeniesienia / wyłączenia do".'

export const translationDict = {
    ARRIVED_DATE_TO_HAS_TO_BE_GREATER_THAN_ARRIVED_DATE_FROM,
    AT_LEAST_ONE_PARAM_IS_MANDATORY,
    COMPOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_COMPOSED_DATE_FROM,
    DISCLOSED_DATE_TO_HAS_TO_BE_GREATER_THAN_DISCLOSED_DATE_FROM,
    END_DATE_TO_HAS_TO_BE_GREATER_THAN_END_DATE_FROM,
    FORM_INVALID,
    INVALID,
    REQUIRED,
    START_DATE_TO_HAS_TO_BE_GREATER_THAN_START_DATE_FROM,
    TRANSFERRED_DATE_TO_HAS_TO_BE_GREATER_THAN_TRANSFERRED_DATE_FROM,
};

export const REPO_TYPES = {
    // CASES_OF_ENTITIES_SIGNED_TO_KRS_REGISTRY("Cases of Entities signed to KRS Registry", "RAR"),
    rar: {
        name: 'rar',
        fetchParamName: 'RAR',
        breadcrumbLinkLabel: 'Repozytorium Akt Rejestrowych',
        frontendUrl: URLS.rarSearch.path,
        label: 'Repozytorium akt rejestrowych',
        extendedLabel: 'Zawierające akta spraw dotyczących podmiotów wpisanych do rejestru przedsiębiorców KRS',
        urlsNames: {
            case_: 'rarCase',
            document: 'rarDocument',
            documentInCase: 'rarDocumentInCase',
            documentsByKrsNumber: 'rarDocumentsByKrsNumber',
            documentsByCaseReference: 'rarDocumentsByCaseReference',
            documentInCaseWithMetric: 'rarDocumentInCaseWithMetric',
            KRSSubject: 'rarKRSSubject',
            search: 'rarSearch',
        },
        searchPageConfig: {
            defaultSection: 'podmiotKRS',
            tabIndexes: {
                podmiotKRS: 0,
                sprawy: 1,
                dokumenty: 2,
            },
        }
    },
    // CASES_WITHOUT_ENTITY_SIGNED_TO_KRS("Cases without Entity signed to KRS", 'RAS'),
    ras: {
        name: 'ras',
        fetchParamName: 'RAS',
        breadcrumbLinkLabel: 'Repozytorium Akt Spraw dotyczących podmiotów niewpisanych do KRS',
        frontendUrl: URLS.rasSearch.path,
        label: 'Repozytorium Akt Spraw',
        extendedLabel: 'Zawierające akta spraw dotyczących podmiotów niewpisanych do KRS',
        urlsNames: {
            case_: 'rasCase',
            document: 'rasDocument',
            documentInCase: 'rasDocumentInCase',
            documentsByCaseReference: 'rasDocumentsByCaseReference',
            documentInCaseWithMetric: 'rasDocumentInCaseWithMetric',
            search: 'rasSearch',
        },
        searchPageConfig: {
            defaultSection: 'sprawy',
            tabIndexes: {
                sprawy: 0,
                dokumenty: 1,
            },
        }
    },
    // LETTERS_OUTSIDE_OF_ANY_CASE("Letters outside of any case", RP),
    rp: {
        name: 'rp',
        fetchParamName: 'RP',
        breadcrumbLinkLabel: 'Repozytorium Pism',
        frontendUrl: '/',
        label: 'Repozytorium pism',
        urlsNames: {
            document: 'rpDocument',
        },
    },
    // CASES_OF_ENTITIES_SIGNED_OR_PLANNED_TO_SIGN_TO_THE_ASSOCIATIONS_REGISTRY("Cases of Entities signed or planed to sign to the Assosiation's Registry", "RWIP");
    rwip: {
        name: 'rwip',
        fetchParamName: 'RWIP',
        breadcrumbLinkLabel: 'Repozytorium wniosków i pism elektronicznych',
        frontendUrl: URLS.rwipSearch.path,
        label: 'Repozytorium wniosków i pism elektronicznych',
        urlsNames: {
            case_: 'rwipCase',
            document: 'rwipDocument',
            documentInCase: 'rwipDocumentInCase',
            documentInCaseWithMetric: 'rwipDocumentInCaseWithMetric',
            documentsByKrsNumber: 'rwipDocumentsByKrsNumber',
            documentsByCaseReference: 'rwipDocumentsByCaseReference',
            KRSSubject: 'rwipKRSSubject',
        },
        searchPageConfig: {
            defaultSection: 'podmiotKRS',
            tabIndexes: {
                podmiotKRS: 0,
                sprawy: 1,
                dokumenty: 2,
            },
        },
    },
    all: {
        name: 'all',
        fetchParamName: 'ALL',
    }
};

export const FILTERS_DATE_FIELDS_DATA = [
    {
        id: 'arrivedDate',
        label: 'Chwila wpływu do Sądu',
        from: {
            filterName: 'arrivedDateFrom',
            id: 'id_arrived_date_from',
            isDateTimeField: true,
        },
        to: {
            filterName: 'arrivedDateTo',
            id: 'id_arrived_date_to',
            isDateTimeField: true,
        },
    },
    {
        id: 'composedDate',
        label: 'Data sporządzenia',
        from: {
            filterName: 'composedDateFrom',
            id: 'id_composed_date_from',
        },
        to: {
            filterName: 'composedDateTo',
            id: 'id_composed_date_to',
        },
    },
    {
        id: 'disclosedDate',
        label: 'Data ujawnienia w sprawie',
        from: {
            filterName: 'disclosedDateFrom',
            id: 'id_disclosed_date_from',
        },
        to: {
            filterName: 'disclosedDateTo',
            id: 'id_disclosed_date_to',
        },
    },
    {
        id: 'transferredDate',
        label: 'Data przeniesienia / wyłączenia',
        from: {
            filterName: 'transferredDateFrom',
            id: 'id_transferred_date_from',
        },
        to: {
            filterName: 'transferredDateTo',
            id: 'id_transferred_date_to',
        },
    },
];

export const EMPTY_VALUE = 'Brak';

export const DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS = {
    krsNumber: '',  // required
    type: '0',
    identifier: '',
    digital: '0',
    accessLevel: '0',
    language: '0',
    arrivedDateFrom: '',    // datetime
    arrivedDateTo: '',      // datetime
    composedDateFrom: '',   // date
    composedDateTo: '',     // date
};

export const DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS = {
    caseReference: '',  // required
    type: '0',
    identifier: '',
    numberInCase: '',
    accessLevel: '0',
    language: '0',
    digital: '0',
    caseReferenceFrom: '',
    caseReferenceTo: '',
    arrivedDateFrom: '',    // datetime
    arrivedDateTo: '',      // datetime
    composedDateFrom: '',   // date
    composedDateTo: '',     // date
    disclosedDateFrom: '',
    disclosedDateTo: '',
    transferredDateFrom: '',
    transferredDateTo: '',
};

export const DOCUMENTS_SERIALIZATION_URL_LIST = [
    ['krsNumber', 'numerKRS'],
    ['type', 'rodzaj'],
    ['identifier', 'identyfikator'],
    ['digital', 'elektroniczny'],
    ['accessLevel', 'dostepnyDla'],
    ['language', 'jezyk'],
    ['arrivedDateFrom', 'wplywOd'],
    ['arrivedDateTo', 'wplywDo'],
    ['composedDateFrom', 'sporzadzenieOd'],
    ['composedDateTo', 'sporzadzenieDo'],
    ['caseReference', 'sygnaturaSprawy'],
    ['numberInCase', 'numerWSprawie'],
    ['caseReferenceFrom', 'przeniesionyZ'],
    ['caseReferenceTo', 'przeniesionyDo'],
    ['disclosedDateFrom', 'ujawnienieOd'],
    ['disclosedDateTo', 'ujawnienieDo'],
    ['transferredDateFrom', 'przeniesienieOd'],
    ['transferredDateTo', 'przeniesienieDo'],
    ['pageNumber', 'strona'],
];

export const DIGITAL_DOCUMENT_CHOICES = [
    {
        value: true,
        label: 'TAK'
    },
    {
        value: false,
        label: 'NIE'
    },
];

export const KRS_SUBJECT_PLACEHOLDER = '_ _ _ _ _ _ _ _ _ _';
export const KRS_SUBJECT_INPUT_MAX_LENGTH = 10;
export const CASE_REFERENCE_PLACEHOLDER = 'np. WA.XIV Ns-Rej.KRS 987654/21/123';
export const CASE_REFERENCE_INPUT_MAX_LENGTH = 40;
export const DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH = 40;
export const DOCUMENT_NUMBER_IN_CASE_INPUT_MAX_LENGTH = 10;

export const REQUEST_ERROR = 'Wystąpił tymczasowy problem z pobraniem danych. Prosimy spróbować później.';

// Be careful! Don't commit it if change it to true
export const WITH_MOCK = false;
