import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';

import { AppContext } from '../context/appContext';
import { UserContext } from '../context/userContext';
import { fetchApi } from '../fetch';
import { theme } from '../theme/theme';
import { IDENTIFY_URL } from '../urls/backend';
import { Loader } from './atoms';
import { Cookies, Footer, SkipLinks } from './molecules';
import { AppBarWithMenu, Session } from './organisms';
import { Routes } from './Routes';
import '../global.sass';

function App() {
  const {
    setAuthorizationFocused,
    setContentFocused,
    setMenuFocused,
  } = useContext(AppContext);

  const {
    initialized: userInitialized,
    set,
    setInitialized,
    user,
  } = useContext(UserContext);

  const location = useLocation();
  const isInternalUserLoginRedirectPage = location.pathname.startsWith(
      '/autoryzacja');

  useEffect(() => {
    if (isInternalUserLoginRedirectPage) {
      window.location.href = window.location.href.replace(
        '/autoryzacja', '/api/v1/saml/login');
      return
    }
    // check user in backend
    let xhrFetch = null;

    function callbackFetchDataSuccess(data) {
      xhrFetch = null;
      set(data);
    }

    function callbackFetchDataError() {
      xhrFetch = null;
      // set in user context that initialization done
      setInitialized();
    }

    xhrFetch = fetchApi({
      url: IDENTIFY_URL,
      callbackSuccess: callbackFetchDataSuccess,
      callbackError: callbackFetchDataError,
      callbackIncorrectStatus: callbackFetchDataError,
    });

    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isInternalUserLoginRedirectPage) { return null }

  if (!userInitialized) {
    return <ThemeProvider theme={theme}><Loader /></ThemeProvider>
  }

  return (
    <ThemeProvider theme={theme}>
      <SkipLinks
        onClickFocusContent={() => setContentFocused(true)}
        onClickFocusLogin={!user.isAuthenticated ? () => setAuthorizationFocused(true) : null}
        onClickFocusMenu={() => setMenuFocused(true)}
      />
      <Session ref={c => {window.sessionComponent = c}} />
      <Cookies />
      <AppBarWithMenu />
      <Container className="app-content" maxWidth="lg">
        <main>
          <Routes />
        </main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
