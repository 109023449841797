import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { EMPTY_VALUE, REPO_TYPES } from '../../consts';
import { useGetSingleObjectData, formatDate } from '../../hooks';
import { URLS } from '../../urls/frontend';
import { ButtonCustom, Loader } from '../atoms';
import {
    MetadataBox,
    MetadataBoxItem,
} from '../molecules';
import { BreadcrumbsWithPageTitle } from '../organisms';
import { Cases } from '../molecules/krsSubjectPage';
import './views-global.sass';

export const KRSSubject = ({repoName}) => {
    const repoData = useMemo(() => REPO_TYPES[repoName], [repoName]);

    const { krsNumber } = useParams();

    const { isLoading, data, fetchError } = useGetSingleObjectData(
        krsNumber,
        '/entities',
        {
            krsNumber,
            repoType: repoData.fetchParamName,
        },
        repoName === REPO_TYPES.all.name
            ? 'Nr KRS nie występuje w systemie lub akta tego podmiotu nie występują w żadnym z repozytoriów.'
            : 'Nr KRS nie występuje w systemie lub akta tego podmiotu nie występują we wskazanym repozytorium.',
    );

    const { documents = {}, enterpriseRegistrationDate } = data;
    function getDocumentLink(documentName) {
        const documentIdentifier = (documents || {})[documentName];
        if (!documentIdentifier) { return EMPTY_VALUE}
        return (
            <ButtonCustom
                buttonLink
                to={URLS[
                    repoName === REPO_TYPES.all.name
                        ? REPO_TYPES[!!enterpriseRegistrationDate ? 'rar' : 'rwip'].urlsNames.document
                        : repoData.urlsNames.document
                ].getUrl(documentIdentifier)}
            >
                <Typography variant="srOnly">Zobacz: </Typography>
                {documentIdentifier}
            </ButtonCustom>
        )
    }

    let breadcrumbs = [[URLS.home.path, 'Repozytoria Akt']];
    if (repoName !== REPO_TYPES.all.name) {
        breadcrumbs.push([repoData.frontendUrl, repoData.breadcrumbLinkLabel]);
    }

    function courtWithDepartment(payload) {
        const department = payload.department ? `, \n${payload.department}` : ''
        return payload.court ? `${payload.court}${department}` : EMPTY_VALUE
    }

    return (
        <>
            <BreadcrumbsWithPageTitle
                breadcrumbs={breadcrumbs}
                currentLocation="Przegląd Danych Podmiotu"
                subtitle={krsNumber}
                title="Numer KRS:"
                otherTitles={!!data && [
                    ['Nazwa / Firma Podmiotu:', (data.name || EMPTY_VALUE)],
                ]}
            />
            {isLoading
                ? <Loader />
                : !!fetchError
                    ? <p className="error" role="alert">{fetchError}</p>
                    : (
                        <>
                            <Grid container spacing={1}>
                                <MetadataBox md={4}>
                                    <MetadataBoxItem
                                        label="Status podmiotu"
                                        sm={6}
                                        text
                                    >
                                        {data.status || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Sąd właściwy"
                                        sm={6}
                                        text
                                    >
                                        {courtWithDepartment({court: data.court, department: data.department})}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Forma prawna"
                                        sm={12}
                                        text
                                    >
                                        {data.legalForm || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                </MetadataBox>
                                <MetadataBox md={4}>
                                    <MetadataBoxItem
                                        label="Data wpisu do Rejestru Przedsiębiorców"
                                        sm={6}
                                        text
                                    >
                                        {formatDate(enterpriseRegistrationDate)}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Data wykreślenia z Rejestru Przedsiębiorców"
                                        sm={6}
                                        text
                                    >
                                        {formatDate(data.enterpriseDeletionDate)}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Data wpisu do Rejestru Stowarzyszeń"
                                        sm={6}
                                        text
                                    >
                                        {formatDate(data.associationRegistrationDate)}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Data wykreślenia z Rejestru Stowarzyszeń"
                                        sm={6}
                                        text
                                    >
                                        {formatDate(data.associationDeletionDate)}
                                    </MetadataBoxItem>
                                </MetadataBox>
                                <MetadataBox md={4}>  
                                    <MetadataBoxItem
                                        label="Adresy do doręczeń"
                                        moreInfo="Adresy do doręczeń osób reprezentujących wnioskodawcę zgodnie z art. 19 a ust. 5 uKRS."
                                        moreInfoTitle="Adresy do doręczeń"
                                        sm={6}
                                    >
                                        {getDocumentLink('addresses')}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Uprawnieni do powołania zarządu"
                                        moreInfo="Lista zawierająca nazwisko i imię oraz adres do doręczeń albo firmę lub nazwę i siedzibę członków organu lub osób uprawnionych do powołania zarządu."
                                        moreInfoTitle="Uprawnieni do powołania zarządu"
                                        sm={6}
                                    >
                                        {getDocumentLink('managementBoardEntitled')}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Umowa/statut/tekst jednolity"
                                        moreInfo="Aktualna treść umowy/statutu/ ostatni tekst jednolity."
                                        moreInfoTitle="Umowa/statut/tekst jednolity"
                                        sm={6}
                                    >
                                        {getDocumentLink('agreement')}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Lista wspólników"
                                        moreInfo="Aktualna lista wspólników zawierająca imiona i nazwiska lub firmę wspólnika/wspólników oraz liczbę i wartość nominalną udziałów każdego ze wspólników."
                                        moreInfoTitle="Lista wspólników"
                                        sm={6}
                                    >
                                        {getDocumentLink('partners')}
                                    </MetadataBoxItem>
                                </MetadataBox>
                            </Grid>
                            <Cases krsNumber={krsNumber} repoData={repoData} />
                        </>
                    )
            }
        </>
    )
};
