import { useState } from 'react';
import { Paper, Typography } from '@material-ui/core';

import { getCookieValue } from '../../../helpers/helpers';
import { ButtonCustom } from '../../atoms';
import './cookies.sass';


const COOKIE_NAME = 'prs_accepted';
const COOKIE_VALUE = 'accepted';


export const Cookies = (props) => {
    const [cookieAccepted, setCookieAccepted] = useState(
        getCookieValue(COOKIE_NAME) === COOKIE_VALUE);

    if (cookieAccepted) { return null}

    function handleAcceptCookies() {
        const maxAge = 60 * 60 * 24 * 365 * 10; // 10 years
        document.cookie = `${COOKIE_NAME}=${COOKIE_VALUE};path=/;max-age=${maxAge}`;
        setCookieAccepted(true);
    }

    return (
        <Paper elevation={3} className="cookies">
            <Typography>Ta strona wykorzystuje ciasteczka.</Typography>
            <ButtonCustom onClick={handleAcceptCookies}>
                <Typography variant="srOnly">Akceptuję politykę dotyczącą wykorzystania plików cookies. </Typography>Zamknij<Typography variant="srOnly"> pop-up.</Typography>
            </ButtonCustom>
        </Paper>
    )
};
