import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { ButtonCustom, InputCustom } from '../../atoms';
import './SingleFieldSearchForm.sass';


export const SingleFieldSearchForm = ({
    blurFunction,
    fieldId,
    fieldName,
    inputErrorOrHelperText,
    inputPlaceholder,
    inputType,
    label,
    maxLength,
    onlyDigits = false,
    pasteFunction,
    redirectUrl,
    validationFunction = (() => [true, {}]),
}) => {
    const [value, setValue] = useState('');
    const [formDisabled, setFormDisabled] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const history = useHistory();

    function handleChange(ev) {
        let value_ = ev.target.value;
        if (onlyDigits) {
            value_ = value_.replace(/[^\d]/, '');
        }
        setValue(value_);
    }

    function handleSearch(withBlur=false) {
        setFormErrors({});
        setFormDisabled(true);
        const [isValid, errors] = validationFunction({[fieldName]: value});
        if (isValid) {
            let value_ = value;
            if (withBlur && blurFunction) {
                value_ = blurFunction(value_);
            }
            history.push(redirectUrl.getUrl(value_));
            return
        }
        setFormErrors(errors);
        setFormDisabled(false);
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        handleSearch(true);
    }

    function handleBlur(ev) {
        if (blurFunction && value.length) {
            setValue(blurFunction(value));
        }
    }

    function handlePaste(ev) {
        if (pasteFunction) {
            setValue(pasteFunction(ev));
        }
    }

    const isFieldInvalid = (formErrors[fieldName] || '').length > 0;
    return (
        <>
            <form onSubmit={handleSubmit} className="single-field-search-form">
                <InputCustom
                    errorOrHelperText={(isFieldInvalid && formErrors[fieldName]) || inputErrorOrHelperText}
                    disabled={formDisabled}
                    id={fieldId}
                    isInvalid={isFieldInvalid}
                    label={label}
                    type={inputType}
                    value={value}
                    maxLength={maxLength}
                    placeholder={inputPlaceholder}
                    required
                    showHelperText
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={handlePaste}
                />
                <ButtonCustom
                    disabled={formDisabled}
                    onClick={() => setValue('')}
                    mr={2}
                    className="form-button"
                    variant="outlined"
                >
                    Wyczyść
                </ButtonCustom>
                <ButtonCustom
                    disabled={formDisabled}
                    onClick={handleSearch}
                    className="form-button"
                >
                    Wyszukaj
                </ButtonCustom>
            </form>
            {isFieldInvalid && <Typography role="alert" variant="srOnly">Formularz zawiera błędy!</Typography> }
        </>
    )
};
