import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Typography,
} from '@material-ui/core';

import { URLS } from '../../../urls/frontend';
import './BreadcrumbsCustom.sass';

// USAGE
/* <BreadcrumbsCustom
  breadcrumbs={[
    ['URL1', 'name of the link1'],
    ['URL2', 'name of the link2'],
  ]}
  currentLocation='Name of current location'
/> */

export const BreadcrumbsCustom = ({ currentLocation, breadcrumbs }) => (
  <div className="breadcrumbs-custom">
    <Breadcrumbs aria-label="Nawigacja pomocnicza" >
      <Link to={URLS.home.path}>
        <Typography color="primary" variant="body2">Strona główna</Typography>
      </Link>
      {breadcrumbs && breadcrumbs.map((brc, idx) =>
        <Link
          key={idx}
          color="primary"
          to={brc[0]}
          variant="body2"
        >
          <Typography color="inherit" variant="body2">{brc[1]}</Typography>
        </Link>
      )}
      <Typography color="textPrimary" variant="body2">{currentLocation}</Typography>
    </Breadcrumbs>
  </div>
);
