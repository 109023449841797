import Typography from '@material-ui/core/Typography';

import './TextCustom.sass';

export const TextCustom = ({
  children,
  className,
  ...props
}) => {
  return (
    <Typography
      classes={{ root: 'text-custom' }}
      className={className ? className : ''}
      variant="body1"
    >
      {children}
    </Typography>
  );
};
