import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { REQUEST_ERROR } from '../../consts';
import { fetchApi } from '../../fetch';
import { changePage } from '../../helpers/urls';
import { BoxCustom, Loader } from '../atoms';
import { Pagination, TableCustom } from '../molecules';


export const TableWithoutFilters = ({
    additionalFetchParams={},
    fetchUrl,
    repoData,
    tableEmptyResults,
    tableHeaderList,
    tableItemKeyField,
    tableItemsName,
    tableName,
    tableResultsToReturn = 20,
    TableRowComponent,
    ...props
}) => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const pageNumber = parseInt(params.get('strona')) || 1;

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [fetchError, setFetchError] = useState(null);

    useEffect(() => {
        let xhrFetch = null;
        setFetchError(null);
        setIsLoading(true);

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            setData(data);
            setIsLoading(false);
        }

        function callbackFetchDataError(message='') {
            xhrFetch = null;
            setFetchError(REQUEST_ERROR);
            setIsLoading(false);
        }

        function callbackFetchDataIncorrectStatus(statusCode) {
            callbackFetchDataError();
        }

        xhrFetch = fetchApi({
            url: fetchUrl,
            body: {
                pageNumber,
                resultsToReturn: tableResultsToReturn,
                ...additionalFetchParams
            },
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: () => callbackFetchDataError(),
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [history.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChangePage(next=true) {
        changePage(history, params, pageNumber, next);
    }

    const items = data[tableItemsName] || [];
    return (
        <BoxCustom title={tableName}>
            {isLoading
                ? <Loader />
                : !!fetchError
                    ? <p className="error" role="alert">{fetchError}</p>
                    : items.length > 0
                        ? (
                            <>
                                <TableCustom tableHeaderList={tableHeaderList}>
                                    {items.map(item => (
                                        <TableRowComponent
                                            item={item}
                                            key={item[tableItemKeyField]}
                                            repoData={repoData}
                                        />
                                    ))}
                                </TableCustom>
                                <Pagination
                                    currentPageNumber={pageNumber}
                                    disabled={isLoading}
                                    perPage={tableResultsToReturn}
                                    total={(data.meta || {}).total || 0}
                                    onChangePage={handleChangePage} />
                            </>
                        )
                        : <p className="error" role="alert">{tableEmptyResults}</p>
            }
        </BoxCustom>
    )
};
