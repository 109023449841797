export function checkPasswordMeetsRules(value) {
    let error = null;
    /* validate if new password meets the rules
        at least:
            - 8 chars
            - one upper letter
            - one lower letter
            - one digit
            - one special char: !@#$%^&*()_+-=
    */
    if (value.length < 8) {
        error = 'Hasło powinno składać się co najmniej z 8 znaków.';
    } else if (!/[\d]/.test(value)) {
        error = 'Hasło powinno zawierać co najmniej jedną cyfrę.';
    } else if (!/[A-Z]/.test(value)) {
        error = 'Hasło powinno zawierać co najmniej jedną wielką literę.';
    } else if (!/[a-z]/.test(value)) {
        error = 'Hasło powinno zawierać co najmniej jedną małą literę.';
    } else if (!/[=!@#$%^&*()_+-]/.test(value)) {
        error = 'Hasło powinno zawierać co najmniej jeden znak specjalny !@#$%^&*()_+-=';
    }
    return error
}


export const validate = (formData) => {
    let isValid = true;
    let errors = {};

    // validate if not empty
    for (let fieldName of Object.keys(formData)) {
        if (!formData[fieldName]) {
            errors[fieldName] = 'To pole jest wymagane.';
            isValid = false;
        }
    }

    const { newPassword, newPassword2 } = formData;
    if (newPassword && newPassword2) {
        if (newPassword !== newPassword2) {
            // validate if new passwords are equal
            errors.newPassword2 = 'Nowe hasła nie zgadzają się.';
            isValid = false;
        } else {
            // check rules
            const error = checkPasswordMeetsRules(newPassword);
            if (error) {
                errors.newPassword = error;
                isValid = false;
            }
        }
    }
    return [isValid, errors]
};
