import {
    END_DATE_TO_HAS_TO_BE_GREATER_THAN_END_DATE_FROM,
    START_DATE_TO_HAS_TO_BE_GREATER_THAN_START_DATE_FROM
} from '../consts';
import { isValidDate, isValidRangeFieldsPair } from './helpers';


export const validate = (formData) => {
    let isValid = true;
    let errors = {};

    for (let fieldName of [
        'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'
    ]) {
        if (!isValidDate(fieldName, formData[fieldName], errors)) {
            isValid = false;
        }
    }

    // form errors
    const invalidFields = Object.keys(errors);
    for (let datePairConfig of [
        [
            'startDateFrom', 'startDateTo', 
            formData.startDateFrom, formData.startDateTo,
            errors, invalidFields, true,
            START_DATE_TO_HAS_TO_BE_GREATER_THAN_START_DATE_FROM
        ],
        [
            'endDateFrom', 'endDateTo',
            formData.endDateFrom, formData.endDateTo,
            errors, invalidFields, true,
            END_DATE_TO_HAS_TO_BE_GREATER_THAN_END_DATE_FROM
        ]
    ]) {
        if (!isValidRangeFieldsPair(...datePairConfig)) {
            isValid = false;
        }
    }

    return [isValid, errors]
};
