// Gives standard dialog elements as props: DialogTitle, DialogContent and DialogActions for confirm and cancel buttons.
// This dialog is controlled (opened, closed) on its own, dialog opening button is build-in.

import { useState } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';

import { ButtonCustom } from '../atoms';
import { IconButtonWithTooltip } from '.';
import { DialogBase } from './DialogBase';

export const DialogWithButton = ({
  buttonClassName,
  disabled,
  dialogBtnIcon,
  dialogOpenBtnIconText,
  dialogOpenBtnText,
  iconEdge,
  onDialogOpen,
  variant,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (onDialogOpen) {
      onDialogOpen();
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  }

  return (
    <>
      {dialogOpenBtnText && <ButtonCustom disabled={disabled} onClick={handleClickOpen} variant={variant}>{dialogOpenBtnText}</ButtonCustom>}
      {dialogOpenBtnIconText && (
        <IconButtonWithTooltip
          className={buttonClassName}
          disabled={disabled}
          srOnlyText={dialogOpenBtnIconText}
          onClick={handleClickOpen}
          edge={iconEdge}
        >
          {dialogBtnIcon || <DescriptionIcon />}
        </IconButtonWithTooltip>
      )}
      <DialogBase
        open={open}
        onDialogClose={handleDialogClose}
        {...props}
      />
    </>
  );
};
