
export const validate = (formData) => {
    let isValid = true;
    let errors = {};
    const value = formData.krsNumber;
    if (!value) {
        errors.krsNumber = 'To pole jest wymagane.';
        isValid = false;
    } else if (value.length > 10) {
        errors.krsNumber = 'Numer KRS może mieć maksymalnie 10 cyfr.';
        isValid = false;
    }
    return [isValid, errors]
};
