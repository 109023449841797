import { Component, createContext } from 'react';

export const AppContext = createContext({
    authorizationFocused: false,
    contentFocused: false,
    menuFocused: false,
    setAuthorizationFocused: () => {},
    setContentFocused: () => {},
    setMenuFocused: () => {},
});

export class AppProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authorizationFocused: false,
            contentFocused: false,
            menuFocused: false,
            setAuthorizationFocused: this.setAuthorizationFocused,
            setContentFocused: this.setContentFocused,
            setMenuFocused: this.setMenuFocused,
        };
    }

    setAuthorizationFocused = (authorizationFocused) => {
        this.setState({ authorizationFocused });
    }

    setContentFocused = (contentFocused) => {
        this.setState({ contentFocused });
    }

    setMenuFocused = (menuFocused) => {
        this.setState({ menuFocused });
    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
};
