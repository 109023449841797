import { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';

import {
    KRS_SUBJECT_INPUT_MAX_LENGTH,
    KRS_SUBJECT_PLACEHOLDER, REPO_TYPES
} from '../../consts';
import { UserContext } from '../../context/userContext';
import {
    getBlurredKrsNumberValue,
    getPastedKrsNumberValue
} from '../../helpers/helpers';
import { URLS } from '../../urls/frontend';
import { CaseTableRow, LinkTail } from '../molecules';
import {
    BreadcrumbsWithPageTitle,
    ServiceMessages,
    SingleFieldSearchForm,
    TableWithoutFilters
} from '../organisms';
import {
    validate as validateKRSSubject
} from '../../validation/KRSSubjectForm';

import './views-global.sass';


export const Home = (props) => {
    const { user } = useContext(UserContext);
    return (
        <>
            <BreadcrumbsWithPageTitle
                currentLocation='Repozytoria Akt'
                title="Wybierz repozytorium"
            />
            <ServiceMessages />

            {user.isInternal && (
                <SingleFieldSearchForm
                    blurFunction={getBlurredKrsNumberValue}
                    fieldId="id_krsNumber"
                    fieldName="krsNumber"
                    inputErrorOrHelperText="W polu możesz wpisać tylko cyfry"
                    inputPlaceholder={KRS_SUBJECT_PLACEHOLDER}
                    label="Numer KRS"
                    maxLength={KRS_SUBJECT_INPUT_MAX_LENGTH}
                    onlyDigits={true}
                    pasteFunction={getPastedKrsNumberValue}
                    redirectUrl={URLS.allKRSSubject}
                    validationFunction={validateKRSSubject}
                />
            )}

            <Box
                component="nav"
                m={5}
                mx={0}
                aria-labelledby="mainNav"
            >
                <h2 className="sr-only" id="mainNav">Nawigacja główna</h2>
                <Grid
                    container
                    spacing={3}
                    component="ul"
                >
                    <LinkTail
                        to={URLS.rarSearch.path}
                        linkName="Repozytorium akt rejestrowych"
                        linkSubtitle="Zawierające akta spraw dotyczących podmiotu wpisanego do rejestru przedsiębiorców KRS"
                    />
                    <LinkTail
                        to={URLS.rasSearch.path}
                        linkName="Repozytorium akt spraw"
                        linkSubtitle="Dotyczących podmiotów niewpisanych do KRS"
                    />
                    {user.isInternal && (
                        <LinkTail
                            to={URLS.rwipSearch.path}
                            linkName="Repozytorium wniosków i pism elektronicznych"
                            linkSubtitle="Akta spraw dotyczących podmiotów podlegających wpisowi tylko do rejestru stowarzyszeń KRS"
                        />
                    )}
                </Grid>
            </Box>

            {/* all means RAR, RAS or RWIP in this case */}
            {(user.isExternalNamed || user.isExternalOneTime) && (
                <TableWithoutFilters
                    additionalFetchParams={{
                        context: 'CURRENT_USER',
                        repoType: REPO_TYPES.all.fetchParamName,
                    }}
                    fetchUrl="/cases"
                    repoData={REPO_TYPES.all}
                    tableEmptyResults="Brak spraw do wyświetlenia."
                    tableHeaderList={[
                        'Repozytorium', 'Sygnatura', 'Rodzaj', 'Stan sprawy',
                        'Data wszczęcia', 'Data zakreślenia', ''
                    ]}
                    tableItemKeyField="caseReference"
                    tableItemsName="cases"
                    tableName="Wykaz spraw z pełnym dostępem do dokumentów"
                    TableRowComponent={CaseTableRow}
                />
            )}
        </>
    )
};
