import { InputCustom } from '../atoms';

export const DatePickerCustom = ({
  dateErrorText,
  dateFormatInfo,
  type,
  ...props
}) => {
  return (
    <InputCustom
      type={type || 'date'}
      errorOrHelperText={dateErrorText || dateFormatInfo || 'Wybierz datę według wzoru: dd.mm.rrrr'}
      {...props}
    />
  );
};
