const getSearchUrl = (repoName) => {
    return `/${repoName}/wyszukiwanie`
};

const getKRSSubjectUrl = (repoName, krsNumber) => {
    return `/${repoName}/dane-podmiotu/${krsNumber}`
};

const getCaseUrl = (repoName, caseReference) => {
    return `/${repoName}/dane-sprawy/${btoa(caseReference)}`
};

const getDocumentUrl = (repoName, identifier) => {
    return `/${repoName}/dane-dokumentu/${btoa(identifier)}`
};

const getDocumentInCaseUrl = (repoName, identifier, caseReference) => {
    return `/${repoName}/dane-dokumentu/${btoa(identifier)}/sprawa/${btoa(caseReference)}`
};

const getRarDocumentInCaseUrl = (repoName, identifier, caseReference, metricIdentifier) => {
    return `/${repoName}/dane-dokumentu/${btoa(identifier)}/${btoa(metricIdentifier)}/sprawa/${btoa(caseReference)}`
};


export const URLS = {
    home: {
        path: '/',
    },
    caseDetails: {
        path: '/dane-sprawy/:caseReference',
    },
    documentInCaseDetails: {
        path: '/dane-dokumentu/:identifier/sprawa/:caseReference',
    },
    // RAR
    rarSearch: {
        path: getSearchUrl('rar'),
    },
    rarKRSSubject: {
        path: '/rar/dane-podmiotu/:krsNumber',
        getUrl: (krsNumber) => (getKRSSubjectUrl('rar', krsNumber)),
    },
    rarCase: {
        path: '/rar/dane-sprawy/:caseReference',
        getUrl: (caseReference) => (getCaseUrl('rar', caseReference)),
    },
    rarDocumentsByKrsNumber: {
        path: '/rar/lista-dokumentow/numer-krs',
    },
    rarDocumentsByCaseReference: {
        path: '/rar/lista-dokumentow/sygnatura-sprawy',
    },
    rarDocument: {
        path: '/rar/dane-dokumentu/:identifier',
        getUrl: (identifier) => (getDocumentUrl('rar', identifier)),
    },
    rarDocumentInCase: {
        path: '/rar/dane-dokumentu/:identifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference) => (
            getDocumentInCaseUrl('rar', identifier, caseReference)),
    },
    rarDocumentInCaseWithMetric: {
        path: '/rar/dane-dokumentu/:identifier/:metricIdentifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference, metricIdentifier) => (
            getRarDocumentInCaseUrl('rar', identifier, caseReference, metricIdentifier)),
    },
    // RAS
    rasSearch: {
        path: getSearchUrl('ras'),
    },
    rasCase: {
        path: '/ras/dane-sprawy/:caseReference',
        getUrl: (caseReference) => (getCaseUrl('ras', caseReference)),
    },
    rasDocumentsByCaseReference: {
        path: '/ras/lista-dokumentow/sygnatura-sprawy',
    },
    rasDocument: {
        path: '/ras/dane-dokumentu/:identifier',
        getUrl: (identifier) => (getDocumentUrl('ras', identifier)),
    },
    rasDocumentInCase: {
        path: '/ras/dane-dokumentu/:identifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference) => (
            getDocumentInCaseUrl('ras', identifier, caseReference)),
    },
    rasDocumentInCaseWithMetric: {
        path: '/ras/dane-dokumentu/:identifier/:metricIdentifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference, metricIdentifier) => (
            getRarDocumentInCaseUrl('ras', identifier, caseReference, metricIdentifier)),
    },
    // RP
    rpDocument: {
        path: '/rp/dane-dokumentu/:identifier',
        getUrl: (identifier) => (getDocumentUrl('rp', identifier)),
    },
    // RWiP
    rwipSearch: {
        path: getSearchUrl('rwip'),
    },
    rwipKRSSubject: {
        path: '/rwip/dane-podmiotu/:krsNumber',
        getUrl: (krsNumber) => (getKRSSubjectUrl('rwip', krsNumber)),
    },
    rwipCase: {
        path: '/rwip/dane-sprawy/:caseReference',
        getUrl: (caseReference) => (getCaseUrl('rwip', caseReference)),
    },
    rwipDocumentsByKrsNumber: {
        path: '/rwip/lista-dokumentow/numer-krs',
    },
    rwipDocumentsByCaseReference: {
        path: '/rwip/lista-dokumentow/sygnatura-sprawy',
    },
    rwipDocument: {
        path: '/rwip/dane-dokumentu/:identifier',
        getUrl: (identifier) => (getDocumentUrl('rwip', identifier)),
    },
    rwipDocumentInCase: {
        path: '/rwip/dane-dokumentu/:identifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference) => (
            getDocumentInCaseUrl('rwip', identifier, caseReference)),
    },
    rwipDocumentInCaseWithMetric: {
        path: '/rwip/dane-dokumentu/:identifier/:metricIdentifier/sprawa/:caseReference',
        getUrl: (identifier, caseReference, metricIdentifier) => (
            getRarDocumentInCaseUrl('rwip', identifier, caseReference, metricIdentifier)),
    },
    // ALL
    allKRSSubject: {
        path: '/dane-podmiotu/:krsNumber',
        getUrl: (krsNumber) => (`/dane-podmiotu/${krsNumber}`),
    },
    help: {
        path: '/pomoc',
    },
};
