// Gives only dialog wrapper. DialogTitle, DialogContent and DialogActions (with buttons) should be set in parent as dialog {children}
// This dialog is controlled (opened, closed) by its parent.

import { Dialog, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import './DialogNaked.sass';

export const DialogNaked = ({
  children,
  onDialogClose,
  open,
  role,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Dialog
        classes={{ paper: 'dialog-naked' }}
        fullScreen={fullScreen}
        maxWidth={'lg'}
        open={open}
        onClose={(_, reason) => reason !== 'backdropClick' && onDialogClose()}
        role={role || 'dialog'}
        disableEscapeKeyDown
      >
        {children}
      </Dialog>
    </>
  );
};
