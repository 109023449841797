import { FiltersForm } from '../organisms';
import { CasesFiltersFields } from './CasesFiltersFields';


export const CasesFiltersForm = ({
    defaultFilters,
    disabled,
    errors,
    initialFilters={},
    onFilter,
    ...props
}) => {

    return (
        <FiltersForm
            dateNamesList={[
                'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'
            ]}
            defaultFilters={defaultFilters}
            disabled={disabled}
            errors={errors}
            FiltersFieldsComponent={CasesFiltersFields}
            initialFilters={initialFilters}
            onFilter={onFilter}
            selectDictNamesList={[
                'caseKind', 'caseStatus', 'caseApplicationType',
                'caseStartType', 'caseEndType'
            ]}
            selectDictNameSelectNameList={[
                ['caseKind', 'caseType'],
                ['caseStatus', 'caseStatus'],
                ['caseApplicationType', 'caseApplicationType'],
                ['caseStartType', 'caseStartType'],
                ['caseEndType', 'caseEndType'],
            ]}
        />
    )
};
