import { isFirefox } from 'react-device-detect';

import { FILTERS_DATE_FIELDS_DATA } from '../../consts';
import { DatePickerCustom } from './DatePickerCustom';
import { DatePickerReact } from './DatePickerReact';
import { dateToString, datetimeToString } from '../../helpers/helpers';
import './molecules-global.sass';

export const DatesFields = ({
    disabled,
    errors,
    fields,
    filtersIds,
    onChangeFilter
}) => {
    return FILTERS_DATE_FIELDS_DATA.map(filterData => {
        if (!filtersIds.includes(filterData.id)) {
            return null
        }
        return (
            <fieldset key={filterData.id} className="date-fields__container">
                <legend className="date-fields__legend">{filterData.label}</legend>
                <div className="date-fields">
                    {['from', 'to'].map(caseType => {
                        const data = filterData[caseType];
                        const { filterName } = data;
                        return (
                            isFirefox ? (
                                <DatePickerReact
                                    dateErrorText={!!errors[filterName] && errors[filterName]}
                                    dateFormatInfo={data.isDateTimeField && 'Wybierz datę według wzoru: dd.mm.rrrr oraz czas według wzoru: hh:mm'}
                                    disabled={disabled}
                                    id={data.id}
                                    isInvalid={!!errors[filterName]}
                                    key={data.id}
                                    label={caseType === 'from' ? 'Od' : 'Do'}
                                    onChange={dt => onChangeFilter(
                                        filterName, data.isDateTimeField ? datetimeToString(dt) : dateToString(dt)
                                    )}
                                    selected={(fields[filterName] && fields[filterName].length > 0) ? new Date(fields[filterName]) : null}
                                    showTimeSelect={data.isDateTimeField}
                                />
                            ) : (
                                <DatePickerCustom
                                    inputContainerClassName={data.isDateTimeField ? 'date-time-picker' : null}
                                    dateErrorText={!!errors[filterName] && errors[filterName]}
                                    dateFormatInfo={data.isDateTimeField && 'Wybierz datę według wzoru: dd.mm.rrrr oraz czas według wzoru: hh:mm'}
                                    disabled={disabled}
                                    id={data.id}
                                    isInvalid={!!errors[filterName]}
                                    key={data.id}
                                    label={caseType === 'from' ? 'Od' : 'Do'}
                                    type={data.isDateTimeField
                                        ? 'datetime-local' : 'date'
                                    }
                                    value={fields[filterName]}
                                    onChange={ev => onChangeFilter(
                                        filterName, ev.target.value
                                    )}
                                />
                            )
                            )
                        })}
                </div>
            </fieldset>
        )
    })
};
