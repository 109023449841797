import { useEffect, useState } from 'react';

import { fetchApi } from '../../fetch';
import { DialogBase } from '../molecules';


export const RefreshSessionDialog = ({min, sec, onSessionRefreshingFailed}) => {
    const [refreshingOn, setRefreshingOn] = useState(false);

    useEffect(() => {
        if (!refreshingOn) { return }

        let xhrFetch = null;

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
        }

        function callbackFetchDataError() {
            xhrFetch = null;
            onSessionRefreshingFailed();
        }

        xhrFetch = fetchApi({
            url: '/refresh',
            method: 'POST',
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataError,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [refreshingOn]); // eslint-disable-line react-hooks/exhaustive-deps

    if (refreshingOn) {
        return 'Trwa przedłużanie czasu sesji'
    }

    return (
        <DialogBase
            dialogTitle="Przedłuż czas sesji"
            dialogConfirmBtnText="Przedłuż czas sesji"
            disabledConfirm={refreshingOn}
            hideCloseButton
            onDialogConfirm={() => setRefreshingOn(true)}
            open
            role="alertdialog"
        >
            <p role="timer">Do końca sesji pozostało {min} minut {sec} sekund</p>
        </DialogBase>
    )
};
