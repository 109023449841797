import { Box, Typography } from '@material-ui/core';

export const BoxCustom = ({
  accent,
  bgColor,
  boxTag,
  boxTitleTag,
  children,
  className,
  m,
  mb,
  ml,
  mx,
  pContent,
  pyContent,
  title,
  width,
  ...props
}) => (
  <Box
    className={className}
    component={boxTag || 'section'}
    width={width || 1}
    m={m || 0}
    mb={mb || 2}
    ml={ml || 0}
    mx={mx}
    {...props}
  >
    {title && (
      <Box
        color={accent && 'white'}
        bgcolor={accent ? 'primary.main' : 'transparent'}
        p={2}
        py={accent ? 1.5 : 2}
        {...props}
      >
        <Typography component={boxTitleTag || 'h3'} variant="h6" >
          {title}
        </Typography>
      </Box>
    )}
    <Box
      p={pContent || 2}
      py={pyContent || 1.5}
      bgcolor={bgColor || 'white'}
      border={1}
      borderColor={accent ? 'primary.main' : 'grey.300'}
    >
      {children}
    </Box>
  </Box>
);
