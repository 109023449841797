import { INVALID } from '../consts';


export const isValidDate = (fieldName, value, errorsDict) => {
    if (value.length && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        errorsDict[fieldName] = INVALID;
        return false
    }
    return true
};

export const isValidDateTime = (fieldName, value, errorsDict) => {
    if (value.length && !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value)) {
        errorsDict[fieldName] = INVALID;
        return false
    }
    return true
};

export const isValidRangeFieldsPair = (
    fieldNameFrom, fieldNameTo, valueFrom, valueTo, errorsDict, invalidFields,
    areDateType, errorMessage
) => {
    if (
        valueFrom &&
        valueTo &&
        !invalidFields.includes(fieldNameFrom) &&
        !invalidFields.includes(fieldNameTo) &&
        (areDateType
            ? new Date(valueFrom) > new Date(valueTo)
            : valueFrom > valueTo
        )
    ) {
        if (!errorsDict.formErrors) {
            errorsDict.formErrors = [];
        }
        errorsDict.formErrors.push(errorMessage);
        return false
    }
    return true
};
