import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import { URLS } from '../../../urls/frontend';
import { ButtonCustom } from '../../atoms';
import { IconButtonWithTooltip, TopAppBar } from '../../molecules';
import './AppBarWithMenu.sass';

export const AppBarWithMenu = (props) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { menuFocused, setMenuFocused } = useContext(AppContext);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    menuFocused && setOpen(true);

    return () => {
      setMenuFocused(false);
    }
  }, [menuFocused, setMenuFocused, setOpen]);

  function handleDrawerOpen() {
    setOpen(true);
  };

  function handleDrawerClose() {
    setOpen(false);
  };

  return (
    <>
      <TopAppBar
        ariaControls="menuDrawer"
        onMenuClick={handleDrawerOpen}
        className={open ? 'AppBarWithMenu__menu-open' : ''}
      />
      <Drawer
        className="drawer"
        classes={{paper: "drawer__content",}}
        anchor="left"
        open={open}
        aria-expanded={open}
        id="menuDrawer"
      >
        <div className="drawer__header">
          <IconButtonWithTooltip
            color="inherit"
            onClick={handleDrawerClose}
            srOnlyText="Zamknij menu boczne"
            aria-expanded={true}
            aria-controls="menuDrawer"
          >
            <ChevronLeftIcon />
          </IconButtonWithTooltip>
        </div>
        <Divider />
        <List>
          <ListItem><ButtonCustom component={Link} variant="outlined" to={URLS.rarSearch.path} key={1}>
            Repozytorium akt rejestrowych</ButtonCustom></ListItem>
          <ListItem><ButtonCustom width={1} fullWidth component={Link} variant="outlined" to={URLS.rasSearch.path} key={2}>Repozytorium akt spraw dotyczących podmiotów niewpisanych do KRS</ButtonCustom></ListItem>
          {user.isInternal && (
            <ListItem><ButtonCustom component={Link} variant="outlined" to={URLS.rwipSearch.path} key={3}>Repozytorium wniosków i pism elektronicznych</ButtonCustom></ListItem>
          )}
          <ListItem className="help-link">
            <ButtonCustom
              component={Link}
              fullWidth
              key={4}
              to={ URLS.help.path }
              variant="outlined"
            >Pomoc</ButtonCustom>
          </ListItem>
          {user.isInternal && (
            <ListItem>
              <ButtonCustom
                component={'a'}
                download="pomoc_dla_pracownikow_sadowych_rar"
                fullWidth
                href="/rar.instrukcja_uzytkownika-uzytkownik_wewnetrzny.pdf"
                key={5}
                variant="outlined"
              >Pobierz instrukcję dla pracownika sądowego</ButtonCustom>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  )
};

export default AppBarWithMenu;
