import { Link } from 'react-router-dom';
import {
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { EMPTY_VALUE, REPO_TYPES } from '../../../consts';
import { URLS } from '../../../urls/frontend';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { ButtonCustom } from '../../atoms';

import '../molecules-global.sass';


export const DocumentTableRow = ({item, repoData}) => {
    return (
        <TableRow hover>
            <TableCell>{item.identifier}</TableCell>
            <TableCell>{item.type || EMPTY_VALUE}</TableCell>
            <TableCell className="table__cell-nowrap">{item.arrivedDate || EMPTY_VALUE}</TableCell>
            {repoData.name !== REPO_TYPES.rp.name && (
                <TableCell className="table__cell-nowrap">
                    {(item.cases || []).map(case_ => (
                        <ButtonCustom
                            buttonLink
                            key={case_.caseReference}
                            to={URLS[repoData.urlsNames.documentInCase].getUrl(
                                item.identifier, case_.caseReference
                            )}
                            m={1}
                        >
                            <Typography variant="srOnly">Przejdź do sprawy: </Typography>{case_.caseReference}
                        </ButtonCustom>
                    ))}
                </TableCell>
            )}
            <TableCell>
                <IconButtonWithTooltip
                    component={Link}
                    to={URLS[repoData.urlsNames.document].getUrl(item.identifier)}
                    srOnlyText="Przejdź do dokumentu"
                >
                    <VisibilityOutlinedIcon />
                </IconButtonWithTooltip>
            </TableCell>
        </TableRow>
    )
};
