import { useState } from 'react';
import { CaseTableRow, TableCustom } from '../../molecules';
import {
    CasesForDocumentDocumentInCaseMetaData
} from './CasesForDocumentDocumentInCaseMetaData';


export const CasesForDocument = ({items, repoData}) => {
    const [documentPreviewData, setDocumentPreviewData] = useState(null);
    return (
        <>
            <TableCustom
                tableHeaderList={[
                    'Sygnatura sprawy', 'Rodzaj sprawy', 'Stan sprawy',
                    'Data wszczęcia', 'Data zakreślenia', 'Więcej o sprawie'
                ]}
            >
                {items.map(item => (
                    <CaseTableRow
                        item={item}
                        key={item.caseReference}
                        repoData={repoData}
                        onDocumentPreview={(caseReference, documentData) =>
                            setDocumentPreviewData(
                                {caseReference, documentData})
                        }/>
                ))}
            </TableCustom>
            {documentPreviewData && (
                <CasesForDocumentDocumentInCaseMetaData
                    data={documentPreviewData}
                    onHide={() => setDocumentPreviewData(null)}
                />
            )}
        </>
    )
};
