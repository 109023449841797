import { useMemo } from 'react';

import {
    DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS,
    DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS,
    DOCUMENTS_SERIALIZATION_URL_LIST,
    REPO_TYPES
} from '../../consts';
import { URLS } from '../../urls/frontend';
import { validate } from '../../validation/DocumentsFiltersForm';
import { BreadcrumbsWithPageTitle, TableWithFilters} from '../organisms';
import {
    DocumentsFiltersForm,
    DocumentTableRow
} from '../molecules/documentsPage';


const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of DOCUMENTS_SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}


function getDefaultFilters(mode) {
    return mode === 'krsSubject'
        ? {...DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS}
        : {...DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS}
}


export const Documents = ({mode, repoName}) => {
    const repoData = useMemo(() => REPO_TYPES[repoName], [repoName]);

    const additionalFetchParams = useMemo(
        () => ({
            context: mode === 'krsSubject' ? 'KRS_SUBJECT' : 'CASE_REFERENCE',
            repoType: repoData.fetchParamName
        }), [mode, repoData.fetchParamName]
    );

    const defaultFilters = useMemo(
        () => (getDefaultFilters(mode)), [mode]);

    const filtersSelectFieldNames = ['type', 'accessLevel', 'language'];

    const tableHeaderList = [
        'Identyfikator', 'Rodzaj dokumentu', 'Data wpływu', 'Sygnatury spraw',
        'Więcej o dokumencie',
    ];

    return (
        <>
            <BreadcrumbsWithPageTitle
                breadcrumbs={[
                    [URLS.home.path, 'Repozytoria Akt'],
                    [repoData.frontendUrl, repoData.breadcrumbLinkLabel],
                ]}
                currentLocation="Wyszukiwanie dokumentów"
                subtitle="Wyszukiwanie dokumentów"
                title={`${repoData.breadcrumbLinkLabel}:`}
            />
            <TableWithFilters
                additionalFetchParams={additionalFetchParams}
                defaultFilters={defaultFilters}
                fetchUrl="/documents"
                FiltersComponent={DocumentsFiltersForm}
                filtersComponentParams={{mode}}
                filtersSelectFieldNames={filtersSelectFieldNames}
                repoData={repoData}
                serializationUrlDictToPolish={SERIALIZATION_URL_DICT_TO_POLISH}
                tableEmptyResults="Brak dokumentów spełniających podane kryteria."
                tableHeaderList={tableHeaderList}
                tableItemKeyField="identifier"
                tableItemsName="documents"
                tableName="Dokumenty"
                TableRowComponent={DocumentTableRow}
                validationAdditionalFunctionAttributes={[
                    mode === 'krsSubject', mode === 'caseReference'
                ]}
                validationFunction={validate}
            />
        </>
    )
};
