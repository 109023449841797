import { useState } from 'react';

import { EMPTY_VALUE } from '../../../consts';
import { formatFileFormat, formatFileSize } from '../../../helpers/helpers';
import { MetadataBoxItem } from '../../molecules';
import { BoxCustom, ButtonCustom } from '../../atoms';
import '../molecules-global.sass';

const TYPES_TO_DISPLAY_JUDGEMENT_FINAL_AT = ['Orzeczenie', 'Postanowienie'].map((type) => type.toLowerCase());

export const DocumentInCaseMetaData = ({ data }) => {
    const [advancedMetaDataOn, setAdvancedMetaDataOn] = useState(false);

    const isVisibleJudgementFinalAt = data && data.type && TYPES_TO_DISPLAY_JUDGEMENT_FINAL_AT.includes(data.type.toLowerCase())

    return (
        <section className="advanced-metadata">
            <ButtonCustom
                className="advanced-metadata__btn"
                onClick={() => setAdvancedMetaDataOn(
                    prevValue => !prevValue
                )}
            >
                {advancedMetaDataOn ? 'Ukryj wszystkie metadane' : 'Pokaż wszystkie metadane'}
            </ButtonCustom>
            <BoxCustom boxTag="div" className="advanced-metadata__info-container">
                {advancedMetaDataOn && (
                    <div className="advanced-metadata__metadata">
                        <MetadataBoxItem label="Poziom dostępu" sm={2}>
                            {(data.accessLevel || {}).value || EMPTY_VALUE}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Rozmiar pliku" sm={2}>
                            {formatFileSize(data.fileSize)}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Format pliku" sm={2}>
                            {formatFileFormat(data.format)}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Czy dokument wpłynął jako elektroniczny?" sm={2}>
                            {data.digital ? 'TAK' : 'NIE'}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Język" sm={2}>
                            {data.language || EMPTY_VALUE}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Status w sprawie" sm={2}>
                            {data.statusInCase.value || data.statusInCase || EMPTY_VALUE}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Sygnatura sprawy, z której został przeniesiony" sm={2}>
                            {data.caseReferenceFrom || EMPTY_VALUE}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Sygnatura sprawy, do której został przeniesiony" sm={2}>
                            {data.caseReferenceTo || EMPTY_VALUE}
                        </MetadataBoxItem>
                        <MetadataBoxItem label="Data przeniesienia / wyłączenia" sm={2}>
                            {data.transferredDate || EMPTY_VALUE}
                        </MetadataBoxItem>
                        {isVisibleJudgementFinalAt && <MetadataBoxItem label="Data prawomocności orzeczenia" sm={2}>
                            {data.judgementFinalAt || EMPTY_VALUE}
                        </MetadataBoxItem>}
                    </div>
                )}
            </BoxCustom>
        </section>
    )
};
