import { Typography } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';

import { ButtonCustom } from '../../atoms';
import './ClearFiltersButton.sass';

export const ClearFiltersButton = ({ filterType, filterButtonName, ...props}) => (
  <ButtonCustom
    className="clear-filters-btn"
    startIcon={<BackspaceIcon />}
    {...props}
  >
    Wyczyść<Typography variant="srOnly"> pola {filterType || 'filtórw'}. Kliknij przycisk {filterButtonName || 'Filtruj'}, aby zaktualizować wyniki.</Typography>
  </ButtonCustom>
);
