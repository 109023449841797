import { TableCell, TableRow } from '@material-ui/core';

import { EMPTY_VALUE } from '../../../consts';


export const SignatureTableRow = ({item}) => {
    return (
        <TableRow>
            <TableCell>{item.signer || EMPTY_VALUE}</TableCell>
            <TableCell>{item.signedDate || EMPTY_VALUE}</TableCell>
            <TableCell>{item.type || EMPTY_VALUE}</TableCell>
        </TableRow>
    )
};

