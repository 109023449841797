import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useGetSingleObjectData } from '../../hooks';
import { Loader, NotFoundPage } from '../atoms';


export const CaseOrDocumentDetails = (props) => {

    const [repoCheckError, setRepoCheckError] = useState(false);
    let { caseReference } = useParams();

    const { isLoading, data, fetchError } = useGetSingleObjectData(
        caseReference,
        `/checkRepository/${caseReference}`,
        {},
        'W żadnym z repozytoriów nie odnaleziono szukanej sprawy.',
        false,   // it's already encoded
    );

    const history = useHistory();

    useEffect(() => {
        if (isLoading) { return }
        const repoName = data.repoName || '';
        if (['rar', 'ras', 'rp', 'rwip'].includes(repoName)) {
            history.replace(`/${repoName}${history.location.pathname}`);
        } else {
            setRepoCheckError(true);
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    return isLoading
        ? <Loader />
        : (!!fetchError || !!repoCheckError) ? <NotFoundPage /> : null
};
