import { useMemo } from 'react';

import { FiltersForm } from '../../organisms';
import { DocumentsFiltersFields } from '../DocumentsFiltersFields';


function getDateNamesList(mode) {
    return [
        ...[
            'arrivedDateFrom', 'arrivedDateTo',
            'composedDateFrom', 'composedDateTo',
        ],
        ...(mode === 'krsSubject' ? [] : [
            'disclosedDateFrom', 'disclosedDateTo',
            'transferredDateFrom', 'transferredDateTo'
        ])
    ]
}

function getTextNamesList(mode) {
    let textNamesList = ['identifier'];
    if (mode === 'krsSubject') {
        textNamesList.push('krsNumber');
    }
    if (mode === 'caseReference') {
        textNamesList = [
            ...textNamesList,
            ...['caseReference', 'numberInCase', 'caseReferenceFrom',
                'caseReferenceTo']
        ];
    }
    return textNamesList
}

function getCleanedFilters(mode, defaultFilters) {
    let filters = {...defaultFilters};
    if (mode === 'krsSubject') {
        delete filters['krsNumber'];
    }
    if (mode === 'caseReference') {
        delete filters['caseReference'];
    }
    return filters
}


export const DocumentsFiltersForm = ({
    defaultFilters,
    disabled,
    errors,
    initialFilters={},
    mode,
    repoData,
    onFilter,
    ...props
}) => {
    const dateNamesList = useMemo(
        () => (getDateNamesList(mode)), [mode]);
    const textNamesList = useMemo(
        () => (getTextNamesList(mode)), [mode]);
    const cleanedFilters = useMemo(
        () => (getCleanedFilters(mode, defaultFilters)),
        [mode, defaultFilters]
    );

    return (
        <FiltersForm
            cleanedFilters={cleanedFilters}
            dateNamesList={dateNamesList}
            defaultFilters={defaultFilters}
            disabled={disabled}
            errors={errors}
            FiltersFieldsComponent={DocumentsFiltersFields}
            initialFilters={initialFilters}
            selectDictNamesList={[
                'documentKind', 'documentAccessLevel', 'documentLanguage'
            ]}
            selectDictNameSelectNameList={[
                ['documentKind', 'type'],
                ['documentAccessLevel', 'accessLevel'],
                ['documentLanguage', 'language']
            ]}
            textNamesList={textNamesList}
            onFilter={onFilter}
        />
    )
};
