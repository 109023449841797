import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { EMPTY_VALUE } from '../../../consts';
import { formatFileFormat, formatFileSize } from '../../../helpers/helpers';
import { BoxCustom  } from '../../atoms';
import { LabeledElement } from '../../molecules';

export const DocumentMetaData = ({caseReference, data}) => {
    const theme = useTheme();
    const breakpointXs = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        accessLevel,
        arrivedDate,
        composedDate,
        digital,
        disclosedDate,
        fileSize,
        format,
        identifier,
        language,
        numberInCase,
        statusInCase,
        transferredDate,
        type,
    } = data;
    return (
        <Box display={breakpointXs ? 'block' : 'flex'}>
            <BoxCustom mr={2}>
                <h3>Podstawowe metadane dokumentu</h3>
                <LabeledElement label="Identyfikator" text my={1}>
                    {identifier}
                </LabeledElement>
                <LabeledElement label="Rodzaj dokumentu" text mb={1}>
                    {type}
                </LabeledElement>
                <LabeledElement label="Data sporządzenia" text mb={1}>
                    {composedDate || EMPTY_VALUE}
                </LabeledElement>
                <LabeledElement label="Chwila wpływu do Sądu" text mb={1}>
                    {arrivedDate || EMPTY_VALUE}
                </LabeledElement>
                <Box
                    display={breakpointXs ? 'block' : 'flex'}
                    flexWrap="wrap"
                >
                    <LabeledElement label="Język" text mb={1} width='50%'>
                        {language}
                    </LabeledElement>
                    <LabeledElement label="Format pliku" text mb={1}>
                        {formatFileFormat(format)}
                    </LabeledElement>
                </Box>
                <Box
                    display={breakpointXs ? 'block' : 'flex'}
                    flexWrap="wrap"
                >
                    <LabeledElement label="Rozmiar pliku" text mb={1} width='50%'>
                        {formatFileSize(fileSize)}
                    </LabeledElement>
                    <LabeledElement label="Czy dokument wpłynął jako elektroniczny?" text>
                        {digital ? 'TAK' : 'NIE'}
                    </LabeledElement>
                </Box>
            </BoxCustom>
            <BoxCustom item>
                <h3>Metadane dokumentu w sprawie</h3>
                <LabeledElement label="Sygnatura sprawy" text my={1}>
                    {caseReference}
                </LabeledElement>
                <LabeledElement label="Numer dokumentu w sprawie" text mb={1}>
                    {numberInCase || EMPTY_VALUE}
                </LabeledElement>
                <LabeledElement label="Status w sprawie" text mb={1}>
                    {statusInCase.value || statusInCase || EMPTY_VALUE}
                </LabeledElement>
                <LabeledElement label="Data ujawnienia w sprawie" text mb={1}>
                    {disclosedDate || EMPTY_VALUE}
                </LabeledElement>
                <LabeledElement label="Data przeniesienia / wyłączenia" text mb={1}>
                    {transferredDate || EMPTY_VALUE}
                </LabeledElement>
                <LabeledElement label="Poziom dostępu" text>
                    {accessLevel || EMPTY_VALUE}
                </LabeledElement>
            </BoxCustom>
        </Box>
    )
}
