import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserContext } from '../context/userContext';
import { URLS } from '../urls/frontend';
import { NotFoundPage } from './atoms';
import {
    Case,
    CaseOrDocumentDetails,
    Document,
    Documents,
    Help,
    Home,
    KRSSubject,
    Search
} from './views';


export const Routes = (props) => {
    const { user } = useContext(UserContext);
    const { isInternal } = user;

    const getInternalUserComponent = (component) => {
        if (isInternal) {
            return component
        }
        return <NotFoundPage />
    }

    const getAuthenticatedUserComponent = (component) => {
        if (user.isAuthenticated) {
            return component
        }
        return <NotFoundPage />
    }

    return (
        <Switch>
            <Route exact path={URLS.home.path}>
                <Home />
            </Route>
            <Route exact path={URLS.caseDetails.path}>
                <CaseOrDocumentDetails />
            </Route>
            <Route exact path={URLS.documentInCaseDetails.path}>
                <CaseOrDocumentDetails />
            </Route>

            {/* RAR urls */}
            <Route exact path={URLS.rarSearch.path}>
                <Search repoName="rar" />
            </Route>
            <Route exact path={URLS.rarKRSSubject.path}>
                <KRSSubject repoName="rar" />
            </Route>
            <Route exact path={URLS.rarCase.path}>
                <Case repoName="rar" />
            </Route>
            <Route exact path={URLS.rarDocumentsByKrsNumber.path}>
                <Documents mode="krsSubject" repoName="rar" />
            </Route>
            <Route exact path={URLS.rarDocumentsByCaseReference.path}>
                <Documents mode="caseReference" repoName="rar" />
            </Route>
            <Route exact path={URLS.rarDocumentInCase.path}>
                <Document inCase={true} repoName="rar" />
            </Route>
            <Route exact path={URLS.rarDocumentInCaseWithMetric.path}>
                <Document inCase={true} repoName="rar" />
            </Route>
            <Route exact path={URLS.rarDocument.path}>
                <Document repoName="rar" />
            </Route>

            {/* RAS urls */}
            <Route exact path={URLS.rasSearch.path}>
                <Search repoName="ras" />
            </Route>
            <Route exact path={URLS.rasCase.path}>
                <Case repoName="ras" />
            </Route>
            <Route exact path={URLS.rasDocumentsByCaseReference.path}>
                <Documents mode="caseReference" repoName="ras" />
            </Route>
            <Route exact path={URLS.rasDocumentInCase.path}>
                <Document inCase={true} repoName="ras" />
            </Route>
            <Route exact path={URLS.rasDocumentInCaseWithMetric.path}>
                <Document inCase={true} repoName="ras" />
            </Route>
            <Route exact path={URLS.rasDocument.path}>
                <Document repoName="ras" />
            </Route>

            {/* RP urls */}
            <Route exact path={URLS.rpDocument.path}>
                {getInternalUserComponent(<Document repoName="rp" />)}
            </Route>

            {/* RWiP urls */}
            <Route exact path={URLS.rwipSearch.path}>
                {getInternalUserComponent(<Search repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipKRSSubject.path}>
                {getAuthenticatedUserComponent(<KRSSubject repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipCase.path}>
                {getAuthenticatedUserComponent(<Case repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipDocumentsByKrsNumber.path}>
                {getInternalUserComponent(
                    <Documents mode="krsSubject" repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipDocumentsByCaseReference.path}>
                {getInternalUserComponent(
                    <Documents mode="caseReference" repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipDocumentInCase.path}>
                {getAuthenticatedUserComponent(
                    <Document inCase={true} repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipDocumentInCaseWithMetric.path}>
                {getAuthenticatedUserComponent(
                    <Document inCase={true} repoName="rwip" />)}
            </Route>
            <Route exact path={URLS.rwipDocument.path}>
                {getAuthenticatedUserComponent(<Document repoName="rwip" />)}
            </Route>

            {/* ALL */}
            <Route exact path={URLS.allKRSSubject.path}>
                {getInternalUserComponent(<KRSSubject repoName="all" />)}
            </Route>

            {/* others */}
            <Route exact path="/blad-uprawnien-do-pliku">
                <h3>Nie posiadasz uprawnień do pobrania lub przeglądania treści dokumentu.</h3>
            </Route>
            <Route exact path="/blad-pobierania-pliku">
                <h3>Podczas pobierania pliku wystapił nieoczekiwany błąd.</h3>
            </Route>
            <Route exact path={URLS.help.path}>
                <Help />
            </Route>
            <Route exact path="*">
                <NotFoundPage />
            </Route>
        </Switch>
    )
};
