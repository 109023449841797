import {
    DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH,
    KRS_SUBJECT_INPUT_MAX_LENGTH,
    KRS_SUBJECT_PLACEHOLDER
} from '../../../consts';
import {
    getBlurredKrsNumberValue,
    getPastedKrsNumberValue
} from '../../../helpers/helpers';
import { InputCustom } from '../../atoms';
import { DatesFields } from '../DatesFields';
import { DigitalSelectField } from './DigitalSelectField';
import { SelectField } from './SelectField';
import '../molecules-global.sass';

const DATE_FILTERS_IDS = ['arrivedDate', 'composedDate'];


export const SearchByKrsNumberFields = ({
    dictionariesData,
    dictionariesErrorOn,
    errors,
    fields,
    formDisabled,
    onChangeFilter
}) => {

    function handleBlurKrsSubject(ev) {
        const value = getBlurredKrsNumberValue(ev.target.value);
        if (value.length) {
            onChangeFilter('krsNumber', value, true);
        }
    }

    function handlePasteKrsSubject(ev) {
        const value = getPastedKrsNumberValue(ev);
        if (value.length) {
            onChangeFilter('krsNumber', value);
        }
    }

    return (
        <>
            <section className="form-section">
                <InputCustom
                    disabled={formDisabled}
                    errorOrHelperText={errors.krsNumber || 'W polu możesz wpisać tylko cyfry'}
                    id="id_krsNumber"
                    isInvalid={!!errors.krsNumber}
                    label="Numer KRS"
                    maxLength={KRS_SUBJECT_INPUT_MAX_LENGTH}
                    placeholder={KRS_SUBJECT_PLACEHOLDER}
                    required
                    showHelperText
                    value={fields.krsNumber}
                    onBlur={handleBlurKrsSubject}
                    onChange={ev => onChangeFilter(
                        'krsNumber', ev.target.value, true
                    )}
                    onPaste={handlePasteKrsSubject}
                />
            </section>
            <section className="form-section form-section--multiple-fields">
                <SelectField
                    dictData={dictionariesData['documentKind'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.type}
                    errorMessage="Nie udało się pobrać rodzajów dokumentów."
                    fieldName="type"
                    id="id_type"
                    label="Rodzaj dokmentu"
                    value={fields.type}
                    onChangeFilter={onChangeFilter}
                />
                <InputCustom
                    disabled={formDisabled}
                    errorOrHelperText={errors.identifier}
                    id="id_identifier"
                    isInvalid={!!errors.identifier}
                    label="Identyfikator dokumentu"
                    maxLength={DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH}
                    required
                    value={fields.identifier}
                    onChange={ev => onChangeFilter('identifier', ev.target.value)}
                />
                <DigitalSelectField
                    disabled={formDisabled}
                    error={errors.digital}
                    value={fields.digital}
                    onChangeFilter={onChangeFilter}
                />

                <SelectField
                    dictData={dictionariesData['documentAccessLevel'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.accessLevel}
                    errorMessage="Nie udało się pobrać poziomów dostępności."
                    fieldName="accessLevel"
                    id="id_accessLevel"
                    label="Poziom dostępu"
                    value={fields.accessLevel}
                    onChangeFilter={onChangeFilter}
                />
                <SelectField
                    dictData={dictionariesData['documentLanguage'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.language}
                    errorMessage="Nie udało się pobrać języków."
                    fieldName="language"
                    id="id_language"
                    label="Język dokumentu"
                    value={fields.language}
                    onChangeFilter={onChangeFilter}
                />
            </section>
            <section className="form-section">
                <DatesFields
                    disabled={formDisabled}
                    errors={errors}
                    fields={fields}
                    filtersIds={DATE_FILTERS_IDS}
                    onChangeFilter={onChangeFilter}
                />
            </section>
        </>
    )
};
