import { useContext, useEffect } from 'react';

import { UserContext } from '../../context/userContext';
import { fetchApi } from '../../fetch';
import { DialogBase } from '../molecules';


export const LogoutDialog = ({
    sessionRefreshingFailed, unauthorizedAccess, onLogoutSuccess
}) => {
    const { logout } = useContext(UserContext);

    useEffect(() => {
        let xhrFetch = null;

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            logout(onLogoutSuccess);
        }

        function callbackFetchDataError() {
            xhrFetch = null;
            window.location.reload();
        }

        xhrFetch = fetchApi({
            url: '/logout',
            method: 'POST',
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataError,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <DialogBase
        dialogTitle={`${sessionRefreshingFailed 
            ? 'Nie udało się przedłużyć czasu sesji. ' 
            : unauthorizedAccess
                ? 'Wystąpił problem z uwierzytelnieniem użytkownika. ' 
                : ''
        }Trwa wylogowywanie...`}
        hideCloseButton={true}
        open />
};
