import { Component, createContext } from 'react';


const EMPTY_USER = {
    accountType: null,
    isAuthenticated: false,
    isExternalNamed: false,
    isExternalOneTime: false,
    isInternal: false,
    username: '',
};
const EMPTY_AUTH = { expire: null };


export const UserContext = createContext({
    initialized: false,
    user: {...EMPTY_USER},
    auth: {...EMPTY_AUTH},
    set: () => {},
    setInitialized: () => {},
    setExpire: () => {},
    refresh: () => {},
    logout: () => {},
});

export class UserProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            user: {...EMPTY_USER},
            auth: {...EMPTY_AUTH},
            set: this.set,
            setInitialized: this.setInitialized,
            setExpire: this.setExpire,
            refresh: this.refresh,
            logout: this.logout,
        };
    }

    set = (data, callback=null) => {
        // from backend, at the beginning of application life or after login
        const { user } = data;
        const { accountType } = user;
        const isInternal = accountType === 'INTERNAL';
        this.setState(
            prevState => ({
                user: {
                    ...prevState.user,
                    isAuthenticated: true,
                    isExternalNamed: accountType === 'EXTERNAL_NAMED',
                    isExternalOneTime: accountType === 'EXTERNAL_ONE_TIME',
                    isInternal: isInternal,
                    username: user.username,
                },
                initialized: true,
                auth: isInternal
                    ? {...EMPTY_AUTH}
                    : (data.auth || {...EMPTY_AUTH}),
            }),
            () => {
                if (!isInternal) {
                    // timer is not working for internal user
                    window.sessionComponent?.initializeTimer();
                }
                if (callback) {callback()}
            }
        );
    }

    setInitialized = (callback=null) => {
        this.setState(
            {initialized: true},
            () => {if (callback) {callback()}}
        );
    }

    setExpire = (value, callback=null) => {
        this.setState(
            prevState => ({auth: {...prevState.auth, expire: value}}),
            () => {if (callback) {callback()}}
        );
    }

    refresh = (data, callback=null) => {
        this.setState(
            {auth: data.auth || {...EMPTY_AUTH}},
            () => {
                window.sessionComponent?.initializeTimer();
                if (callback) {callback()}
            }
        );
    }

    logout = (callback=null) => {
        this.setState(
            {user: {...EMPTY_USER}, auth: {...EMPTY_AUTH}},
            () => {
                window.sessionComponent?.logout();
                if (callback) {callback()}
            }
        );
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export const UserConsumer = UserContext.Consumer;
