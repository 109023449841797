import {
  Box,
  InputLabel,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';

import './SelectCustom.sass';

export const SelectCustom = ({
  children,
  className,
  errorOrHelperText,
  id,
  isInvalid,
  label,
  m,
  mb,
  mr,
  name,
  onChange,
  options,
  value,
  width,
  ...props
}) => {
  const MenuProps = {
    PaperProps: {
      className: 'select-custom__menu'
    },
  };
  return (
    <Box m={m || 0} mr={mr} mb={mb || 2} width={width || 1}>
      <FormControl
        className={`select-custom ${className ? className : ''}`}
        error={isInvalid}
        {...props}
      >
        <InputLabel htmlFor={id} shrink>{label}</InputLabel>
        <Select
          aria-label="Wybierz opcję z menu rozwijalnego."
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          MenuProps={MenuProps}
        >
          <MenuItem key="0" value="0">Wybierz opcję</MenuItem>
          {children}
        </Select>
        <FormHelperText>{isInvalid && errorOrHelperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};