// Gives standard dialog elements as props: DialogTitle, DialogContent and DialogActions for confirm and cancel buttons.
// This dialog is controlled (opened, closed) by its parent

import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { ButtonCustom } from '../atoms';
import { DialogNaked } from '../molecules';

export const DialogBase = ({
  buttonForm,
  buttonType,
  children,
  contentClassName,
  dialogCancelBtnText,
  dialogConfirmBtnText,
  dialogTitle,
  disabled,
  formGlobalErrors,
  hideCloseButton,
  onDialogConfirm,
  onDialogClose,
  ...props
}) => {

  return (
    <>
      <DialogNaked {...props} >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent className={contentClassName}>
          {children}
        </DialogContent>
        <DialogActions className="form-buttons">
          {!hideCloseButton && (
            <ButtonCustom
              autoFocus={!dialogConfirmBtnText}
              color="default"
              variant="outlined"
              onClick={onDialogClose}
            >
              {dialogCancelBtnText || 'Zamknij'}
            </ButtonCustom>
          )}
          {dialogConfirmBtnText && (
            <ButtonCustom
              autoFocus
              disabled={disabled}
              form={buttonForm}
              onClick={onDialogConfirm}
              type={buttonType || 'button'}
            >
              {dialogConfirmBtnText}
            </ButtonCustom>
          )}
          {formGlobalErrors}
        </DialogActions>
      </DialogNaked>
    </>
  );
};
