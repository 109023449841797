import {
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';

export const IconButtonWithTooltip = ({
  children,
  color,
  edge,
  onClick,
  srOnlyText,
  ...props
}) => {

  return (
    <Tooltip title={<Typography variant="caption">{srOnlyText}</Typography>}>
      <IconButton
        color={color || 'primary'}
        onClick={onClick}
        edge={edge || 'start'}
        {...props}
      >
        <Typography variant="srOnly">{srOnlyText}</Typography>
        {children}
      </IconButton>
    </Tooltip>
  )
};
