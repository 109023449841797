import { useEffect, useState } from 'react';
import './CombainedSearchForm.sass';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, MenuItem } from '@material-ui/core';
import { ButtonCustom, InputCustom, SelectCustom, InfoAlert } from '../../atoms';

const ELEMENTS_IDS = {
    SEARCH_BUTTON_FORM: 'search-button-field',
    DEPARTMENT: 'department-field',
    KRS_NUMBER: 'krsNumber-field',
    FIRST_NUMBER_PART: 'firstNumberPart-field',
    SECOND_NUMBER_PART: 'secondNumberPart-field'
};

const FORM_CONFIGURATION = {
    department: { minLength: 1 },
    krsNumber: { minLength: 1, maxLength: 10 },
    firstPartNumber: { minLength: 1, maxLength: 2 },
    secondPartNumber: { minLength: 1, maxLength: 3 },
};

const initialFormErrorsState = {
    krsNumber: '',
    firstPartNumber: '',
    secondPartNumber: '',
    department: ''
};

const NS_REJ_KRS_LABEL = 'Ns-Rej.KRS';

export const CombainedSearchForm = ({
    redirectUrl,
    visibleButtons = true,
    visitorFunction = null,
    formId,
    externalClearState = false,
    externalChangeClearState,
    externalSearchState,
    dictData = [],
    dictionariesErrorOn,
    mandatory,
    label,
    textInformationStyles
}) => {
    const history = useHistory();

    const [formErrors, setFormErrors] = useState(initialFormErrorsState);

    const [department, setDepartment] = useState('0');
    const [krsNumber, setKrsNumber] = useState('');
    const [firstPartNumber, setFirstPartNumber] = useState('');
    const [secondPartNumber, setSecondPartNumber] = useState('');

    const [searchButtonClicked, setSearchButtonClicked] = useState(false);

    const isValidForm = Object.values(formErrors).every(val => val.length > 0);

    const notCorrectFilledAllInputs = Object.values(formErrors).filter(val => val.length > 0).length !== Object.values(formErrors).length

    useEffect(() => {
        if (isValidForm && visitorFunction) {
            const generatedURL = `${department} ${NS_REJ_KRS_LABEL} ${krsNumber}/${firstPartNumber}/${secondPartNumber}`;
            visitorFunction(generatedURL)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [department, krsNumber, firstPartNumber, secondPartNumber, isValidForm])

    useEffect(() => {
        if (externalClearState) {
            onClearHandler();
            externalChangeClearState(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalClearState])

    const onClearHandler = () => {
        setDepartment('0');
        setKrsNumber('');
        setFirstPartNumber('');
        setSecondPartNumber('');
        setFormErrors(initialFormErrorsState)
    };

    const handleChange = (ev, callback, navigateId, maxLength) => {
        let value_ = ev.target.value;
        value_ = value_.replace(/[^\d]/, '');
        callback(value_);
        if (value_.length === maxLength && navigateId) {
            navigateToElement(navigateId);
        }
    };

    const navigateToElement = (id) => setTimeout(() => document.getElementById(`${formId}-${id}`)?.focus(), 0);

    const handleErrorField = (ev, key, minLength) => {
        const value = ev.target.value;
        if (value.length >= minLength) {
            setFormErrors((prev) => ({ ...prev, [key]: 'Correct Field' }));
        } else {
            setFormErrors((prev) => ({ ...prev, [key]: '' }));
        }
    }

    const handleSearch = () => {
        setSearchButtonClicked(true);
        if (isValidForm) {
            setFormErrors(initialFormErrorsState);
            const generatedURL = `${department} ${NS_REJ_KRS_LABEL} ${krsNumber}/${firstPartNumber}/${secondPartNumber}`;
            history.push(redirectUrl.getUrl(generatedURL));
            return;
        }
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        handleSearch();
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className="combained-search-form"
                style={{
                    paddingBottom: visibleButtons ? '5rem' : undefined
                }}
            >
                <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} className="combained-search-form-grid">
                    <Grid item xs={12} sm={9} md={2} className="combained-search-form-grid-select-wrapper">
                        <SelectCustom
                            disabled={dictionariesErrorOn}
                            errorOrHelperText={dictionariesErrorOn && 'Nie udało się pobrać listy departamentów.'}
                            label={label}
                            value={department}
                            isInvalid={mandatory ? dictionariesErrorOn || (formErrors.department.length === 0 && (searchButtonClicked || externalSearchState)) : false}
                            onChange={ev => {
                                setDepartment(ev.target.value)
                                const selectedValue = ev.target.value === '0' ? '' : ev.target.value
                                handleErrorField({ target: { value: selectedValue } }, 'department', FORM_CONFIGURATION.department.minLength)
                                navigateToElement(ELEMENTS_IDS.KRS_NUMBER)
                            }}
                        >
                            {dictData.map(choice => (
                                <MenuItem
                                    key={`${formId} ${choice.value}`}
                                    value={choice.shortName}
                                >
                                    {choice.shortName}
                                </MenuItem>
                            ))}
                        </SelectCustom>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} className="combained-search-form-grid-krsnumber-wrapper">
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={0} sm={0} md={6} lg={5} className="combained-search-form-grid-krsnumber-wrapper-child">
                                <span className="combained-search-form-grid-krsnumber-wrapper-child-label">{NS_REJ_KRS_LABEL}</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={7} className="combained-search-form-grid-krsnumber-wrapper-child-input">
                                <InputCustom
                                    id={`${formId}-${ELEMENTS_IDS.KRS_NUMBER}`}
                                    label={''}
                                    disabled={dictionariesErrorOn}
                                    type={'text'}
                                    value={krsNumber}
                                    isInvalid={mandatory ? dictionariesErrorOn || (formErrors.krsNumber.length === 0 && (searchButtonClicked || externalSearchState)) : false}
                                    maxLength={FORM_CONFIGURATION.krsNumber.maxLength}
                                    placeholder={''}
                                    required
                                    onChange={(e) => {
                                        handleChange(
                                            e,
                                            setKrsNumber,
                                            ELEMENTS_IDS.FIRST_NUMBER_PART,
                                            FORM_CONFIGURATION.krsNumber.maxLength
                                        )
                                        handleErrorField(e, 'krsNumber', FORM_CONFIGURATION.krsNumber.minLength)
                                    }}
                                    onPaste={(e) => {
                                        handleChange(
                                            e,
                                            setKrsNumber,
                                            ELEMENTS_IDS.FIRST_NUMBER_PART,
                                            FORM_CONFIGURATION.krsNumber.maxLength
                                        )
                                        handleErrorField(e, 'krsNumber', FORM_CONFIGURATION.krsNumber.minLength)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sm={2} md={1} className="combained-search-form-grid-slash-part-wrapper">
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={0} sm={0} md={2} className="form-input-slash">
                                <span>/</span>
                            </Grid>
                            <Grid item xs={11} sm={11} md={9}>
                                <InputCustom
                                    id={`${formId}-${ELEMENTS_IDS.FIRST_NUMBER_PART}`}
                                    label={''}
                                    type={'text'}
                                    disabled={dictionariesErrorOn}
                                    value={firstPartNumber}
                                    maxLength={FORM_CONFIGURATION.firstPartNumber.maxLength}
                                    isInvalid={mandatory ? dictionariesErrorOn || (formErrors.firstPartNumber.length === 0 && (searchButtonClicked || externalSearchState)) : false}
                                    placeholder={''}
                                    required
                                    onChange={(e) => {
                                        handleChange(
                                            e,
                                            setFirstPartNumber,
                                            ELEMENTS_IDS.SECOND_NUMBER_PART,
                                            FORM_CONFIGURATION.firstPartNumber.maxLength
                                        )
                                        handleErrorField(e, 'firstPartNumber', FORM_CONFIGURATION.firstPartNumber.minLength)
                                    }}
                                    onPaste={(e) => {
                                        handleChange(
                                            e,
                                            setFirstPartNumber,
                                            ELEMENTS_IDS.SECOND_NUMBER_PART,
                                            FORM_CONFIGURATION.firstPartNumber.maxLength
                                        )
                                        handleErrorField(e, 'firstPartNumber', FORM_CONFIGURATION.firstPartNumber.minLength)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sm={2} md={1} className="combained-search-form-grid-slash-part-wrapper">
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={0} md={2} className="form-input-slash">
                                <span>/</span>
                            </Grid>
                            <Grid item xs={11} sm={11} md={9}>
                                <InputCustom
                                    id={`${formId}-${ELEMENTS_IDS.SECOND_NUMBER_PART}`}
                                    label={''}
                                    type={'text'}
                                    disabled={dictionariesErrorOn}
                                    value={secondPartNumber}
                                    isInvalid={mandatory ? dictionariesErrorOn || (formErrors.secondPartNumber.length === 0 && (searchButtonClicked || externalSearchState)) : false}
                                    maxLength={FORM_CONFIGURATION.secondPartNumber.maxLength}
                                    placeholder={''}
                                    required
                                    onChange={(e) => {
                                        handleChange(
                                            e,
                                            setSecondPartNumber,
                                            ELEMENTS_IDS.SEARCH_BUTTON_FORM,
                                            FORM_CONFIGURATION.secondPartNumber.maxLength
                                        )
                                        handleErrorField(e, 'secondPartNumber', FORM_CONFIGURATION.secondPartNumber.minLength)
                                    }}
                                    onPaste={(e) => {
                                        handleChange(
                                            e,
                                            setSecondPartNumber,
                                            ELEMENTS_IDS.SEARCH_BUTTON_FORM,
                                            FORM_CONFIGURATION.secondPartNumber.maxLength
                                        )
                                        handleErrorField(e, 'secondPartNumber', FORM_CONFIGURATION.secondPartNumber.minLength)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {visibleButtons && <Grid item xs={12} md={3} className="form-button-container">
                        <ButtonCustom
                            onClick={onClearHandler}
                            className="form-button"
                            mr={2}
                            variant="outlined"
                        >
                            Wyczyść
                        </ButtonCustom>
                        <ButtonCustom
                            onClick={handleSearch}
                            className="form-button"
                            id={`${formId}-${ELEMENTS_IDS.SEARCH_BUTTON_FORM}`}
                        >
                            Wyszukaj
                        </ButtonCustom>
                    </Grid>}
                </Grid>
                <Grid container spacing={2} alignItems={'center'} className="form-info isDesktop">
                    <div style={{ paddingLeft: textInformationStyles }} class="form-info-text">
                        <Typography component="span" color="textSecondary">
                            Wybierz wydział sądu oraz wprowadź numeryczną sygnaturę sprawy w powyższych polach
                        </Typography>
                    </div>
                </Grid>
                <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} className="form-info isMobile">
                    <div class="form-info-text">
                        <Typography component="span" color="textSecondary">
                            Wybierz wydział sądu oraz wprowadź numeryczną sygnaturę sprawy w powyższych polach
                        </Typography>
                    </div>
                </Grid>
                {(searchButtonClicked || externalSearchState) && notCorrectFilledAllInputs && mandatory && <Grid container alignItems={'center'} justifyContent={'center'} className="info-alert-container">
                    <InfoAlert>Należy wprowadzić pełną sygnaturę sprawy.</InfoAlert>
                </Grid>}
            </form>
            {!isValidForm && <Typography role="alert" variant="srOnly">Formularz zawiera błędy!</Typography> }
        </>
    )
};
