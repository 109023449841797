import { FiltersForm } from '../../organisms';
import { DocumentsFiltersFields } from '../DocumentsFiltersFields';


export const DocumentsFiltersForm = ({
    defaultFilters,
    disabled,
    errors,
    initialFilters={},
    onFilter,
    ...props
}) => {

    return (
        <FiltersForm
            dateNamesList={[
                'arrivedDateFrom', 'arrivedDateTo',
                'composedDateFrom', 'composedDateTo',
                'disclosedDateFrom', 'disclosedDateTo',
                'transferredDateFrom', 'transferredDateTo'
            ]}
            defaultFilters={defaultFilters}
            disabled={disabled}
            errors={errors}
            FiltersFieldsComponent={DocumentsFiltersFields}
            initialFilters={initialFilters}
            selectDictNamesList={[
                'documentKind', 'documentAccessLevel', 'documentLanguage'
            ]}
            selectDictNameSelectNameList={[
                ['documentKind', 'type'],
                ['documentAccessLevel', 'accessLevel'],
                ['documentLanguage', 'language']
            ]}
            textNamesList={[
                'identifier', 'numberInCase', 'caseReferenceFrom',
                'caseReferenceTo'
            ]}
            onFilter={onFilter}
        />
    )
};
