import { useEffect, useState } from 'react';

import { REQUEST_ERROR, WITH_MOCK } from '../../consts';
import { fetchApi } from '../../fetch';
import { DATA } from '../../mock/CaseFiles';
import { CaseFilesTableRow, TableInDialog } from '../molecules';
import './organisms-global.sass';

export const CaseFiles = ({
    casePageCaseReference = null,
    caseReference,
    repoData,
    onChangeCaseReference,
    onCloseMovedFiles
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [fetchError, setFetchError] = useState(null);

    useEffect(() => {
        let xhrFetch = null;
        setFetchError(null);
        setIsLoading(true);

        function callbackFetchDataSuccess(data_) {
            xhrFetch = null;
            const mappedData = (WITH_MOCK ? (DATA[caseReference] || {}) : (data_ || {}))
            setData({...mappedData, cases: (mappedData.cases || []).map((item) => ({...item, case: {...item.case, caseReference: item.case.krsNumber ? null : item.case.caseReference}}))});
            setIsLoading(false);
        }

        function callbackFetchDataError(message='') {
            xhrFetch = null;
            if (WITH_MOCK) {
                setData(DATA[caseReference]);
            } else {
                setFetchError(REQUEST_ERROR);
            }
            setIsLoading(false);
        }

        function callbackFetchDataIncorrectStatus(statusCode) {
            callbackFetchDataError();
        }

        xhrFetch = fetchApi({
            url: '/casefiles',
            body: {caseReference: encodeURIComponent(caseReference)},
            encodeParams: false,
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: () => callbackFetchDataError(),
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }

    }, [caseReference]);

    return (
        <TableInDialog
            className="case-files-dialog"
            emptyMessage='Brak danych'
            errorMessage={fetchError}
            isLoading={isLoading}
            itemKeyAttributeName="caseReference"
            items={data.cases || []}
            name={`Dołączone akta/sprawy (sygnatura sprawy: ${caseReference})`}
            repoData={repoData}
            tableHeaderList={[
                'NR KRS',
                'Sygnatura sprawy',
                'Więcej o sprawie',
                'Więcej o podmiocie'
            ]}
            TableRowComponent={CaseFilesTableRow}
            tableRowComponentProps={{
                casePageCaseReference,
                onChangeCaseReference,
            }}
            onClose={onCloseMovedFiles}
        />
    )
};
