import { EMPTY_VALUE } from '../../../consts';
import { ButtonCustom } from '../../atoms';
import { LabeledElement } from '../../molecules';


export const CasesForDocumentDocumentInCaseMetaData = ({data, onHide}) => {
    const {
        caseReference,
        documentData,
    } = data;

    const {
        accessLevel,
        caseReferenceFrom,
        caseReferenceTo,
        disclosedDate,
        numberInCase,
        statusInCase,
        transferredDate,
    } = documentData;

    return (
        <>
            <ButtonCustom onClick={onHide} my={2} >
                Ukryj
            </ButtonCustom>

            <h2>Metadane dokumentu w sprawie</h2>
            <LabeledElement label="Sygnatura sprawy" text my={1}>
                {caseReference}
            </LabeledElement>
            <LabeledElement label="Numer dokumentu w sprawie" text mb={1}>
                {numberInCase || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Status w sprawie" text mb={1}>
                {statusInCase || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Data ujawniania w sprawie" text mb={1}>
                {disclosedDate || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Data przeniesienia / wyłączenia" text mb={1}>
                {transferredDate || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Poziom dostępu" text mb={1}>
                {(accessLevel || {}).value || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Sygnatura sprawy, do której został przeniesiony" text mb={1}>
                {caseReferenceTo || EMPTY_VALUE}
            </LabeledElement>
            <LabeledElement label="Sygnatura sprawy, z której został przeniesiony" text mb={1}>
                {caseReferenceFrom || EMPTY_VALUE}
            </LabeledElement>
        </>
    )
};
