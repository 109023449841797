import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { EMPTY_VALUE, REPO_TYPES, WITH_MOCK } from '../../consts';
import { useGetSingleObjectData, formatDate } from '../../hooks';
import { URLS } from '../../urls/frontend';
import { ButtonCustom, Loader } from '../atoms';
import {
    ApplicantTableRow,
    KRSSubjectDataWidget,
    MetadataBox,
    MetadataBoxItem,
    RelatedCaseTableRow,
    TableInDialog,
} from '../molecules';
import { BreadcrumbsWithPageTitle, CaseFiles } from '../organisms';
import { Documents } from '../molecules/casePage';
import { LoginForm } from '../organisms/LoginForm';
import refreshSession from '../../helpers/refreshSession'
import { getErrorPerDefinition } from '../../helpers/helpers'

const CASE_NOT_EXISTS_INFO = 'Sprawa o podanej sygnaturze nie występuje w tym repozytorium.';
const CASE_NOT_EXISTS_IN_RAR_INFO = 'Sprawa nie występuje w RAR.';
const CASE_AUTH_USER_INFO = 'Dostęp do sprawy wymaga zalogowania.';

export const Case = ({repoName}) => {
    const repoData = useMemo(() => REPO_TYPES[repoName], [repoName]);

    let { caseReference } = useParams();
    try {
        caseReference = atob(caseReference);
    } catch (error) {
        caseReference = null;
    }

    const [applicantsOpen, setApplicantsOpen] = useState(false);
    const [relatedCasesOpen, setRelatedCasesOpen] = useState(false);
    const [convertedErrorObject, setConvertedErrorObject] = useState({});
    const [loginWindowOn, setLoginWindowOn] = useState(false);
    const [caseFilesCaseReference, setCaseFilesCaseReference] = useState(null);

    const { isLoading, data, fetchError, technicalError } = useGetSingleObjectData(
        caseReference,
        '/case',
        {
            caseReference: encodeURIComponent(caseReference),
            repoType: repoData.fetchParamName
        },
        CASE_NOT_EXISTS_INFO,
        false   // it's already encoded
    );

    useEffect(() => {
        if (technicalError && technicalError.length > 0) {
            try {
                const parsedError = JSON.parse(technicalError);
                setConvertedErrorObject(parsedError ?? {});
            } catch (_) {
                setConvertedErrorObject({});
            }
        }
    }, [technicalError]);

    const isInvalidFieldType = getErrorPerDefinition(
        convertedErrorObject,
        'INVALID',
        'repoType',
        'fieldFormErrors',
    );

    const shouldAuthenticateUser = getErrorPerDefinition(
        convertedErrorObject,
        'INVALID',
        'userContext',
        'fieldFormErrors',
    );

    useEffect(() => {
        const refreshSessionDefinition = refreshSession();
        if (shouldAuthenticateUser) {
            refreshSessionDefinition.setState();
            setLoginWindowOn(true);
        }
        return () => {
            refreshSessionDefinition.removeState();
        }
    }, [shouldAuthenticateUser]);

    useEffect(() => {
        // close dialogs
        setApplicantsOpen(false);
        setRelatedCasesOpen(false);
        setCaseFilesCaseReference(null);
    }, [caseReference]);

    function handleOpenApplicants() {
        if (relatedCasesOpen) {
            setRelatedCasesOpen(false);
        }
        if (caseFilesCaseReference !== null) {
            setCaseFilesCaseReference(null);
        }
        setApplicantsOpen(true);
    }

    function handleOpenRelatedCases() {
        if (applicantsOpen) {
            setApplicantsOpen(false);
        }
        if (caseFilesCaseReference !== null) {
            setCaseFilesCaseReference(null);
        }
        setRelatedCasesOpen(true);
    }

    function handleOpenCaseFiles(caseReference_) {
        if (relatedCasesOpen) {
            setRelatedCasesOpen(false);
        }
        if (applicantsOpen) {
            setApplicantsOpen(false);
        }
        setCaseFilesCaseReference(caseReference_);
    }

    function handleCloseLoginForm() {
        setLoginWindowOn(false);
    }

    const {
        applicants = [],
        case: case_ = {},
        krsSubject = {},
        relatedCases = [],
    } = data;

    return (
        <>
            <BreadcrumbsWithPageTitle
                breadcrumbs={[
                    [URLS.home.path, 'Repozytoria Akt'],
                    [repoData.frontendUrl, repoData.breadcrumbLinkLabel],
                ]}
                currentLocation="Przegląd Danych Sprawy"
                subtitle={caseReference}
                title="Sygnatura Sprawy:"
            />
            {loginWindowOn && (
                <LoginForm onDialogClose={handleCloseLoginForm} />
            )}
            {isLoading ? <Loader /> : !!fetchError
                ? <p className="error" role="alert">{isInvalidFieldType ? CASE_NOT_EXISTS_INFO : shouldAuthenticateUser ? CASE_AUTH_USER_INFO : CASE_NOT_EXISTS_IN_RAR_INFO}</p>
                : !caseReference
                    ? <p className="error">{isInvalidFieldType ? CASE_NOT_EXISTS_INFO : shouldAuthenticateUser ? CASE_AUTH_USER_INFO : CASE_NOT_EXISTS_IN_RAR_INFO}</p>
                    : (
                        <>
                            <Grid container spacing={1}>
                                <MetadataBox md>
                                    <MetadataBoxItem
                                        label="Sposób wszczęcia"
                                        text
                                        sm={12}
                                    >
                                        {case_.applicationType || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Stan sprawy"
                                        text
                                        sm={4}
                                    >
                                        {case_.status || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Rodzaj sprawy"
                                        text
                                        sm={4}
                                    >
                                        {case_.kind || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Sposób zakończenia"
                                        text
                                        sm={4}
                                    >
                                        {case_.endType || EMPTY_VALUE}
                                    </MetadataBoxItem>
                                </MetadataBox>
                                <MetadataBox md={2}>
                                    <MetadataBoxItem
                                        label="Data wszczęcia"
                                        text
                                        sm={12}
                                    >
                                        {formatDate(case_.startDate)}
                                    </MetadataBoxItem>
                                    <MetadataBoxItem
                                        label="Data zakreślenia"
                                        text
                                        sm={12}
                                    >
                                        {formatDate(case_.endDate)}
                                    </MetadataBoxItem>
                                </MetadataBox>
                                <MetadataBox md={2}>
                                    <MetadataBoxItem sm={12}>
                                        <ButtonCustom
                                            disabled={!applicants.length || isLoading}
                                            fullWidth
                                            onClick={handleOpenApplicants}
                                            variant="outlined"
                                        >
                                            Wnioskodawcy {applicants.length}
                                        </ButtonCustom>
                                    </MetadataBoxItem>
                                    <MetadataBoxItem sm={12}>
                                        <ButtonCustom
                                            disabled={!relatedCases.length || isLoading}
                                            fullWidth
                                            onClick={handleOpenRelatedCases}
                                            variant="outlined"
                                        >
                                            Sprawy powiązane {relatedCases.length}
                                        </ButtonCustom>
                                    </MetadataBoxItem>
                                    {(WITH_MOCK ? true : case_.hasMovedFiles) && (
                                        <MetadataBoxItem sm={12}>
                                            <ButtonCustom
                                                fullWidth
                                                onClick={() => handleOpenCaseFiles(caseReference)}
                                                variant="outlined"
                                            >
                                                Dołączone akta/sprawy
                                            </ButtonCustom>
                                        </MetadataBoxItem>
                                    )}
                                    {applicantsOpen && (
                                        <TableInDialog
                                            emptyMessage="Brak wnioskodawców"
                                            items={applicants || []}
                                            name="Wnioskodawcy"
                                            tableHeaderList={['Nazwa']}
                                            TableRowComponent={ApplicantTableRow}
                                            onClose={() => setApplicantsOpen(false)}
                                        />
                                    )}
                                    {relatedCasesOpen && (
                                        <TableInDialog
                                            emptyMessage="Brak powiązanych spraw"
                                            itemKeyAttributeName="caseReference"
                                            items={relatedCases || []}
                                            name="Powiązane sprawy"
                                            repoData={repoData}
                                            tableHeaderList={[
                                                'Sygnatura',
                                                'Data utworzenia powiązania',
                                                'Powód powiązania',
                                                'Więcej o powiązanych sprawach'
                                            ]}
                                            TableRowComponent={RelatedCaseTableRow}
                                            onClose={() => setRelatedCasesOpen(false)}
                                        />
                                    )}
                                    {caseFilesCaseReference !== null && (
                                        <CaseFiles
                                            caseReference={caseFilesCaseReference}
                                            casePageCaseReference={caseReference}
                                            repoData={repoData}
                                            onChangeCaseReference={handleOpenCaseFiles}
                                            onCloseMovedFiles={() => handleOpenCaseFiles(null)}
                                        />
                                    )}
                                </MetadataBox>
                                {[REPO_TYPES.rar.name, REPO_TYPES.rwip.name].includes(repoName) && !!krsSubject &&
                                    <KRSSubjectDataWidget
                                        data={krsSubject}
                                        krsSubjectUrlName={repoData.urlsNames.KRSSubject}
                                    />
                                }
                            </Grid>
                            <Documents
                                caseReference={caseReference}
                                repoData={repoData}
                            />
                        </>
                    )
            }
        </>
    )
};
