import { DialogBase } from './DialogBase';
import { Loader } from '../atoms';
import { TableCustom } from './TableCustom/TableCustom';


export const TableInDialog = ({
    className,
    emptyMessage,
    errorMessage,
    isLoading,
    itemKeyAttributeName,
    items,
    name,
    repoData,
    tableHeaderList,
    TableRowComponent,
    tableRowComponentProps = {},
    onClose
}) => {
    return (
        <DialogBase dialogTitle={name} open onDialogClose={onClose} contentClassName={className}>
            {errorMessage
                ? <p className="error" role="alert">{errorMessage}</p>
                : items.length === 0
                    ? (isLoading ? <Loader /> : <p className="error">{emptyMessage}</p>)
                    : (
                        <TableCustom
                            tableHeaderList={tableHeaderList}
                        >
                            {items.map((item, i) => (
                                <TableRowComponent
                                    item={item}
                                    key={`${item[itemKeyAttributeName] || item}_${i}`}
                                    repoData={repoData}
                                    {...tableRowComponentProps}
                                />
                            ))}
                        </TableCustom>
                    )
            }
        </DialogBase>
    )
};
