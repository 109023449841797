import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { REQUEST_ERROR } from '../../../consts';
import { fetchApi } from '../../../fetch';
import { formatFormErrors } from '../../../helpers/forms';
import {
    changePage,
    getFiltersByUrlParams,
    getUrlParamsString
} from '../../../helpers/urls';
import { BoxCustom, Loader } from '../../atoms';
import { Pagination, TableCustom } from '../../molecules';
import './TableWithFilters.sass';


export const TableWithFilters = ({
    additionalFetchParams={},
    defaultFilters = {},
    fetchUrl,
    FiltersComponent,
    filtersComponentParams = {},
    repoData,
    serializationUrlDictToPolish = {},
    tableEmptyResults,
    tableHeaderList,
    tableItemKeyField,
    tableItemsName,
    tableName,
    tableResultsToReturn = 20,
    TableRowComponent,
    tableRowComponentProps = {},
    validationAdditionalFunctionAttributes = [],
    validationFunction = (() => [true, {}]),
}) => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const pageNumber = parseInt(params.get('strona')) || 1;

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [fetchError, setFetchError] = useState(null);

    const [filtersInitialized, setFiltersInitialized] = useState(false);
    const [filtersErrors, setFiltersErrors] = useState({});

    const [initialFilters, setInitialFilters] = useState(null);
    useEffect(() => {
        setInitialFilters(getFiltersByUrlParams(
            params, defaultFilters, serializationUrlDictToPolish));
    }, [history.location.search]); // eslint-disable-line react-hooks/exhaustive-deps
    const [
        filtersFormInitialized, setFiltersFormInitialized
    ] = useState(false);

    function handleFilter(filters, callbackSuccess=null) {
        setIsLoading(true);
        // validate
        const [isValid, errors] = validationFunction(
            filters, ...validationAdditionalFunctionAttributes);
        if (!isValid) {
            setFiltersErrors(errors);
            setIsLoading(false);
            return
        }

        setIsLoading(false);
        if (callbackSuccess) {
            callbackSuccess();
        }

        // update url
        const urlWithParams = `${window.location.pathname}${getUrlParamsString({
            defaultData: defaultFilters,
            data: filters,
            serializationUrlDictToPolish,
            pageNumber: filtersInitialized ? 1 : pageNumber,  // set page to 1 always if filters change
        })}`;
        if (!filtersInitialized) {
            setFiltersInitialized(true);
        }

        if (!filtersFormInitialized) {
            history.replace(urlWithParams);
            setFiltersFormInitialized(true);
        } else if (
            window.location.pathname + history.location.search !==
            urlWithParams
        ) {
            history.push(urlWithParams);
        }
    }

    useEffect(() => {
        if (!filtersFormInitialized) { return }
        let xhrFetch = null;
        setFetchError(null);
        setFiltersErrors({});
        setIsLoading(true);

        function getFiltersData() {
            const params = new URLSearchParams(history.location.search);
            const filters = getFiltersByUrlParams(
                params, defaultFilters, serializationUrlDictToPolish);
            for (let filterName of Object.keys(filters)) {
                if (filters[filterName] === defaultFilters[filterName]) {
                    delete filters[filterName];
                    continue
                }
                filters[filterName] = encodeURIComponent(filters[filterName]);
            }
            return filters
        }

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            setData(data);
            setIsLoading(false);
        }

        function callbackFetchDataError(message='') {
            xhrFetch = null;
            setFetchError(REQUEST_ERROR);
            setIsLoading(false);
        }

        function callbackFetchDataIncorrectStatus(statusCode) {
            callbackFetchDataError();
        }

        function callbackFetchDataShowErrors(data) {
            xhrFetch = null;
            setFiltersErrors(
                formatFormErrors(data, Object.keys(defaultFilters)));
            setIsLoading(false);
        }

        xhrFetch = fetchApi({
            url: fetchUrl,
            body: {
                pageNumber,
                resultsToReturn: tableResultsToReturn,
                ...getFiltersData(),
                ...additionalFetchParams
            },
            encodeParams: false,    // they are already encoded in getFiltersData method
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: callbackFetchDataShowErrors,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [filtersFormInitialized, history.location.search, additionalFetchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChangePage(next=true) {
        changePage(history, params, pageNumber, next);
    }

    const items = data[tableItemsName] || [];
    return (
        <Box m={0} mt={3} className="tables-with-filters" position="relative">
            <BoxCustom pr={2} title={tableName} className="tables-with-filters__table">
                {isLoading
                    ? <Loader />
                    : !!fetchError
                        ? <p className="error" role="alert">{fetchError}</p>
                        : items.length > 0
                            ? (
                                <>
                                    <TableCustom tableHeaderList={tableHeaderList}>
                                        {items.map(item => (
                                            <TableRowComponent
                                                item={item}
                                                key={item[tableItemKeyField]}
                                                repoData={repoData}
                                                {...tableRowComponentProps}
                                            />
                                        ))}
                                    </TableCustom>
                                    <Pagination
                                        currentPageNumber={pageNumber}
                                        disabled={isLoading}
                                        perPage={tableResultsToReturn}
                                        total={(data.meta || {}).total || 0}
                                        onChangePage={handleChangePage} />
                                </>
                            )
                            : <p className="error" role="alert">{tableEmptyResults}</p>
                }
            </BoxCustom>
            {initialFilters !== null && (
                <>
                    <FiltersComponent
                        defaultFilters={defaultFilters}
                        disabled={isLoading}
                        errors={filtersErrors}
                        initialFilters={initialFilters}
                        repoData={repoData}
                        onFilter={handleFilter}
                        {...filtersComponentParams}
                    />
                </>
            )}
        </Box>
    )
};
