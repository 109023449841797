import { MenuItem } from '@material-ui/core';
import { formatChoice, formatChoiceKey } from '../../../helpers/forms';
import { SelectCustom } from '../../atoms';


export const SelectField = ({
    dictData,
    dictionariesErrorOn,
    disabled,
    error,
    errorMessage,
    fieldName,
    id,
    label,
    value,
    onChangeFilter
}) => {
    return (
        <SelectCustom
            disabled={dictionariesErrorOn || disabled}
            errorOrHelperText={
                (dictionariesErrorOn && errorMessage) ||
                (!!error && error)
            }
            id={id}
            isInvalid={dictionariesErrorOn || !!error}
            label={label}
            value={value}
            onChange={ev => onChangeFilter(fieldName, ev.target.value)}
        >
            {dictData.map(choice => (
                <MenuItem
                    key={formatChoiceKey(choice)}
                    value={formatChoice(choice)}
                >
                    {choice.value}
                </MenuItem>
            ))}
        </SelectCustom>
    )
};
