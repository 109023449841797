import { useState } from 'react';
import { useHistory } from 'react-router';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';

import {
    DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS,
    DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS,
    DOCUMENTS_SERIALIZATION_URL_LIST
} from '../../../consts';
import { getUrlParamsString } from '../../../helpers/urls';
import { useGetDictionariesValues } from '../../../hooks';
import { URLS } from '../../../urls/frontend';
import { validate } from '../../../validation/DocumentsFiltersForm';
import { ButtonCustom } from '../../atoms';
import { ClearFiltersButton } from '../../molecules';
import {
    SearchByCaseReferenceFields,
    SearchByKrsNumberFields
} from '../../molecules/searchPage';
import './DocumentsSearchForm.sass';


const SELECT_DICT_NAMES_LIST = [
    'department', 'documentKind', 'documentAccessLevel', 'documentLanguage'
];
const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of DOCUMENTS_SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}


export const DocumentsSearchForm = ({repoData}) => {
    const { name: repoName } = repoData;
    const [dictionariesData, setDictionariesData] = useState({});
    const [dictionariesErrorOn, setDictionariesErrorOn] = useState(false);
    const [radioValue, setRadioValue] = useState(
        repoName === 'ras' ? 'byCaseReference' : 'byKRSNumber'
    );
    const [fields, setFields] = useState({...(repoName === 'ras'
        ? DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS
        : DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS
    )});
    const [formDisabled, setFormDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const [clearState, setClearState] = useState(false)
    const [searchState, setSearchState] = useState(false)

    function handleChangeFilter(filterName, value, isNumber=false) {
        setFields(prevVal => ({
            ...prevVal,
            [filterName]: isNumber ? value.replace(/[^\d]/, '') : value,
        }));
    }

    function handleChangeRadioValue(ev) {
        const value = ev.target.value;
        setFields(value === 'byKRSNumber'
            ? {...DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS}
            : {...DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS}
        );
        setRadioValue(value);
    }

    function handleClean() {
        setFields(radioValue === 'byKRSNumber'
            ? {...DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS}
            : {...DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS}
        );
        setClearState(true)
    }

    useGetDictionariesValues(
        SELECT_DICT_NAMES_LIST, setDictionariesData, setDictionariesErrorOn
    );

    const history = useHistory();

    function handleSearch() {
        setSearchState(true)
        setErrors({});
        setFormDisabled(true);
        // validate
        const [isValid, errors] = validate(
            fields,
            radioValue === 'byKRSNumber',
            radioValue === 'byCaseReference'
        );
        if (!isValid) {
            setErrors(errors);
            setFormDisabled(false);
            return
        }
        history.push(
            URLS[repoData.urlsNames[
                radioValue === 'byKRSNumber'
                    ? 'documentsByKrsNumber': 'documentsByCaseReference'
                ]].path +
            getUrlParamsString({
                defaultData: radioValue === 'byKRSNumber'
                    ? {...DEFAULT_SEARCH_DOCUMENTS_BY_KRS_NUMBERS_FILTERS}
                    : {...DEFAULT_SEARCH_DOCUMENTS_BY_CASE_REFERENCE_FILTERS},
                data: fields,
                serializationUrlDictToPolish: SERIALIZATION_URL_DICT_TO_POLISH
            })
        );
    }

    return (
        <>
            {['rar', 'rwip'].includes(repoName) && (
                <FormControl component="fieldset" classes={{ root: 'radio-group-container' }}>
                    <FormLabel component="legend"><Typography variant="srOnly">Przeszukaj</Typography></FormLabel>
                    <RadioGroup
                        aria-label="Przeszukaj po"
                        disabled={formDisabled}
                        value={radioValue}
                        onChange={handleChangeRadioValue}
                        classes={{root: 'radio-group'}}
                    >
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label="W ramach danego Podmiotu KRS"
                            value="byKRSNumber" />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label="W ramach sprawy"
                            value="byCaseReference" />
                    </RadioGroup>
                </FormControl>
            )}
            <form className="search-form">
                <ClearFiltersButton
                    disabled={formDisabled}
                    filterType="wyszukiwania"
                    filterButtonName="Wyszukaj"
                    onClick={handleClean}
                    position="absolute"
                    top={['rar', 'rwip'].includes(repoName) ? (-10) : (15)}
                    right={5}
                    variant="outlined"
                />

                {radioValue === 'byKRSNumber'
                    ? (
                        <SearchByKrsNumberFields
                            dictionariesData={dictionariesData}
                            dictionariesErrorOn={dictionariesErrorOn}
                            errors={errors}
                            fields={fields}
                            formDisabled={formDisabled}
                            onChangeFilter={handleChangeFilter}
                        />
                    )
                    : (
                        <SearchByCaseReferenceFields
                            dictionariesData={dictionariesData}
                            dictionariesErrorOn={dictionariesErrorOn}
                            errors={errors}
                            fields={fields}
                            formDisabled={formDisabled}
                            onChangeFilter={handleChangeFilter}
                            changeClearState={(value) => setClearState(value)}
                            clearState={clearState}
                            searchState={searchState}
                        />
                    )
                }
                <ButtonCustom
                    btnContainerClassName="search-form__search-btn"
                    disabled={formDisabled}
                    fullWidth
                    onClick={handleSearch}
                >
                    Wyszukaj
                </ButtonCustom>
                {(errors.formErrors || []).map((error, i) => (
                    <p key={`${error}_${i}`} className="error" role="alert">{error}</p>
                ))}
            </form>
        </>
    )
};
