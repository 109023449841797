
export const DATA = {
    'TO.VII 1365/21/123': {
        cases: [
            {
                case: {
                    caseReference: 'TO.VII 1365/21/310',
                    kind: 'Zmiana',
                    status: 'string',
                    startDate: '2021-02-16',
                    endDate: '2021-02-17',
                    hasMovedFiles: false,
                    krsNumber: '0000256206',
                },
                repoType: 'RAS',
            },
            {
                case: {
                    caseReference: 'WA.XIII 34946/20/906',
                    kind: 'Wykreślenie',
                    status: 'string',
                    startDate: '2021-04-15',
                    endDate: '2021-04-17',
                    hasMovedFiles: true,
                    krsNumber: '0000256206',
                },
                repoType: 'RAR',
            },
        ],
        meta: {total: 2}
    },
    'TO.VII 1365/21/310': {
        cases: [
            {
                case: {
                    caseReference: 'TO.VII 1365/21/123',
                    kind: 'Zmiana',
                    status: 'string',
                    startDate: '2020-01-01',
                    endDate: '2020-02-02',
                    hasMovedFiles: true,
                    krsNumber: '0000256206',
                },
                repoType: 'RAR',
            },
            {
                case: {
                    caseReference: 'WA.XIII 34946/20/906',
                    kind: 'Wykreślenie',
                    status: 'string',
                    startDate: '2021-04-15',
                    endDate: '2021-04-17',
                    hasMovedFiles: true,
                    krsNumber: '0000256206',
                },
                repoType: 'RAR',
            },
        ],
        meta: {total: 2}
    },
    'WA.XIII 34946/20/906': {
        cases: [
            {
                case: {
                    caseReference: 'TO.VII 1365/21/310',
                    kind: 'Zmiana',
                    status: 'string',
                    startDate: '2021-02-16',
                    endDate: '2021-02-17',
                    hasMovedFiles: false,
                    krsNumber: '0000256206',
                },
                repoType: 'RAS',
            },
            {
                case: {
                    caseReference: 'TO.VII 1365/21/123',
                    kind: 'Zmiana',
                    status: 'string',
                    startDate: '2020-01-01',
                    endDate: '2020-02-02',
                    hasMovedFiles: true,
                    krsNumber: '0000123123',
                },
                repoType: 'RAS',
            },
        ],
        meta: {total: 2}
    },
};
