import { REQUEST_ERROR } from './consts';
import { IDENTIFY_URL, LOGIN_URL } from './urls/backend';


function setApiAppVersion(xhr) {
    const version = xhr.getResponseHeader('X-Build-Revision');
    const prsURL = xhr.getResponseHeader('X-PRS-URL');

    if (prsURL && prsURL !== window.externalEnvironment) {
        window.externalEnvironment ? window.externalEnvironment.set(prsURL) : window.externalEnvironment = prsURL;
    }

    if (version && window.apiAppVersionProviderComponent) {
        window.apiAppVersionProviderComponent.set(version);
    }
}

export const fetchApi = ({
    url,
    method = 'GET',
    body = {},
    encodeParams = true,
    callbackSuccess = (() => {}),
    callbackError = (() => {}),
    callbackIncorrectStatus = (() => {}),
    callbackShowErrors = (() => {}),
}) => {

    const xhr = new XMLHttpRequest();
    if (!['POST', 'PUT'].includes(method)) {
        if (Object.keys(body).length) {
            let urlParamsPart = '';
            for (let [k, v] of Object.entries(body)) {
                if (Array.isArray(v)) {
                    for (let subvalue of v) {
                        urlParamsPart += `&${k}=${subvalue}`;
                    }
                } else {
                    urlParamsPart += `&${k}=${v}`;
                }
            }
            if (urlParamsPart.length) {
                url += `?${urlParamsPart.substring(1)}`;
            }
        }
    }
    xhr.open(
        method,
        (process.env.REACT_APP_LOCAL_BACKEND_DOMAIN
            ? process.env.REACT_APP_LOCAL_BACKEND_DOMAIN + '/api/v1'
            : process.env.REACT_APP_LOCAL_THE_SAME_DOMAIN
                ? '/api/v1'
                : 'https://rar.ms.gov.pl/api/v1' // for instances on server
        ) + (encodeParams ? encodeURI(url) : url)
    );
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    if (window.sessionComponent?.isUserInternal()) {
        xhr.setRequestHeader('X-Internal-User', 'true');
    }
    xhr.onerror = () => {callbackIncorrectStatus(xhr.status, xhr.responseText)};
    xhr.onload = () => {
        setApiAppVersion(xhr);

        if ([200, 400].includes(xhr.status)) {
            if (xhr.status === 200 && !xhr.responseText) {
                callbackSuccess({});
            } else {
                let success = true;
                let jsonData = {};
                try {
                    jsonData = JSON.parse(xhr.responseText);
                } catch(error) {
                    success = false;
                }
                if (success) {
                    if (xhr.status === 200) {
                        callbackSuccess(jsonData);
                    } else {
                        callbackShowErrors(jsonData);
                    }
                } else {
                    callbackError(REQUEST_ERROR);
                }
            }
            // don't set expire for internal user
            if (window.sessionComponent?.isUserInternal()) { return }
            // refresh session time if needed
            let expire = xhr.getResponseHeader('X-Auth-Expire');
            expire = parseInt(expire) || 0;
            if (expire) {
                window.sessionComponent.setExpire(expire);
            }
        } else {
            if (xhr.status === 401) {
                if (window.sessionComponent?.isUserInternal()) {
                    // User is not logged in, redirect to external login page
                    window.sessionComponent.loginInternalUser();
                    return
                } else if (![IDENTIFY_URL, LOGIN_URL].includes(url)) {
                    // there are some urls for what 401 means something different
                    window.sessionComponent?.logout(true);
                    return
                }
            }
            callbackIncorrectStatus(xhr.status, xhr.responseText);
        }
    };

    xhr.send(JSON.stringify(body));
    return xhr
};
