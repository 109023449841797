import { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { BoxCustom, ButtonCustom, TextCustom } from '../atoms';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const Help = () => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const breakpointXs = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <>
      <BoxCustom title="Pomoc" boxTitleTag="h2" width={!breakpointXs && '70%'} pContent={6} pyContent={4} mx="auto">
        <TextCustom>
          Bezpośredni kontakt z Centrum Wsparcia jest możliwy w dni robocze od poniedziałku do piątku w godzinach od 7:30 do 15:30.
        </TextCustom>
        <TextCustom>
          Zgłoszenia prosimy kierować na adres <a href="mailto:krs@ms.gov.pl">krs@ms.gov.pl</a> lub pod nr tel.: <a href="tel:00717489600">(71) 748 96 00</a> wybieramy 1 a następnie 4.
        </TextCustom>
        <TextCustom>
          Zgłoszenia wysłane drogą elektroniczną w celu umożliwienia sprawniejszej obsługi powinny zawierać minimum:
        </TextCustom>
        <ul className="requirement-list">
          <li>Dane kontaktowe (Imię, Nazwisko i/lub instytucja, prawidłowy adres e-mail, nr telefonu);</li>
          <li>Nazwę systemu operacyjnego i jego wersję (np. Windows 7, Windows 10, OS MAC 10.13);</li>
          <li> Nazwę przeglądarki internetowej (np. Internet Explorer 11, Mozilla Firefox 89.0.2 (64 bity), Google Chrome 91.0.4472.114, Microsoft Edge Wersja 91.0.864.59);</li>
          <li>Czas wystąpienia błędu: datę, godzinę;</li>
          <li>Miejsca wystąpienia błędu: na jakim oknie/formatce wystąpił błąd: (np. Repozytorium Akt Rejestrowych, Repozytorium Akt Spraw dotyczących podmiotów niewpisanych do KRS, Wyszukiwanie spraw, Wyszukiwanie dokumentów);</li>
          <li>Widok ekranu z błędem (Print Screen);</li>
        </ul>
        <TextCustom>
          Dla już zgłoszonych wcześniej spraw otrzymany z systemu numer zgłoszenia w temacie wiadomości mailowej.
        </TextCustom>
        <TextCustom className="section-end">
          Informujemy, że pracownicy Centrum Wsparcia nie udzielają porad prawnych. Pracownicy Centrum Wsparcia udzielają jedynie pomocy technicznej przy obsłudze Przeglądarki Akt Rejestrowych.
        </TextCustom>
        <ButtonCustom
          component={'a'}
          download="pomoc_rar"
          fullWidth
          href="/rar.instrukcja_uzytkownika-uzytkownik_zewnetrzny.pdf"
          key={4}
          variant="outlined"
        >Pobierz instrukcję użytkownika</ButtonCustom>
        {user.isInternal && (
          <>
            <h3 className="internal-user-section-title">Jeśli jesteś pracownikiem sądu</h3>
            <ButtonCustom
              component={'a'}
              download="pomoc_dla_pracownikow_sadowych_rar"
              fullWidth
              href="/rar.instrukcja_uzytkownika-uzytkownik_wewnetrzny.pdf"
              key={5}
              variant="outlined"
            >Pobierz instrukcję dla pracownika sądowego</ButtonCustom>
          </>
        )}
      </BoxCustom>
    </>
  );
}
