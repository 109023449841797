import { useEffect, useContext, useState } from 'react';

import { UserContext } from '../../context/userContext';
import { fetchApi } from '../../fetch';
import { InfoAlert } from '../atoms';


function sortByValidFrom(message1Data, message2Data) {
    return new Date(message1Data.validFrom) - new Date(message2Data.validFrom)
}


export const ServiceMessages = (props) => {
    const { initialized, user } = useContext(UserContext);
    const [serviceMessages, setServiceMessages] = useState([]);

    useEffect(() => {
        if (!initialized || user.isAuthenticated) {
            setServiceMessages([]);
            return
        }

        let xhrFetch = null;

        function callbackFetchDataSuccess(data) {
            xhrFetch = null;
            setServiceMessages((data || []).sort(sortByValidFrom));
        }

        function callbackFetchDataError() {
            xhrFetch = null;
        }

        xhrFetch = fetchApi({
            url: '/service-messages',
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataError,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [initialized, user.isAuthenticated]);

    if (!serviceMessages.length) {
        return null
    }

    return serviceMessages.map(message => (
        <InfoAlert key={message.id}>{message.message}</InfoAlert>
    ))
};
