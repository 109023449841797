import { Typography } from '@material-ui/core';

import './SkipLinks.sass';

export const SkipLinks = ({
  onClickFocusContent,
  onClickFocusLogin,
  onClickFocusMenu,
}) => {

  const SKIP_LINKS = [
    {
      id: 1,
      label: 'Przejdź do menu',
      onClick: onClickFocusMenu,
    },
    {
      id: 2,
      label: 'Przejdź do autoryzacji',
      onClick: onClickFocusLogin,
    },
    {
      id: 3,
      label: 'Przejdź do treści',
      onClick: onClickFocusContent,
    },
  ];

  return (
    <nav className="skip-links-container" aria-labelledby="skipLinksNav">
      <h2 className="sr-only" id="skipLinksNav">Nawigacja pomocnicza</h2>
      <ul>
        {[...SKIP_LINKS].map((link) =>
          link.onClick && (
            <li key={link.id}>
              <Typography
                component="button"
                onClick={link.onClick}
                className="skip-link"
                tabIndex={link.id} >
                {link.label}
              </Typography>
            </li>
          )
        )}
      </ul>
    </nav>
  )
};
