import { translationDict } from '../consts';


const translate = (text) => {
    return translationDict[text] || text
};

export const formatFormErrors = (originalErrors, fieldsNames) => {
    /*
        an example originalErrors structure:
        {
          fieldFormErrors: [
            {
              errorCode: 'INVALID',
              fieldName: 'krsNumber',
            },
            // This one means that there are global form errors
            {
              errorCode: 'FORM_INVALID',
              fieldName: null,
            },
          ],
        }
        and returned structure:
        {
          krsNumber: 'INVALID',
          formErrors: ['FORM_INVALID'],
        }
    */
    let errors = {};
    if ((originalErrors.fieldFormErrors || []).length) {
        for (let errorDict of originalErrors.fieldFormErrors) {
            const errorName = errorDict.fieldName;
            if ([null, 'null'].includes(errorName)) {
                /*
                    form error; it has to be a list because of frontend
                    validation structure
                */
                errors.formErrors = [translate(errorDict.errorCode)];
                continue
            }
            if (!errorName || !fieldsNames.includes(errorName)) { continue }
            // field error
            errors[errorName] = translate(errorDict.errorCode);
        }
    }
    return errors
};

export const formatChoice = (choice) => {
    return choice.key
}

export const formatChoiceKey = (choice) => {
    return `${choice.key}_${choice.value}`
};

export const sortDictionaryOptions = (option1, option2) => {
    return option1.value.localeCompare(option2.value, 'pl')
};
