import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';

import { InputCustom } from '../atoms';
import './molecules-global.sass';

export const DatePickerReact = ({
  dateErrorText,
  dateFormatInfo,
  disabled,
  id,
  isInvalid,
  label,
  onChange,
  reactDatePicker,
  selected,
  showTimeSelect,
  type,
  value,
  ...props
}) => {
  registerLocale('pl', pl);

  return (
    <DatePicker
      dateFormat={showTimeSelect ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy'}
      locale="pl"
      onChange={onChange}
      selected={selected}
      showTimeSelect={showTimeSelect}
      timeFormat="HH:mm"
      timeCaption="Czas:"
      placeholderText={showTimeSelect ? 'dd.mm.rrrr, hh:mm' : 'dd.mm.rrrr'}
      customInput={<InputCustom
        errorOrHelperText={dateErrorText || dateFormatInfo || 'Wybierz datę według wzoru: dd.mm.rrrr'}
        disabled={disabled}
        id={id}
        isInvalid={isInvalid}
        label={label}
        value={value}
        {...props}
      />}
    />
  );
};
