import {
    Box,
    Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { DialogWithButton } from '../../molecules';

import './LabeledElement.sass';

export const LabeledElement = ({
    children,
    label,
    moreInfo,
    moreInfoTitle,
    text,
    position = 'left',
    ...props
}) => {
    const ContentTag = text ? Typography : Box
    return (
        <Box lineHeight={1} {...props}>
            <div className="labeled-element__info-container">
                <Typography
                    className={`labeled-element__label${moreInfo ? ' labeled-element__label--more-info' : ''}`}
                    color="textSecondary"
                    component="span"
                    variant="caption"
                >
                    {label || null}
                </Typography>
                {moreInfo && (
                    <DialogWithButton
                        buttonClassName={`labeled-element__info-btn${(moreInfo && !label) ? position === 'left' ? ' labeled-element__info-btn--no-label' : ' labeled-element__info-btn--no-label-right' : ''}`}
                        dialogTitle={moreInfoTitle}
                        dialogOpenBtnIconText="Więcej informacji"
                        dialogBtnIcon={<InfoOutlinedIcon fontSize="small" />}
                    >
                        {moreInfo}
                    </DialogWithButton>
                )}
            </div>
            <ContentTag variant={text ? 'body2' : null}>{children}</ContentTag>
        </Box>
    );
};
