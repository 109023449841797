import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';

import { EMPTY_VALUE, REPO_TYPES } from '../../consts';
import {
    formatFileFormat,
    formatFileSize,
    isDocumentOutOfCase
} from '../../helpers/helpers';
import { useGetSingleObjectData } from '../../hooks';
import { URLS } from '../../urls/frontend';
import { ButtonCustom, Loader } from '../atoms';
import {
    DialogWithButton,
    KRSSubjectDataWidget,
    MetadataBox,
    MetadataBoxItem,
} from '../molecules';
import { BreadcrumbsWithPageTitle } from '../organisms';
import {
    CasesForDocument,
    DocumentInCaseMetaData,
    ShowDocumentSection
} from '../molecules/documentPage';
import { convertDateWithoutTime } from '../../helpers/helpers'

const DOCUMENT_NOT_EXISTS_INFO = 'Podany dokument nie istnieje w systemie.';


export const Document = ({inCase = false, repoName}) => {
    const repoData = useMemo(() => REPO_TYPES[repoName], [repoName]);

    let { caseReference, identifier, metricIdentifier} = useParams();

    try {
        metricIdentifier = atob(metricIdentifier)
    } catch(_) {
        metricIdentifier= null
    }

    try {
        identifier = atob(identifier);
    } catch (error) {
        identifier = null;
    }

    if (inCase) {
        try {
            caseReference = atob(caseReference);
        } catch (error) {
            caseReference = null;
        }
    } else {
        caseReference = '';
    }

    const { isLoading, data, fetchError } = useGetSingleObjectData(
        identifier,
        '/document',
        {
            identifier: encodeURIComponent(identifier),
            repoType: repoData.fetchParamName,
            ...(metricIdentifier && { metricIdentifier: encodeURIComponent(metricIdentifier )}),
            ...(inCase ? {caseReference: encodeURIComponent(caseReference)} : {}),
        },
        DOCUMENT_NOT_EXISTS_INFO,
        false   // it's already encoded
    );

    let { cases, krsSubject = {}, fileIdentifier } = data;
    cases = cases || [];
    const dialogTitle = `${inCase ? 'Inne sprawy' : 'Sprawy'} w RAR, w których występuje dokument`;

    return (
        <>
            <BreadcrumbsWithPageTitle
                actionButton={inCase &&
                    <ButtonCustom
                        btnContainerClassName="case-reference-btn"
                        buttonLink
                        standardFont
                        to={URLS[repoData.urlsNames.case_].getUrl(caseReference)}>
                        Przegląd sprawy
                    </ButtonCustom>
                }
                breadcrumbs={[
                    [URLS.home.path, 'Repozytoria Akt'],
                    [repoData.frontendUrl, repoData.breadcrumbLinkLabel],
                ]}
                currentLocation="Przegląd Danych Dokumentu"
                otherTitles={inCase && !!data && [
                    ['Sygnatura sprawy:', caseReference],
                ]}
                subtitle={fileIdentifier}
                title="Identyfikator dokumentu:"
            />
            {isLoading ? <Loader /> : !!fetchError
                ? <p className="error" role="alert">{fetchError}</p>
                : (!identifier || (inCase && !caseReference))
                    ? <p className="error">{DOCUMENT_NOT_EXISTS_INFO}</p>
                    : (
                        <>
                            <Box m={0} mb={1}>
                                <Grid container spacing={1}>
                                    <MetadataBox md={3}>
                                        <MetadataBoxItem
                                            label="Rodzaj dokumentu"
                                            text
                                            sm={12}
                                        >
                                            {data.type || EMPTY_VALUE}
                                        </MetadataBoxItem>
                                        {[REPO_TYPES.rar.name, REPO_TYPES.rwip.name].includes(repoName) && (
                                            <MetadataBoxItem
                                                label="Oznaczenie dokumentu kluczowego"
                                                text
                                                sm={6}
                                            >
                                                {data.characterForSubject || EMPTY_VALUE}
                                            </MetadataBoxItem>
                                        )}
                                        {!inCase && repoName === REPO_TYPES.rp.name && (
                                            <MetadataBoxItem
                                                label="Poziom dostępu"
                                                text
                                                sm={6}
                                            >
                                                dla każdego
                                            </MetadataBoxItem>
                                        )}
                                        {inCase && (
                                            <MetadataBoxItem
                                                label="Numer dokumentu w sprawie"
                                                text
                                                sm={6}
                                            >
                                                {data.numberInCase || EMPTY_VALUE}
                                            </MetadataBoxItem>
                                        )}
                                    </MetadataBox>
                                    <MetadataBox md>
                                        <MetadataBoxItem
                                            label="Data sporządzenia"
                                            text
                                            sm
                                        >
                                            {data.composedDate || EMPTY_VALUE}
                                        </MetadataBoxItem>
                                        {inCase && (
                                            <MetadataBoxItem
                                                label="Data ujawnienia w sprawie"
                                                text
                                                sm={6}
                                            >
                                                {data.disclosedDate ? convertDateWithoutTime(data.disclosedDate) : EMPTY_VALUE}
                                            </MetadataBoxItem>
                                        )}
                                        <MetadataBoxItem
                                            label="Chwila wpływu do sądu"
                                            text
                                            sm={12}
                                        >
                                            {data.arrivedDate || EMPTY_VALUE}
                                        </MetadataBoxItem>
                                    </MetadataBox>
                                    {!inCase && (
                                        <MetadataBox md={2}>
                                            <MetadataBoxItem
                                                label="Rozmiar pliku"
                                                text
                                                sm={6}
                                            >
                                                {formatFileSize(data.fileSize)}
                                            </MetadataBoxItem>
                                            <MetadataBoxItem
                                                label="Format pliku"
                                                text
                                                sm={6}
                                            >
                                                {formatFileFormat(data.format)}
                                            </MetadataBoxItem>
                                            <MetadataBoxItem
                                                label="Czy dokument wpłynął jako elektroniczny?"
                                                text
                                                sm={6}
                                            >
                                                {data.digital ? 'TAK' : 'NIE'}
                                            </MetadataBoxItem>
                                            <MetadataBoxItem
                                                label="Język"
                                                text
                                                sm={6}
                                            >
                                                {data.language || EMPTY_VALUE}
                                            </MetadataBoxItem>
                                        </MetadataBox>
                                    )}
                                    {repoName !== REPO_TYPES.rp.name && (
                                        <MetadataBox md={2}>
                                            <MetadataBoxItem>
                                                <DialogWithButton
                                                    disabled={!cases.length}
                                                    dialogOpenBtnText={`${dialogTitle}: ${cases.length}`}
                                                    dialogTitle={dialogTitle}
                                                    variant="outlined"
                                                >
                                                    <CasesForDocument items={cases} repoData={repoData} />
                                                </DialogWithButton>
                                            </MetadataBoxItem>
                                        </MetadataBox>
                                    )}
                                    {[REPO_TYPES.rar.name, REPO_TYPES.rwip.name].includes(repoName) && !!krsSubject &&
                                        <KRSSubjectDataWidget
                                            data={krsSubject}
                                            krsSubjectUrlName={repoData.urlsNames.KRSSubject}
                                        />
                                    }
                                </Grid>
                            </Box>
                            {inCase && <DocumentInCaseMetaData data={data} />}
                            {!isDocumentOutOfCase(data.statusInCase) && (
                                <ShowDocumentSection
                                    accessLevel={data.accessLevel?.key}
                                    caseEndType={inCase ? data.caseEndType : null}
                                    caseReference={caseReference}
                                    casesForDocument={cases || []}
                                    identifier={identifier}
                                    inCase={inCase}
                                    repoName={repoName}
                                    signatures={data.signatures || []}
                                />
                            )}
                        </>
                    )
            }
        </>
    )
}
