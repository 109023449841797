import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './ButtonCustom.sass';

export const ButtonCustom = ({
  btnContainerClassName,
  buttonLink,
  color,
  component,
  className,
  fullWidth,
  m,
  mb,
  mr,
  my,
  position,
  right,
  top,
  standardFont,
  type,
  width,
  variant,
  // only if component="a":
  href,
  rel,
  target,
  ...props
}) => {
  const btnClassName = `${fullWidth ? 'button-custom--full-width' : ''} ${buttonLink && !standardFont ? 'button-custom--button-link' : ''} ${className ? className : ''} `

  return (
    <Box
      className={btnContainerClassName}
      m={m}
      mr={mr}
      mb={mb}
      my={my}
      position={position || 'relative'}
      top={top}
      right={right}
      width={width}
    >
      <Button
        classes={{ root: 'button-custom' }}
        className={btnClassName}
        color={color || 'primary'}
        component={component || (buttonLink && Link) || 'button'}
        startIcon={buttonLink ? <VisibilityIcon /> : null}
        type={type || 'button'}
        variant={(buttonLink && 'outlined') || variant || 'contained'}
        // only if component="a":
        href={href}
        rel={rel}
        target={target}
        {...props}
      />
    </Box>
  );
};
