import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { formatChoice } from '../../helpers/forms';
import { useGetDictionariesValues } from '../../hooks';
import { BoxCustom, ButtonCustom } from '../atoms';
import { ClearFiltersButton, DialogBase } from '../molecules';
import './organisms-global.sass';


export const FiltersForm = ({
    cleanedFilters=null,  // one can manage which filters should be cleaned; if null, clear all filters
    dateNamesList = [],
    defaultFilters,
    disabled,
    errors,
    FiltersFieldsComponent,
    initialFilters = {},
    selectDictNamesList = [], // dictionaries names for select fields
    selectDictNameSelectNameList = [],
    textNamesList = [],
    onFilter,
    ...props
}) => {
    const [dictionariesData, setDictionariesData] = useState({});
    const [dictionariesErrorOn, setDictionariesErrorOn] = useState(false);
    const [filters, setFilters] = useState(
        {...(initialFilters || defaultFilters)});
    const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
    const [
        previousFiltersInDialog, setPreviousFiltersInDialog
    ] = useState(filters);

    const theme = useTheme();
    const filtersHidden = useMediaQuery(theme.breakpoints.down('md'));

    function handleChangeFilter(filterName, value) {
        setFilters(prevVal => ({
            ...prevVal,
            [filterName]: value,
        }));
    }

    // manage selects

    function checkInitialSelectValueWithChoices(dictionariesData) {
        for (let [dictName, selectName] of selectDictNameSelectNameList) {
            if (filters[selectName] === defaultFilters[selectName]) {
                continue
            }
            // check if in select choices
            const choices = dictionariesData[dictName] || [];
            if (
                !choices || !choices.length ||
                !choices.map(choice =>
                    formatChoice(choice)).includes(filters[selectName]
                )
            ) {
                handleChangeFilter(selectName, defaultFilters[selectName]);
            }
        }
    }

    useGetDictionariesValues(
        selectDictNamesList, setDictionariesData,
        setDictionariesErrorOn, checkInitialSelectValueWithChoices
    );

    // manage initial dates

    useEffect(() => {
        // check initial values; they may change on back button,
        // that's why the behavior below should be correct

        // check date fields
        let datesDict = {};
        for (let dateFieldName of dateNamesList) {
            // if initial date is set and it is not in date format, clean it
            if (
                initialFilters[dateFieldName] !== defaultFilters[dateFieldName]
                && isNaN(Date.parse(initialFilters[dateFieldName]))
            ) {
                datesDict[dateFieldName] = '';
            }
        }

        // update filters
        setFilters({
            ...initialFilters,
            ...datesDict,
        });
    }, [defaultFilters, initialFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!Object.keys(dictionariesData).length) { return }
        handleFilter();
    }, [dictionariesData]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleFilterCancel() {
        // for filters in dialog set previous filters before closing dialog
        setFilters(previousFiltersInDialog);
        setFiltersDialogOpen(false);
    }

    function handleFilter() {
        setPreviousFiltersInDialog(filters);
        onFilter(filters, handleFilterCancel);
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        handleFilter();
    }

    function handleCleanFilters() {
        setPreviousFiltersInDialog(filters);
        setFilters(prevValue => ({
            ...prevValue,
            ...(cleanedFilters !== null ? cleanedFilters : defaultFilters),
        }));
    }

    const filtersTitleButton = (
        <>
            <span>Filtry</span>
            <ClearFiltersButton
                onClick={handleCleanFilters}
                position="absolute"
                top={10}
                right={5}
            />
        </>
    )

    const filtersContent = (
        <form onSubmit={handleSubmit} id="filters_form">
            <FiltersFieldsComponent
                dateNamesList={dateNamesList}
                dictionariesData={dictionariesData}
                dictionariesErrorOn={dictionariesErrorOn}
                disabled={disabled}
                errors={errors}
                filters={filters}
                selectDictNamesList={selectDictNamesList}
                textNamesList={textNamesList}
                onChangeFilter={handleChangeFilter}
            />
        </form>
    )

    const filtersGlobalErrors = (
        (errors.formErrors || []).map((error, i) => (
            <p className="error" key={`${error}_${i}`} role="alert">{error}</p>
        ))
    )

    return filtersHidden
        ? (
            <>
                <ButtonCustom
                    className="tables-with-filters__dialog-btn"
                    onClick={() => setFiltersDialogOpen(true)}
                >
                    Otwórz panel filtrów
                </ButtonCustom>
                <DialogBase
                    buttonType="submit"
                    buttonForm="filters_form"
                    dialogConfirmBtnText="Filtruj"
                    disabled={disabled}
                    dialogOpenBtnText="Otwórz panel filtrów"
                    dialogTitle={filtersTitleButton}
                    dialogCancelBtnText="Anuluj"
                    formGlobalErrors={filtersGlobalErrors}
                    onDialogConfirm={handleFilter}
                    onDialogClose={handleFilterCancel}
                    open={filtersDialogOpen}
                >
                    {filtersContent}
                </DialogBase>
            </>
        ) : (
            <BoxCustom
                accent
                className="tables-with-filters__filters"
                ml={2}
                title={filtersTitleButton}
            >
                <>
                    {filtersContent}
                    <ButtonCustom
                        disabled={disabled}
                        fullWidth
                        onClick={handleFilter}
                    >
                        Filtruj
                    </ButtonCustom>
                    {filtersGlobalErrors}
                </>
            </BoxCustom>
        )
};
