import { useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import { getDocumentFileAccessAction } from '../../../helpers/helpers';
import { ButtonCustom } from '../../atoms';
import { LoginForm } from '../../organisms';
import { DialogBase } from '../DialogBase';
import { LabeledElement } from '../../molecules'

export const DownloadDocumentSection = ({
    accessLevel = 'PUBLIC',
    caseEndType,
    caseReference,
    casesForDocument,
    identifier,
    inCase,
    repoName
}) => {
    const { authorizationFocused, setAuthorizationFocused } = useContext(AppContext);
    const { user } = useContext(UserContext);
    const [noAccessDialogOn, setNoAccessDialogOn] = useState(false);
    const [loginWindowOn, setLoginWindowOn] = useState(false);

    useEffect(() => {
        authorizationFocused && setLoginWindowOn(true);

        return () => {
            setAuthorizationFocused(false);
        }
    }, [authorizationFocused, setAuthorizationFocused, setLoginWindowOn]);

    const downloadFileAction = useMemo(
        () => {
            return getDocumentFileAccessAction({
                accessLevel, casesForDocument, inCase, repoName, user, caseEndType});
        },
        [accessLevel, caseEndType, casesForDocument, inCase, repoName, user]
    );

    let fileUrl = useMemo(
        () => (window.location.origin +
            '/api/v1/download/document?identifier=' +
            encodeURIComponent(identifier) +
            (inCase ? `&caseReference=${encodeURIComponent(caseReference)}` : '') +
            (user.isInternal ? '&internalUser=true' : '')
        ),
        [caseReference, identifier, inCase, user.isInternal]
    )

    function handleOpenLoginForm() {
        // try to log in and then maybe user will has an access
        setNoAccessDialogOn(false);
        setLoginWindowOn(true);
    }

    function handleOpenNoAccessInfo() {
        setLoginWindowOn(false);
        setNoAccessDialogOn(true);
    }

    function handleCloseLoginForm() {
        setLoginWindowOn(false);
    }

    return (
        <>
            {['LOGIN', 'NO_ACCESS_INFO'].includes(downloadFileAction) &&
                [
                    ['Pokaż treść dokumentu', 'Prezentacja treści wniosku o wpis w postaci pdf, lub prezentacja treści załączników do wniosku dołączonych w formacie pdf. Jeżeli dokument prezentuje się nieprawidłowo (jest zaszyfrowany), użyj przycisku „Pobierz dokument bez podpisów”'],
                    ['Pobierz dokument', 'Pobranie dokumentu elektronicznego w postaci, w jakiej został złożony w Portalu Rejestrów Sądowych, S24 lub dołączony przez sąd'],
                    ['Pobierz dokument bez podpisów', 'Pobranie treści dokumentu w postaci pierwotnej, bez podpisów elektronicznych']
                ].map(([label, tooltip]) => (
                    <LabeledElement key={label} moreInfo={tooltip} position={'right'}>
                        <ButtonCustom
                            fullWidth
                            onClick={downloadFileAction === 'LOGIN'
                                ? handleOpenLoginForm
                                : handleOpenNoAccessInfo
                            }
                        >
                            {label}
                        </ButtonCustom>
                    </LabeledElement>
                ))
            }
            {downloadFileAction === 'GET_FILE' && [
                    ['pdf', 'Pokaż treść dokumentu', 'Prezentacja treści wniosku o wpis w postaci pdf, lub prezentacja treści załączników do wniosku dołączonych w formacie pdf. Jeżeli dokument prezentuje się nieprawidłowo (jest zaszyfrowany), użyj przycisku „Pobierz dokument bez podpisów”'],
                    ['zip', 'Pobierz dokument', 'Pobranie dokumentu elektronicznego w postaci, w jakiej został złożony w Portalu Rejestrów Sądowych, S24 lub dołączony przez sąd'],
                    ['origin', 'Pobierz dokument bez podpisów', 'Pobranie treści dokumentu w postaci pierwotnej, bez podpisów elektronicznych']
                ]
                .map(([transform, label, tooltip]) => (
                    <LabeledElement
                        moreInfo={tooltip}
                        position={'right'}
                        key={label}
                    >
                        <ButtonCustom
                            component="a"
                            fullWidth
                            href={`${fileUrl}&transform=${transform}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {label}
                        </ButtonCustom>
                    </LabeledElement>
                ))
            }

            {noAccessDialogOn && (
                <DialogBase
                    dialogTitle="Nie posiadasz uprawnień do pobrania lub przeglądania treści dokumentu."
                    open
                    onDialogClose={() => setNoAccessDialogOn(false)}
                />
            )}
            {loginWindowOn && (
                <LoginForm
                    extendedInfo="Autoryzacja umożliwia przeglądanie dokumentów o ograniczonym poziomie dostępności."
                    onDialogClose={handleCloseLoginForm}
                />
            )}
        </>
    )
};
