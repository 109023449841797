import { Box, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './InfoAlert.sass';

export const InfoAlert = ({ children, formInfoAlert, ...props }) => (
  <Box {...props} className={`info-alert ${formInfoAlert ? 'info-alert--form' : null}`}>
    <InfoOutlinedIcon color="primary" fontSize="small" />
    <Typography className="info-alert__text" variant="body2" role="alert">{children}</Typography>
  </Box>
);
