import { Typography } from '@material-ui/core';

import './PageTitle.sass';

export const PageTitle = ({
  children,
  extendedName,
  otherTitles,
  subtitle
}) => (
  <Typography component="h2" className="page-title">
    <Typography component="span" variant="h5" color="textSecondary">{children} </Typography>
    <Typography component="span" variant="h5" color="textPrimary">{subtitle}</Typography>
    {otherTitles && otherTitles.map((title, idx) =>
      <span key={idx}>
        <Typography
          className="page-title__other-title"
          color="textSecondary"
          component="span"
          variant="h5"
        >
          {title[0]}
        </Typography>
        <Typography component="span" variant="h5" color="textPrimary">
          {title[1]}
        </Typography>
      </span>
    )}
    {extendedName && <Typography component="span" className="page-title__extended-name" color="textSecondary">{extendedName}</Typography>}
  </Typography>
);

export default PageTitle; 
