import { MenuItem } from '@material-ui/core';

import { CASE_REFERENCE_INPUT_MAX_LENGTH } from '../../consts';
import { formatChoice, formatChoiceKey } from '../../helpers/forms';
import { DatePickerCustom } from '../molecules';
import { InputCustom, SelectCustom } from '../atoms';
import './molecules-global.sass';

const FILTERS_SELECT_FIELDS_DATA = [
    {
        dictionaryName: 'caseKind',
        fetchingChoicesError: 'Nie udało się pobrać rodzajów spraw.',
        filterName: 'caseType',
        id: 'id_case_type',
        label: 'Rodzaj',
    },
    {
        fetchingChoicesError: 'Nie udało się pobrać statusów spraw.',
        filterName: 'caseStatus',
        id: 'id_case_status',
        label: 'Stan',
    },
    {
        fetchingChoicesError: 'Nie udało się pobrać typów pisma wszczynającego.',
        filterName: 'caseApplicationType',
        id: 'id_case_application_type',
        label: 'Typ pisma wszczynającego',
    },
    {
        fetchingChoicesError: 'Nie udało się pobrać sposobów wszczęcia.',
        filterName: 'caseStartType',
        id: 'id_case_start_type',
        label: 'Sposób wszczęcia',
    },
    {
        fetchingChoicesError: 'Nie udało się pobrać sposobów zakończenia.',
        filterName: 'caseEndType',
        id: 'id_case_end_type',
        label: 'Sposób zakończenia',
    }
];
const FILTERS_DATE_FIELDS_DATA = [
    {
        id: 'arrivedDate',
        label: 'Wszczęcie',
        from: {
            filterName: 'startDateFrom',
            id: 'id_start_date_from',
            label: 'Od',
        },
        to: {
            filterName: 'startDateTo',
            id: 'id_start_date_to',
            label: 'Do',
        },
    },
    {
        id: 'composedDate',
        label: 'Zakreślenie',
        from: {
            filterName: 'endDateFrom',
            id: 'id_end_date_from',
            label: 'Od',
        },
        to: {
            filterName: 'endDateTo',
            id: 'id_end_date_to',
            label: 'Do',
        },
    },
];


export const CasesFiltersFields = ({
    dictionariesData,
    dictionariesErrorOn,
    disabled,
    errors,
    filters,
    onChangeFilter
}) => {
    return (
        <>
            <InputCustom
                errorOrHelperText={errors.caseReference}
                disabled={disabled}
                id="id_case_reference"
                isInvalid={!!errors.caseReference}
                label="Sygnatura"
                maxLength={CASE_REFERENCE_INPUT_MAX_LENGTH}
                value={filters.caseReference}
                onChange={ev => onChangeFilter(
                    'caseReference', ev.target.value
                )}
            />
            {FILTERS_SELECT_FIELDS_DATA.map(filterData => {
                const { filterName } = filterData;
                const choices = dictionariesData[filterData.dictionaryName] ||
                    dictionariesData[filterName] || [];
                return (
                    <div key={filterData.id}>
                        <SelectCustom
                            disabled={dictionariesErrorOn || disabled}
                            errorOrHelperText={(dictionariesErrorOn && filterData.fetchingChoicesError) || (!!errors[filterName] && errors[filterName])}
                            label={filterData.label}
                            id={filterData.id}
                            isInvalid={dictionariesErrorOn || !!errors[filterName]}
                            value={choices.length > 0 ? filters[filterName] : '0'}
                            onChange={ev => onChangeFilter(
                                filterName, ev.target.value
                            )}
                        >
                            {choices.map(choice => (
                                <MenuItem
                                    key={formatChoiceKey(choice)}
                                    value={formatChoice(choice)}
                                >
                                    {choice.value}
                                </MenuItem>
                            ))}
                        </SelectCustom>
                    </div>
                )
            })}

            <InputCustom
                errorOrHelperText={errors.applicant}
                disabled={disabled}
                id="id_applicant"
                isInvalid={!!errors.applicant}
                label="Wnioskodawca"
                maxLength={100}
                value={filters.applicant}
                onChange={ev => onChangeFilter('applicant', ev.target.value)}
            />

            <InputCustom
                errorOrHelperText={errors.relatedCase}
                disabled={disabled}
                id="id_related_case"
                isInvalid={!!errors.relatedCase}
                label="Powiązana ze sprawą"
                maxLength={CASE_REFERENCE_INPUT_MAX_LENGTH}
                value={filters.relatedCase}
                onChange={ev => onChangeFilter('relatedCase', ev.target.value)}
            />

            {FILTERS_DATE_FIELDS_DATA.map(filterData => (
                <fieldset key={filterData.id} className="date-fields">
                    <legend className="date-fields__legend">{filterData.label}</legend>
                    {['from', 'to'].map(caseType => {
                        const data = filterData[caseType];
                        const { filterName } = data;
                        return (
                            <DatePickerCustom
                                dateErrorText={!!errors[filterName] && errors[filterName]}
                                disabled={disabled}
                                id={data.id}
                                isInvalid={!!errors[filterName]}
                                key={data.id}
                                label={data.label}
                                value={filters[filterName]}
                                onChange={ev => onChangeFilter(
                                    filterName, ev.target.value
                                )}
                            />
                        )
                    })}
                </fieldset>
            ))}
        </>
    )
};
