import { Grid } from '@material-ui/core';

import { LabeledElement } from '../molecules';

export const MetadataBoxItem = ({
  children,
  label,
  sm,
  text,
  xs,
  ...props
}) => (
  <LabeledElement
    component={Grid}
    item
    label={label}
    text={text}
    xs={xs || 12}
    sm={sm}
    {...props}
  >
    {children}
  </LabeledElement>
);