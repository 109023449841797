import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';

import {
    KRS_SUBJECT_INPUT_MAX_LENGTH,
    KRS_SUBJECT_PLACEHOLDER,
    REPO_TYPES
} from '../../consts';
import { UserContext } from '../../context/userContext';
import {
    getBlurredKrsNumberValue,
    getPastedKrsNumberValue
} from '../../helpers/helpers';
import { URLS } from '../../urls/frontend';
import {
    validate as validateKRSSubject
} from '../../validation/KRSSubjectForm';
import { BoxCustom } from '../atoms';
import { Cases } from '../molecules/searchPage';
import { BreadcrumbsWithPageTitle, DocumentsSearchForm, SingleFieldSearchForm, CombainedSearchForm } from '../organisms';
import './views-global.sass';
import { useGetDictionariesValues } from '../../hooks/getDataHooks';
import { sortDepartmentDictionary } from '../../helpers/helpers';

const TABS_LIST = [
    {
        sectionName: 'podmiotKRS',
        label: 'Wyszukiwanie Podmiotu po numerze KRS',
    },
    {
        sectionName: 'sprawy',
        label: 'Wyszukiwanie spraw',
    },
    {
        sectionName: 'dokumenty',
        label: 'Wyszukiwanie dokumentów',
    },
];

const SELECT_DICT_NAMES_LIST = ['department']

export const Search = ({repoName}) => {
    const repoData = useMemo(() => REPO_TYPES[repoName], [repoName]);

    const { defaultSection, tabIndexes } = repoData.searchPageConfig;

    const [activeTab, setActiveTab] = useState(tabIndexes[defaultSection]);

    const [dictionariesData, setDictionariesData] = useState({});
    const [dictionariesErrorOn, setDictionariesErrorOn] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const section = params.get('sekcja') || defaultSection;
        setActiveTab(tabIndexes[section] || tabIndexes[defaultSection]);
    }, [history.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChangeTab(tabName) {
        history.push(`${window.location.pathname}?sekcja=${tabName}`);
    }

    const tabsList = useMemo(
        () => {
            const tabsSectionsNames = Object.keys(tabIndexes);
            return TABS_LIST.filter(
                tabData => tabsSectionsNames.includes(tabData.sectionName))
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const { user } = useContext(UserContext);

    const { breadcrumbLinkLabel, extendedLabel, label } = repoData;

    useGetDictionariesValues(
        SELECT_DICT_NAMES_LIST, setDictionariesData, setDictionariesErrorOn
    );

    return (
        <>
            <BreadcrumbsWithPageTitle
                breadcrumbs={[
                    [URLS.home.path, 'Repozytoria Akt'],
                    [repoData.frontendUrl, breadcrumbLinkLabel],
                ]}
                currentLocation="Wyszukiwanie"
                title={label}
                extendedName={extendedLabel}
            />
            <BoxCustom>
                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label={`Panel zakładek, liczba zakładek: ${tabsList.length}`}
                    className="tabs"
                    classes={{ flexContainer: 'tabs__container', indicator: 'tabs__active-indicator' }}
                >
                    {tabsList.map(tabData => (
                        <Tab
                            classes={{root: 'tabs__tab'}}
                            key={tabData.sectionName}
                            label={tabData.label}
                            onClick={() => handleChangeTab(tabData.sectionName)}
                        />
                    ))}
                </Tabs>
                {activeTab === tabIndexes.podmiotKRS && (
                    <SingleFieldSearchForm
                        blurFunction={getBlurredKrsNumberValue}
                        fieldId="id_krsNumber"
                        fieldName="krsNumber"
                        inputErrorOrHelperText="W polu możesz wpisać tylko cyfry"
                        inputPlaceholder={KRS_SUBJECT_PLACEHOLDER}
                        label="Numer KRS"
                        maxLength={KRS_SUBJECT_INPUT_MAX_LENGTH}
                        onlyDigits={true}
                        pasteFunction={getPastedKrsNumberValue}
                        redirectUrl={URLS[repoData.urlsNames.KRSSubject]}
                        validationFunction={validateKRSSubject}
                    />
                )}
                {activeTab === tabIndexes.sprawy && (
                    <>
                        <CombainedSearchForm
                            formId={'issues'}
                            textInformationStyles={'9%'}
                            redirectUrl={URLS[repoData.urlsNames.case_]}
                            dictData={sortDepartmentDictionary(dictionariesData['department'] ?? []) || []}
                            dictionariesErrorOn={dictionariesErrorOn}
                            mandatory={true}
                            label={'Sygnatura sprawy'}
                        />
                    </>
                )}
                {activeTab === tabIndexes.dokumenty && (
                    <DocumentsSearchForm repoData={repoData} />
                )}
            </BoxCustom>
            {activeTab === tabIndexes.sprawy && (user.isExternalNamed || user.isExternalOneTime) && (
                <Cases repoData={repoData} />
            )}
        </>
    )
};
