import { URLS } from '../../urls/frontend';
import { MetadataBox, MetadataBoxItem } from '../molecules';
import { ButtonCustom } from '../atoms';


export const KRSSubjectDataWidget = ({data, krsSubjectUrlName}) => {
    return (
        <MetadataBox md={3}>
            <MetadataBoxItem
                label="Numer KRS Podmiotu"
                text
                sm={5}
            >
                {data.krsNumber}
            </MetadataBoxItem>
            <MetadataBoxItem sm={7}>
                <ButtonCustom
                    buttonLink
                    to={URLS[krsSubjectUrlName].getUrl(data.krsNumber)}
                >
                    Przegląd danych podmiotu
                </ButtonCustom>
            </MetadataBoxItem>
            <MetadataBoxItem
                label="Nazwa Podmiotu"
                text
                sm={12}
            >
                {data.name}
            </MetadataBoxItem>
        </MetadataBox>
    )
};
 