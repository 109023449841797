import { Component, createContext } from 'react';


export const ApiAppVersionContext = createContext({
    version: '',
    set: () => {},
});


export class ApiAppVersionProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            version: '',
            set: this.set,
        };
    }

    set = (version) => {
        this.setState({version});
    }

    render() {
        return (
            <ApiAppVersionContext.Provider value={this.state}>
                {this.props.children}
            </ApiAppVersionContext.Provider>
        );
    }
}
