import { useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';

import { fetchApi } from '../../fetch';
import { formatFormErrors } from '../../helpers/forms';
import { validate } from '../../validation/ChangePasswordForm';
import { ButtonCustom, InputCustom } from '../atoms';
import { DialogBase, DialogNaked } from '../molecules';


export const ChangePasswordForm = ({onCancel}) => {
    const [data, setData] = useState({
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
    });
    const [isChangingOn, setIsChangingOn] = useState(false)
    const [errors, setErrors] = useState({});
    const [errorUnauthenticated, setErrorUnauthenticated] = useState(false);
    const [
        passwordChangedSuccessful, setPasswordChangedSuccessful
    ] = useState(false);

    function handleChangeValue(fieldName, value) {
        setData(prevValue => ({
            ...prevValue,
            [fieldName]: value,
        }));
    }

    useEffect(() => {
        if (!isChangingOn) { return }
        setErrors({});
        const [isValid, errors] = validate(data);
        if (!isValid) {
            setErrors(errors);
            setIsChangingOn(false);
            return
        }

        let xhrFetch = null;

        function callbackFetchDataSuccess() {
            xhrFetch = null;
            setPasswordChangedSuccessful(true);
        }

        function callbackFetchDataError(message) {
            xhrFetch = null;
            setErrors({formErrors: [message]});
            setIsChangingOn(false);
        }

        function callbackFetchDataIncorrectStatus(statusCode) {
            if (statusCode === 401) {
                setErrorUnauthenticated(true);
                return
            }
            callbackFetchDataError(
                'Wystąpił tymczasowy problem ze zmianą hasła. Prosimy spróbować później.');
        }

        function callbackFetchDataShowErrors(data) {
            xhrFetch = null;
            setErrors(formatFormErrors(data, ['oldPassword', 'newPassword']));
            setIsChangingOn(false);
        }

        xhrFetch = fetchApi({
            url: '/change-password',
            method: 'PUT',
            body: {
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
            },
            callbackSuccess: callbackFetchDataSuccess,
            callbackError: callbackFetchDataError,
            callbackIncorrectStatus: callbackFetchDataIncorrectStatus,
            callbackShowErrors: callbackFetchDataShowErrors,
        });

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [isChangingOn]); // eslint-disable-line react-hooks/exhaustive-deps

    if (errorUnauthenticated) {
        return (
            <DialogBase
                open
                dialogConfirmBtnText="OK"
                dialogTitle="Nieudana zmiana hasła."
                hideCloseButton
                onDialogConfirm={() => window.location.reload()}
            >
                <p className="error" role="alert">Wystąpił problem z uwierzytelniem użytkownika.</p>
            </DialogBase>
        )
    }

    if (passwordChangedSuccessful) {
        return (
            <DialogBase
                open
                dialogConfirmBtnText="OK"
                dialogTitle="Hasło zostało zmienione."
                hideCloseButton
                onDialogConfirm={onCancel}
            />
        )
    }

    const formInvalid = !!errors.oldPassword || !!errors.newPassword || !!errors.newPassword2;

    return (
        <DialogNaked open onDialogClose={onCancel}>
            <DialogTitle>Zmień hasło</DialogTitle>
            <DialogContent>
                <form id="change_password_form">
                    <InputCustom
                        autoFocus
                        errorOrHelperText={errors.oldPassword}
                        disabled={isChangingOn}
                        id="id_oldPassword"
                        isInvalid={!!errors.oldPassword}
                        label="Dotychczasowe hasło"
                        required
                        type="password"
                        value={data.oldPassword}
                        onChange={ev => handleChangeValue(
                            'oldPassword', ev.target.value
                        )}
                    />
                    <InputCustom
                        errorOrHelperText={errors.newPassword || 'Hasło powinno zawierać co najmniej 8 znaków, co najmniej jedną małą i jedną wielką literę, co najmniej jedną cyfrę oraz co najmniej jeden znak specjalny !@#$%^&*()_+-='}
                        disabled={isChangingOn}
                        id="id_newPassword"
                        isInvalid={!!errors.newPassword}
                        label="Nowe hasło"
                        required
                        showHelperText
                        type="password"
                        value={data.newPassword}
                        onChange={ev => handleChangeValue(
                            'newPassword', ev.target.value
                        )}
                    />
                    <InputCustom
                        errorOrHelperText={errors.newPassword2}
                        disabled={isChangingOn}
                        id="id_newPassword2"
                        isInvalid={!!errors.newPassword2}
                        label="Powtórzone nowe hasło"
                        required
                        type="password"
                        value={data.newPassword2}
                        onChange={ev => handleChangeValue(
                            'newPassword2', ev.target.value
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions className="form-buttons">
                <ButtonCustom
                    disabled={isChangingOn}
                    onClick={onCancel}
                    variant="outlined"
                >
                    Anuluj
                </ButtonCustom>
                <ButtonCustom
                    type="submit"
                    form="change_password_form"
                    disabled={isChangingOn}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsChangingOn(true)
                    }}
                >
                    Zmień
                </ButtonCustom>
                {(errors.formErrors || []).map(error =>
                    <p className="error" role="alert" key={error}>{error}</p>
                )}
                {formInvalid && <Typography role="alert" variant="srOnly">Formularz zawiera błędy!</Typography>}
            </DialogActions>
        </DialogNaked>
    )
};
