import {
    DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH,
    DOCUMENT_NUMBER_IN_CASE_INPUT_MAX_LENGTH
} from '../../../consts';
import { InputCustom } from '../../atoms';
import { DatesFields } from '../DatesFields';
import { DigitalSelectField } from './DigitalSelectField';
import { SelectField } from './SelectField';
import '../molecules-global.sass';
import { CombainedSearchForm } from '../../organisms';
import { Grid } from '@material-ui/core';
import { sortDepartmentDictionary } from '../../../helpers/helpers';

const DATE_FILTERS_IDS = [
    'arrivedDate', 'composedDate', 'disclosedDate', 'transferredDate'
];


export const SearchByCaseReferenceFields = ({
    dictionariesData,
    dictionariesErrorOn,
    errors,
    fields,
    formDisabled,
    onChangeFilter,
    clearState,
    changeClearState,
    searchState,
}) => {
    return (
        <>
            <section className="form-section">
                <CombainedSearchForm
                    formId={'caseReference'}
                    visibleButtons={false}
                    textInformationStyles={'21.6%'}
                    visitorFunction={(value) => onChangeFilter('caseReference', value )}
                    externalClearState={clearState}
                    externalChangeClearState={changeClearState}
                    externalSearchState={searchState}
                    customContainerPadding={{ paddingLeft: `1rem`, paddingRight: `1rem` }}
                    dictData={sortDepartmentDictionary(dictionariesData['department'] ?? []) || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    mandatory={true}
                    label={'Sygnatura sprawy'}
                />
            </section>
            <section className="form-section form-section--multiple-fields">
                <SelectField
                    dictData={dictionariesData['documentKind'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.type}
                    errorMessage="Nie udało się pobrać rodzajów dokumentów."
                    fieldName="type"
                    id="id_type"
                    label="Rodzaj dokumentu"
                    value={fields.type}
                    onChangeFilter={onChangeFilter}
                />
                <InputCustom
                    disabled={formDisabled}
                    errorOrHelperText={errors.identifier}
                    id="id_identifier"
                    isInvalid={!!errors.identifier}
                    label="Identyfikator dokumentu"
                    maxLength={DOCUMENT_IDENTIFIER_INPUT_MAX_LENGTH}
                    value={fields.identifier}
                    onChange={ev => onChangeFilter('identifier', ev.target.value)}
                />
                <InputCustom
                    disabled={formDisabled}
                    errorOrHelperText={errors.numberInCase}
                    id="id_numberInCase"
                    isInvalid={!!errors.numberInCase}
                    label="Numer dokumentu w sprawie"
                    maxLength={DOCUMENT_NUMBER_IN_CASE_INPUT_MAX_LENGTH}
                    value={fields.numberInCase}
                    onChange={ev => onChangeFilter('numberInCase', ev.target.value)}
                />

                <SelectField
                    dictData={dictionariesData['documentAccessLevel'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.accessLevel}
                    errorMessage="Nie udało się pobrać poziomów dostępności."
                    fieldName="accessLevel"
                    id="id_accessLevel"
                    label="Poziom dostępu"
                    value={fields.accessLevel}
                    onChangeFilter={onChangeFilter}
                />
                <SelectField
                    dictData={dictionariesData['documentLanguage'] || []}
                    dictionariesErrorOn={dictionariesErrorOn}
                    disabled={formDisabled}
                    error={errors.language}
                    errorMessage="Nie udało się pobrać języków."
                    fieldName="language"
                    id="id_language"
                    label="Język dokumentu"
                    value={fields.language}
                    onChangeFilter={onChangeFilter}
                />
                <DigitalSelectField
                    disabled={formDisabled}
                    error={errors.digital}
                    value={fields.digital}
                    onChangeFilter={onChangeFilter}
                />
                <Grid container className="max-width-form-section">
                    <Grid item xs={12}>
                        <CombainedSearchForm
                            formId={'caseReferenceFrom'}
                            textInformationStyles={'21.6%'}
                            visibleButtons={false}
                            visitorFunction={(value) => onChangeFilter('caseReferenceFrom', value )}
                            externalClearState={clearState}
                            externalChangeClearState={changeClearState}
                            externalSearchState={searchState}
                            dictData={sortDepartmentDictionary(dictionariesData['department'] ?? []) || []}
                            dictionariesErrorOn={dictionariesErrorOn}
                            mandatory={false}
                            label={'Przeniesiony ze sprawy'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CombainedSearchForm
                            formId={'caseReferenceTo'}
                            textInformationStyles={'21.6%'}
                            visibleButtons={false}
                            visitorFunction={(value) => onChangeFilter('caseReferenceTo', value )}
                            externalClearState={clearState}
                            externalChangeClearState={changeClearState}
                            externalSearchState={searchState}
                            dictData={sortDepartmentDictionary(dictionariesData['department'] ?? []) || []}
                            dictionariesErrorOn={dictionariesErrorOn}
                            mandatory={false}
                            label={'Przeniesiony do sprawy'}
                        />
                    </Grid>
                </Grid>
            </section>
            <section className="form-section">
                <DatesFields
                    disabled={formDisabled}
                    errors={errors}
                    fields={fields}
                    filtersIds={DATE_FILTERS_IDS}
                    onChangeFilter={onChangeFilter}
                />
            </section>
        </>
    )
};
