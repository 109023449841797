
export const validate = (formData, fieldsList) => {
    let isValid = true;
    let errors = {};
    for (let fieldName of fieldsList) {
        if (!formData[fieldName]) {
            errors[fieldName] = 'To pole jest wymagane.';
            isValid = false;
        }
    }
    return [isValid, errors]
};
