import { MenuItem } from '@material-ui/core';

import {SelectCustom} from "../../atoms";
import {DIGITAL_DOCUMENT_CHOICES} from "../../../consts";


export const DigitalSelectField = ({
    disabled,
    error,
    value,
    onChangeFilter,
}) => {
    return (
        <SelectCustom
            disabled={disabled}
            errorOrHelperText={!!error && error}
            id="id_digital"
            isInvalid={!!error}
            label={'Czy dokument wpłynął elektronicznie?'}
            value={value}
            onChange={ev => onChangeFilter('digital', ev.target.value)}
        >
            {DIGITAL_DOCUMENT_CHOICES.map(choice => (
                <MenuItem
                    key={choice.value}
                    value={choice.value}
                >
                    {choice.label}
                </MenuItem>
            ))}
        </SelectCustom>
    )
};
