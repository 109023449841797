const refreshSession = () => {
    const key = 'REFRESH_STATE';

    const setState = () => localStorage.setItem(key, 'true');

    const getState = () => localStorage.getItem(key) === 'true';

    const removeState = () => localStorage.removeItem(key);

    return {
        setState,
        getState,
        removeState,
    }
};

export default refreshSession;
