import { useMemo, useState } from 'react';

import { REPO_TYPES } from '../../../consts';
import { validate } from '../../../validation/CasesFiltersForm';
import { CaseFiles, TableWithFilters} from '../../organisms';
import { CasesFiltersForm } from '../CasesFiltersForm';
import { CaseTableRow } from '../CaseTableRow';


const DEFAULT_FILTERS = {
    caseReference: '',
    caseType: '0',
    caseStatus: '0',
    caseApplicationType: '0',
    caseStartType: '0',
    caseEndType: '0',
    applicant: '',
    relatedCase: '',
    startDateFrom: '',
    startDateTo: '',
    endDateFrom: '',
    endDateTo: '',
};
const SERIALIZATION_URL_LIST = [
    ['caseReference', 'sygnatura'],
    ['caseType', 'rodzaj'],
    ['caseStatus', 'status'],
    ['caseApplicationType', 'typPismaWszczynajacego'],
    ['caseStartType', 'sposobWszczecia'],
    ['caseEndType', 'sposobZakonczenia'],
    ['applicant', 'wnioskodawca'],
    ['relatedCase', 'powiazanaZeSprawa'],
    ['startDateFrom', 'wszczecieOd'],
    ['startDateTo', 'wszczecieDo'],
    ['endDateFrom', 'zakreslenieOd'],
    ['endDateTo', 'zakreslenieDo'],
    ['pageNumber', 'strona'],
];
const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}


export const Cases = ({krsNumber, repoData, ...props}) => {
    let tableHeaderList = [
        'Sygnatura', 'Rodzaj', 'Stan', 'Data wszczęcia', 'Data zakreślenia',
        'Więcej o sprawie', 'Dołączone akta/sprawy'
    ];
    if (repoData.name === REPO_TYPES.all.name) {
        tableHeaderList.unshift('Repozytorium');
    }

    const additionalFetchParams = useMemo(
        () => ({
            context: 'KRS_SUBJECT',
            krsNumber,
            repoType: repoData.fetchParamName,
        }), [krsNumber, repoData.fetchParamName]
    );

    const [
        caseFilesCaseReference, setCaseFilesCaseReference
    ] = useState(null);

    function handleShowCaseFiles(caseReference) {
        setCaseFilesCaseReference(caseReference);
    }

    return (
        <>
            <TableWithFilters
                additionalFetchParams={additionalFetchParams}
                defaultFilters={DEFAULT_FILTERS}
                fetchUrl="/cases"
                FiltersComponent={CasesFiltersForm}
                filtersSelectFieldNames={[
                    'caseType', 'caseStatus', 'caseApplicationType',
                    'caseStartType', 'caseEndType'
                ]}
                repoData={repoData}
                serializationUrlDictToPolish={SERIALIZATION_URL_DICT_TO_POLISH}
                tableEmptyResults="Brak spraw spełniających podane kryteria."
                tableHeaderList={tableHeaderList}
                tableItemKeyField="caseReference"
                tableItemsName="cases"
                tableName="Sprawy"
                TableRowComponent={CaseTableRow}
                tableRowComponentProps={{
                    withMovedFiles: true,
                    onShowMovedFiles: handleShowCaseFiles,
                    useFormatDate:true
                }}
                validationFunction={validate}
            />
            {caseFilesCaseReference !== null && (
                <CaseFiles
                    caseReference={caseFilesCaseReference}
                    repoData={repoData}
                    onChangeCaseReference={handleShowCaseFiles}
                    onCloseMovedFiles={() => handleShowCaseFiles(null)}
                />
            )}
        </>
    )
};
