import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    useMediaQuery,
} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { useTheme } from '@material-ui/core/styles';

import { IconButtonWithTooltip } from '../../molecules';
import './TableCustom.sass';

export const TableCustom = ({children, tableHeaderList, ...props}) => {
    const theme = useTheme();
    const showScrollIndicator = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="table-custom-container">
            {showScrollIndicator && (
                <div className="table-custom__scroll">
                    <IconButtonWithTooltip
                        srOnlyText="Żeby obejrzeć całą tabelę, przesuwaj ją poziomo"
                    >
                        <SwapHorizIcon color="primary" size="small" />
                    </IconButtonWithTooltip>
                </div>
            )}
            <TableContainer component={Paper} elevation={0} className="table-custom">
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaderList.map(tableHeader =>
                                <TableCell align="left" key={tableHeader}>{tableHeader}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};
